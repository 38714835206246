import {Box,
    Button,
    Paper
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {useState} from "react";
import InputFieldWithLabel from "../../shared/input-fields/InputFieldWithLabel";
import { useNavigate } from 'react-router-dom';
import SelectOptionsDropdown from "../../shared/dropdowns/SelectOptionsDropdown";
import AuthService from "../../../data-access/services/authServices/AuthService";
import {enqueueSnackbar} from "notistack";

export default function ProfileSetup({setIsSetupDoneFirstScreen, userId}) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        email: "",
        password: "",
        remember: false,
        showPassword: false,
    });
    const navigate = useNavigate()

    // yup schema
    const schema = yup.object().shape({
        // fullName: yup
        //     .string()
        //     .required("You must enter your full name"),
        role: yup.string().required("You must select your role"),
        countryOrProvince: yup.string().required("You must select your country/province"),
        dentistryType: yup.string().required("You must select your dentistry type"),
    });

    // default form values
    const defaultValues = {
        // fullName: "",
        role: "",
        countryOrProvince: "",
        dentistryType: "",
    };

    // form controler
    const { control, formState, handleSubmit, reset } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });
    const { isValid, dirtyFields, errors } = formState;


    const onSubmit = (values)=> {
        AuthService.profileSetupScreenOne({...values, id : userId})
            .then((response)=> {
                enqueueSnackbar(response?.data?.message, {variant: "success"})
                // navigate("/dashboard")
                setIsSetupDoneFirstScreen(true)
            })
            .catch((err)=> {
                enqueueSnackbar(err, {variant: "error"})
            })
        setIsSetupDoneFirstScreen(true)
    }

    const roles = [
        {
            key : "dentist",
            title : "Dentist"
        },
        {
            key : "manager",
            title : "Manager"
        },
        {
            key : "receptionist",
            title : "Receptionist"
        },
        {
            key : "other",
            title : "Other"
        },
        {
            key : "notSure",
            title : "Not Sure"
        },
    ]

    const dentistryType = [
        {
            key : "generalDentist",
            title : "General Dentist"
        },
        {
            key : "pediatric",
            title : "Pedodontist or Pediatric Dentis"
        },
        {
            key : "orthodontist",
            title : "Orthodontistt"
        },
        {
            key : "periodontist",
            title : "Periodontist or Gum Specialist"
        },
        {
            key : "endodontist",
            title : "Endodontist or Root Canal Specialist"
        },
        {
            key : "oralpathologist",
            title : "Oral Pathologist or Oral Surgeon"
        },
        {
            key : "prosthodontist",
            title : "Prosthodontist"
        },
        {
            key : "notSure",
            title : "Not Sure"
        },
    ]

    const profileSetupSkip = (stage)=> {
        AuthService.profileSetupSkip(stage, userId)
            .then((response)=> {
                enqueueSnackbar(response?.data?.message, {variant: "success"})
                setIsSetupDoneFirstScreen(true)
            })
            .catch((err)=> {
                enqueueSnackbar(err, {variant: "error"})
            })
    }

    return (
        <div className="flex flex-col flex-auto items-center justify-around sm:justify-center h-auto min-w-0 md:p-32 w-full">
            <Paper className="flex justify-center md:min-w-[1020px] sm:w-auto min-h-auto max-h-3xl h-661 overflow-hidden relative" sx={{borderRadius: "20px"}}>
                <div className="absolute profile-setup-logo rounded-full">
                    <div className="flex justify-center items-center " >
                        <img src="./WAIG-Logo.png" alt=""/>
                    </div>
                </div>
                <Paper className="m-8" sx={{backgroundColor: "#F4F8F6"}}>
                    <div className="w-full sm:w-auto flex flex-col justify-center py-[104px] px-6 md:px-16 lg:min-w-[425px] max-w-[425px]">
                        <div className="w-auto">
                            <p className="welcome-back-text">Setup Your Profile</p>
                            <p className="body-text-2 pb-8">Complete your profile for better experience</p>
                        </div>
                        <form name="setupProfileForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <div style={{paddingBottom: "5px"}}>
                                {/*<InputFieldWithLabel label="Full Name" placeholder="Your Full Name" control={control} errors={errors} controllerName="fullName" />*/}
                                <SelectOptionsDropdown label="Your Role" placeholder="Select" control={control} errors={errors} controllerName="role" options={roles}/>
                            </div>
                            <div style={{paddingBottom: "5px"}}>
                                {/*<SelectOptionsDropdown label="Your Role" placeholder="Select" control={control} errors={errors} controllerName="role" />*/}
                                <InputFieldWithLabel label="Country/Province" placeholder="Location" control={control} errors={errors} controllerName="countryOrProvince" />
                            </div>
                            <div>
                                <SelectOptionsDropdown label="Dentistry Type" placeholder="Choose Here" control={control} errors={errors} controllerName="dentistryType" options={dentistryType}/>
                            </div>
                            <div className="pt-4">
                                <Button type="submit" variant="outlined" sx={{backgroundColor: "#184332", height: "32.5px", borderRadius: "30px"}} fullWidth>
                                    <p className="button-linear-text" style={{textTransform: "capitalize"}}>Next</p>
                                </Button>
                            </div>
                            <div className="pt-4">
                                <Button type="button" variant="outlined" sx={{backgroundColor: "#E2DB97", height: "32.5px", borderRadius: "30px"}} fullWidth onClick={()=> profileSetupSkip(1, userId)}>
                                    <p className="button-linear-text2" style={{textTransform: "capitalize"}}>Skip for Now</p>
                                </Button>
                            </div>
                        </form>
                    </div>
                </Paper>
                {/*<Box className="relative hidden lg:flex flex-auto justify-center items-center pt-36 pb-36 overflow-hidden lg:min-w-[500px] max-w-[500px] h-auto w-full bg-[#184332]">*/}
                {/*    <div className="flex flex-col gap-[60px] justify-center items-center z-10 relative w-full">*/}
                {/*        <div className="flex justify-center items-center h-[190px] w-[190px] bg-[#133B2B] rounded-full">*/}
                {/*            <div className="flex justify-center h-[95px] w-[95px]">*/}
                {/*                <img src="./login-logo.png" alt="" />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="flex flex-col justify-center items-center max-w-[433px]">*/}
                {/*            <p className="heading-text-1 pb-2">*/}
                {/*                Welcome to WAIG*/}
                {/*            </p>*/}
                {/*            <p className="body-text text-center">*/}
                {/*                Welcome to World AI Group, where data-driven insights*/}
                {/*            </p>*/}
                {/*            <p className="body-text text-center">*/}
                {/*                meet innovation, powering your journey towards*/}
                {/*            </p>*/}
                {/*            <p className="body-text text-center">*/}
                {/*                optimized KPIs and business excellence.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Box>*/}
            </Paper>
        </div>
    )
}