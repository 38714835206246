import {Box,
    Button,
    Paper
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {useState} from "react";
import InputFieldWithLabel from "../../shared/input-fields/InputFieldWithLabel";
import { useNavigate } from 'react-router-dom';
import SelectOptionsDropdown from "../../shared/dropdowns/SelectOptionsDropdown";
import AuthService from "../../../data-access/services/authServices/AuthService";
import {enqueueSnackbar} from "notistack";

export default function ProfileSetup2({userId}) {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        email: "",
        password: "",
        remember: false,
        showPassword: false,
    });
    const navigate = useNavigate()

    // yup schema
    const schema = yup.object().shape({
        // fullName: yup
        //     .string()
        //     .required("You must enter your full name"),
        estimatedLastYearGR: yup.string().required("You must enter you Estimated last year GR"),
        numOfOperations: yup.string().required("You must select your number of operations"),
        ageOfPractice: yup.string().required("You must select your age of practice"),
        numOfActivePatients: yup.string().required("You must select your number of active patients"),
        avgNumOfNewPatientsPeerMonth: yup.string().required("You must select your avg number of new patients/month"),
    });

    // default form values
    const defaultValues = {
        // fullName: "",
        estimatedLastYearGR: "",
        numOfOperations: "",
        ageOfPractice: "",
        numOfActivePatients: "",
        avgNumOfNewPatientsPeerMonth: "",
    };

    // form controler
    const { control, formState, handleSubmit, reset } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });
    const { isValid, dirtyFields, errors } = formState;


    const onSubmit = (values)=> {
        AuthService.profileSetupScreenTwo({...values, id : userId})
            .then((response)=> {
                enqueueSnackbar(response?.data?.message, {variant: "success"})
                navigate("/dashboard")
            })
            .catch((err)=> {
                enqueueSnackbar(err, {variant: "error"})
            })
    }

    const numOfOperations = [
        {
            key : "zeroToFive",
            title : "0-5"
        },
        {
            key : "tenToFifteen",
            title : "10-15"
        },
        {
            key : "twentyToTwentyFive",
            title : "20-25"
        },
        {
            key : "twentyFiveToThirty",
            title : "25-30"
        },
        {
            key : "notSure",
            title : "Not Sure"
        },
    ]

    const ageOfPractice = [
        {
            key : "lessThanOne",
            title : "Less Than One"
        },
        {
            key : "one",
            title : "One"
        },
        {
            key : "two",
            title : "Two"
        },
        {
            key : "three",
            title : "Three"
        },
        {
            key : "four",
            title : "Four"
        },
        {
            key : "five",
            title : "Five"
        },
        {
            key : "six",
            title : "Six"
        },
        {
            key : "moreThanSix",
            title : "More Than Six"
        },
        {
            key : "notSure",
            title : "Not Sure"
        },
    ]

    const numOfActivePatients = [
        {
            key : "zeroToTwenty",
            title : "0-20"
        },
        {
            key : "twentyOneToFifty",
            title : "21-50"
        },
        {
            key : "fiftyOneToOneHundred",
            title : "51-100"
        },
        {
            key : "oneHundredOneToTwoHundred",
            title : "101-200"
        },
        {
            key : "moreThanTwoHundred",
            title : "200+"
        },
        {
            key : "notSure",
            title : "Not Sure"
        },
    ]

    const avgNumOfNewPatientsPeerMonth = [
        {
            key : "zeroToFive",
            title : "0-5"
        },
        {
            key : "sixToTen",
            title : "6-10"
        },
        {
            key : "elevenToTwenty",
            title : "11-20"
        },
        {
            key : "twentyOneToFifty",
            title : "21-50"
        },
        {
            key : "moreThanFifty",
            title : "50+"
        },
        {
            key : "notSure",
            title : "Not Sure"
        },
    ]

    const profileSetupSkip = (stage)=> {
        AuthService.profileSetupSkip(stage, userId)
            .then((response)=> {
                enqueueSnackbar(response?.data?.message, {variant: "success"})
                navigate("/dashboard")
            })
            .catch((err)=> {
                enqueueSnackbar(err, {variant: "error"})
            })
    }

    return (
        <div className="flex flex-col flex-auto items-center justify-around sm:justify-center h-auto min-w-0 md:p-32 w-full">
            <Paper className="flex justify-center md:min-w-[1020px] sm:w-auto min-h-auto max-h-3xl h-661 overflow-hidden relative" sx={{borderRadius: "20px"}}>
                <div className="absolute profile-setup-logo rounded-full">
                    <div className="flex justify-center items-center " >
                        <img src="./WAIG-Logo.png" alt=""/>
                    </div>
                </div>
                <Paper className="m-8" sx={{backgroundColor: "#F4F8F6"}}>
                    <div className="w-full sm:w-auto flex flex-col justify-center py-[50px] px-6 md:px-16 lg:min-w-[425px] max-w-[425px]">
                        <div className="w-auto">
                            <p className="welcome-back-text">Let’s Finish Your Setup</p>
                            <p className="body-text-2 pb-8">Complete your profile for better experience</p>
                        </div>
                        <form name="setupProfileForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <div style={{paddingBottom: "5px"}}>
                                <InputFieldWithLabel label="Estimated Last Year GR" placeholder="Your Last Year Gross Revenue" control={control} errors={errors} controllerName="estimatedLastYearGR" />
                            </div>
                            <div style={{paddingBottom: "5px"}}>
                                <SelectOptionsDropdown label="Number of Operations" placeholder="Choose Here" control={control} errors={errors} controllerName="numOfOperations" options={numOfOperations}/>
                            </div>
                            <div style={{paddingBottom: "5px"}}>
                                <SelectOptionsDropdown label="Age of Practice" placeholder="Choose Here" control={control} errors={errors} controllerName="ageOfPractice" options={ageOfPractice}/>
                            </div>
                            <div style={{paddingBottom: "5px"}}>
                                <SelectOptionsDropdown label="No of Active Patients" placeholder="Choose Here" control={control} errors={errors} controllerName="numOfActivePatients" options={numOfActivePatients}/>
                            </div>
                            <div>
                                <SelectOptionsDropdown label="Avg Number of New Patients/Month" placeholder="Choose Here" control={control} errors={errors} controllerName="avgNumOfNewPatientsPeerMonth" options={avgNumOfNewPatientsPeerMonth}/>
                            </div>
                            <div className="pt-4">
                                <Button type="submit" variant="outlined" sx={{backgroundColor: "#184332", height: "32.5px", borderRadius: "30px"}} fullWidth>
                                    <p className="button-linear-text" style={{textTransform: "capitalize"}}>Get Started</p>
                                </Button>
                            </div>
                            <div className="pt-4">
                                <Button type="button" variant="outlined" sx={{backgroundColor: "#E2DB97", height: "32.5px", borderRadius: "30px"}} fullWidth onClick={()=> profileSetupSkip(2, userId)}>
                                    <p className="button-linear-text2" style={{textTransform: "capitalize"}}>Skip for Now</p>
                                </Button>
                            </div>
                        </form>
                    </div>
                </Paper>
            </Paper>
        </div>
    )
}