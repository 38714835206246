import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormHelperText,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
} from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import InputFieldWithLabel from "../shared/input-fields/InputFieldWithLabel";
import SelectOptionsDropdown from "../shared/dropdowns/SelectOptionsDropdown";
import NavAndTopBar from "../shared/layouts/NavAndTopBar";
import { useState } from "react";
import CreatePermission from "./CreatePermission";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetAllUsersQuery } from "../../store/apiSlice";
import Utils from "../../utils/Utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CreateUpdateModal from "../recommendations/CreateUpdateModal";
import PermissionModal from "./PermissionModal";

const accessForUsers = [
  {
    key: "jKSutradhor",
    name: "J.K. Sutradhor",
    email: "jksutradhor@gmail.com",
  },
  {
    key: "joniKumar",
    name: "Joni Kumar",
    email: "jonisutradhor@gmail.com",
  },
  {
    key: "mrKumar",
    name: "Mr. Kumar",
    email: "mr.kumar@gmail.com",
  },
  {
    key: "mrSutradhor",
    name: "Mr. Sutradhor",
    email: "mr.sutradhor@gmail.com",
  },
  {
    key: "estherHoward",
    name: "Esther Howard",
    email: "esther.howard@gmail.com",
  },
  {
    key: "jacabJones",
    name: "Jacab Jones",
    email: "jacab.jones@gmail.com",
  },
  {
    key: "leslieAlexander",
    name: "Leslie Alexander",
    email: "leslie.alexander@gmail.com",
  },
];

const accessLevelOptions = [
  {
    key: "viewOnly",
    title: "View Only",
  },
  {
    key: "manager",
    title: "Manager",
  },
  {
    key: "limitedAccess",
    title: "Limited Access",
  },
  {
    key: "fullAccess",
    title: "Full Access",
  },
];

const permissions = [
  {
    type: "static",
    name: "All",
    value: true,
  },
  {
    type: "option",
    name: "Summary Dashboard",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Patients",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Patients Trend Chart",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Life Cycle Chart",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "EBIDTA VS Ind. Avg.",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "EBIDTA VS Ind. Avg. TC",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Revenue VS Ind. Avg.",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Revenue VS Ind. Avg. TC",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Expenses VS Ind. Avg.",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Expenses VS Ind. Avg. TC",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Churn Rate",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Churn Rate TC",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Growth Rate",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Growth Rate TC",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Treatment Acceptance Rate",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Average Revenue",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Revenue by Provider",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Revenue by Provider TC",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Refferal Info",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Revenue by Treatment Type",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Detailed Services",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Patient By Top Location",
    value: true,
  },
  {
    type: "summaryDashboard",
    name: "Top Type of Patients",
    value: true,
  },
  {
    type: "option",
    name: "Recommendations",
    value: true,
  },
  {
    type: "option",
    name: "Revenue Dashboard",
    value: true,
  },
  {
    type: "option",
    name: "Patients Dashboard",
    value: true,
  },
  {
    type: "option",
    name: "Operations Dashboard",
    value: true,
  },
  {
    type: "option",
    name: "Profile",
    value: true,
  },
  {
    type: "option",
    name: "Permissions",
    value: true,
  },
  {
    type: "option",
    name: "Get Started",
    value: true,
  },
  {
    type: "option",
    name: "Contact Us",
    value: true,
  },
  {
    type: "option",
    name: "Settings",
    value: true,
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "80%",
  width: "40%",
  bgcolor: "background.paper",
  border: "2px solid #184332",
  boxShadow: 40,
  p: 4,
};

export default function Permissions() {
  const {
    data: users,
    error: usersError,
    isFetching: isUserFetching,
  } = useGetAllUsersQuery();
  const [openPermissionModal, setOpenPermissioneModal] = useState(false);
  const [openCreateUpdateModal, setOpenCreateUpdateModal] = useState(false);
  const [createUpdateModalData, setCreateUpdateModalData] = useState([]);
  const [userExistingPermissions, setUserExistingPermissions] = useState(null);
  const [isHavePermittedUser, setIsHavePermittedUser] = useState(true);
  const [isGavePermissionScreen, setIsGavePermissionScreen] = useState(false);
  const [isNewAccessScreen, setIsNewAccessScreen] = useState(false);
  const [userId, setUserId] = useState("");

  // yup schema
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("You must enter a valid email")
      .required("You must enter a email"),
    name: yup.string().required("Please enter Name."),
    selectAccessLevel: yup.string().required("Please Select Access level."),
  });

  // default form values
  const defaultValues = {
    name: "",
    email: "",
    selectAccessLevel: "",
  };

  // form controler
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const getWordsFirstLetter = (word) => {
    let matches = word.match(/\b(\w)/g);
    return matches.join("");
  };
  const handleNewAccess = () => {
    setIsNewAccessScreen(true);
    setOpenPermissioneModal(true);
    // setIsGavePermissionScreen(true);
    // setIsHavePermittedUser(false);
  };

  const handlePermissionModalClose = () => {
    setOpenPermissioneModal(false);
  };

  return (
    <NavAndTopBar>
      <p className="font-bold text-2xl px-6 pt-6">Permissions</p>
      <div className="flex flex-col flex-auto  sm:justify-center h-auto min-w-0 md:p-6 w-full">
        {!isUserFetching && users?.data?.length > 0 && isHavePermittedUser && (
          <div>
            <div className="flex justify-end pb-6">
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  backgroundColor: "#184332",
                  height: "40px",
                  borderRadius: "10px",
                }}
                onClick={() => handleNewAccess()}
              >
                <p
                  className="button-linear-text"
                  style={{ textTransform: "capitalize" }}
                >
                  New Access
                </p>
              </Button>
            </div>
            <Paper
              className="flex justify-center md:min-w-[600px] md:max-w-full sm:w-auto min-h-auto max-h-3xl h-661 overflow-hidden relative py-6"
              sx={{ borderRadius: "20px" }}
            >
              <div className="w-full px-6">
                {
                  // accessForUsers.map((user)=> (
                  users.data.map((user) => (
                    <div
                      className="flex gap-16 my-3 py-2 border-2 rounded-md px-2"
                      key={user.email}
                    >
                      <div className="flex gap-3">
                        <div className="flex justify-center items-center">
                          <div
                            style={{
                              backgroundColor: `${Utils.getRandomHexColorCode()}`,
                            }}
                            className={`flex justify-center items-center h-[40px] w-[40px] rounded-full text-white`}
                          >
                            {getWordsFirstLetter(user.fullname)}
                          </div>
                        </div>
                        <div className="flex flex-col justify-center gap-1">
                          <p>{user.fullname}</p>
                          <p>{user.email}</p>
                          <ModeEditOutlineOutlinedIcon
                            onClick={() => {
                              setCreateUpdateModalData(
                                permissions.map(
                                  (permission) => `${permission.name}, `
                                )
                              );
                              setUserExistingPermissions(
                                user?.permissions || null
                              );
                              setUserId(user?.id);
                              setIsNewAccessScreen(false)
                              setOpenPermissioneModal(true);
                            }}
                            className="cursor-pointer text-green-600"
                          />
                        </div>
                      </div>
                      {/* Here */}
                      <div>
                        <p>
                          {/* {permissions.map(
                            (permission) => `${permission.name}, `
                          )} */}
                          {user?.permissions ? user?.permissions : ""}
                        </p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </Paper>
          </div>
        )}
        {!isHavePermittedUser && !isGavePermissionScreen && (
          <Paper
            className="flex justify-center md:w-full sm:w-auto min-h-auto max-h-3xl h-661 overflow-hidden relative py-6"
            sx={{ borderRadius: "20px" }}
          >
            <div className="flex flex-col justify-center items-center gap-2">
              <div className="">
                <img src="./permissions_no_user_icon.png" alt="" />
              </div>
              <div>
                <p className="font-bold text-2xl pt-5">Invite Members</p>
              </div>
              <div className="flex flex-col justify-center items-center py-4">
                <p className="text-[#24373D]">
                  Invite users who would manage accounts on your behalf
                </p>
                <p className="font-semibold text-[#109FE7]">
                  Watch Video to Understand.
                </p>
              </div>
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  backgroundColor: "#184332",
                  height: "40px",
                  borderRadius: "10px",
                }}
                onClick={() => setIsNewAccessScreen((prevState) => !prevState)}
              >
                <p
                  className="button-linear-text"
                  style={{ textTransform: "capitalize" }}
                >
                  Invite Members
                </p>
              </Button>
            </div>
          </Paper>
        )}
        {/* {!isHavePermittedUser && isGavePermissionScreen && <CreatePermission users={users?.data} />} */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openPermissionModal}
          onClose={handlePermissionModalClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openPermissionModal}>
            <Box sx={style}>
              <PermissionModal
                accessForOptions={permissions}
                closeModal={handlePermissionModalClose}
                userExistingPermissions={userExistingPermissions}
                userId={userId}
                users={users?.data}
                modalType={isNewAccessScreen ? "create" : "update"}
              />
            </Box>
          </Fade>
        </Modal>
      </div>
    </NavAndTopBar>
  );
}
