import React from "react";
import ReactApexChart from "react-apexcharts";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalChart({
  options,
  series,
  type,
  height,
  closeModal,
}) {
  return (
    <div>
      <div className="flex justify-end">
        <CloseIcon onClick={closeModal} className="cursor-pointer" />
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        height={height}
      />
    </div>
  );
}
