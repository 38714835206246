import React, { useEffect, useState } from "react";
import NavAndTopBar from "../../shared/layouts/NavAndTopBar";
import ReactApexChart from "react-apexcharts";
import {
  Box,
  ClickAwayListener,
  FormControl,
  MenuItem,
  styled,
  Tooltip,
  tooltipClasses,
  Select,
  SvgIcon,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import Utils from "../../../utils/Utils";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function RevenueDashboard() {
  const [fromDateOpen, setFromDateOpen] = useState(false);
  const [toDateOpen, setToDateOpen] = useState(false);
  const [fromDate, setFromDate] = useState(
    dayjs(`${new Date().getFullYear() - 1}-01-01`)
  );
  // const [toDate, setToDate] = useState(dayjs(new Date()));
  const [toDate, setToDate] = useState(dayjs(`2023-11-28 00:00:00`));
  const [mmfromDateOpen, setmmFromDateOpen] = useState(false);
  const [mmtoDateOpen, setmmToDateOpen] = useState(false);
  const [mmfromDate, setmmFromDate] = useState(
    dayjs(`${new Date().getFullYear() - 1}-01-01`)
  );
  // const [toDate, setToDate] = useState(dayjs(new Date()));
  const [mmtoDate, setmmToDate] = useState(dayjs(`2023-11-28 00:00:00`));
  const [filterByProvider, setFilterByProvider] = React.useState([]);
  const [topProvidersName, setTopProvidersName] = useState([
    "",
    "",
    "",
    "",
    "",
  ]);
  const [filterBy, setFilterBy] = React.useState([]);
  const [mainMetricsViewBy, setMainMetricsViewBy] = useState("revenue");
  const [revenueChartViewBy, setRevenueChartViewBy] =
    useState("revPerAppointment");
  const [appointmentChartViewBy, setAppointmentChartViewBy] =
    useState("actual");

  const handleClickAway = () => {
    setFromDateOpen(false);
    setToDateOpen(false);
    setmmFromDateOpen(false);
    setmmToDateOpen(false);
  };

  const handleProviderChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterByProvider(value);
  };

  const handleFilterBy = (event) => {
    const {
      target: { value },
    } = event;
    setFilterBy(value);
  };

  const mainMetricsSeries = [
    {
      name: "Actual",
      type: "column",
      data: [440, 505, 414, 671, 227, 413],
    },
    {
      name: "Planned",
      type: "line",
      data: [435, 550, 458, 650, 430, 220],
    },
  ];
  const mainMetricsOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#184332", "#F2C94C"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
        endingShape: "rounded",
      },
    },

    stroke: {
      width: [0, 4],
    },
    // title: {
    //   text: "Traffic Sources",
    // },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
    yaxis: [
      {
        // title: {
        //   text: "Website Blog",
        // },
      },
      {
        opposite: true,
        // title: {
        //   text: "Social Media",
        // },
      },
    ],
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  const revenueChartSeries = [
    {
      name: "Actual",
      data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
    },
    {
      name: "Last Year",
      data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
    },
    {
      name: "Planned",
      data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47],
    },
  ];
  const revenueChartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3, 3],
      curve: "smooth",
      dashArray: [0, 0, 0],
    },
    // title: {
    //   text: "Page Statistics",
    //   align: "left",
    // },
    colors: ["#184332", "#EB5757", "#F2994A"],
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - <strong>" +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          "</strong>"
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (mins)";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
      // horizontalAlign: 'left',
    },
  };

  const appointmentsChartSeries = [
    {
      data: [400, 430, 448, 470, 540, 580],
    },
  ];
  const appointmentsChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ["#27AE60"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "25%",
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["2018", "2019", "2020", "2021", "2022", "2023"],
    },
    legend: {
      position: "top",
      // horizontalAlign: 'left',
    },
  };

  const revenueByTypeSeries = [68, 80, 48];
  const revenueByTypeOptions = {
    chart: {
      height: 450,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        offsetX: 45,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "50%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: ["#184332", "#F2C94C", "#27AE60"],
    labels: ["Filling", "Crown", "Scalling"],
    legend: {
      show: true,
      floating: true,
      fontSize: "16px",
      position: "left",
      offsetX: -10,
      offsetY: 0,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        return (
          seriesName +
          ":  " +
          Utils.amountFormatter(opts.w.globals.series[opts.seriesIndex])
        );
      },
      itemMargin: {
        vertical: 1,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const revenueByProviderSeries = [
    {
      name: "Ortho",
      data: [44, 55, 41, 37],
    },
    {
      name: "Preventive",
      data: [53, 32, 33, 52, 13],
    },
    {
      name: "Endo",
      data: [12, 17, 11],
    },
    {
      name: "Perio",
      data: [9, 7, 5, 8],
    },
  ];
  const revenueByProvidersOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    // title: {
    //   text: "100% Stacked Bar",
    // },
    xaxis: {
      categories: ["Dr. 1", "Dr. 2", "Dr. 3", "Dr. 4", "Hyg. 1"],
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        // return val + "% decrease compared to last year"
        //   console.log("opt : ",opt)
        let typeArr = ["Ortho", "Preventive", "Endo", "Perio"];
        //   return `$${Utils.amountFormatter(val)}`;
        // return typeArr[opt.dataPointIndex];
        return typeArr[opt.seriesIndex];
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "K";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  const revenueByProviderSubCategorySeries = [
    {
      name: "2023",
      data: [400, 430, 448, 470, 540],
    },
    {
      name: "2022",
      data: [200, 530, 648, 770, 240],
    },
  ];
  const revenueByProviderSubCategoryOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ["#917BD9", "#30A4FC"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "25%",
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Filling", "Endo", "Implant", "Crown", "Extraction"],
    },
    legend: {
      position: "top",
      // horizontalAlign: 'left',
    },
  };

  const cyTreatmentlanVsExpectedChartSeries = [
    {
      name: "Current",
      data: [
        // {
        //   x: '2011',
        //   y: 1292,
        //   goals: [
        //     {
        //       name: 'Expected',
        //       value: 1400,
        //       strokeHeight: 5,
        //       strokeColor: '#775DD0'
        //     }
        //   ]
        // },
        {
          x: "2023",
          y: 4432,
          goals: [
            {
              name: "Expected",
              value: 5400,
              strokeHeight: 5,
              strokeColor: "#F2994A",
            },
          ],
        },
        // {
        //   x: '2013',
        //   y: 5423,
        //   goals: [
        //     {
        //       name: 'Expected',
        //       value: 5200,
        //       strokeHeight: 5,
        //       strokeColor: '#775DD0'
        //     }
        //   ]
        // },
        // {
        //   x: '2014',
        //   y: 6653,
        //   goals: [
        //     {
        //       name: 'Expected',
        //       value: 6500,
        //       strokeHeight: 5,
        //       strokeColor: '#775DD0'
        //     }
        //   ]
        // },
        // {
        //   x: '2015',
        //   y: 8133,
        //   goals: [
        //     {
        //       name: 'Expected',
        //       value: 6600,
        //       strokeHeight: 13,
        //       strokeWidth: 0,
        //       strokeLineCap: 'round',
        //       strokeColor: '#775DD0'
        //     }
        //   ]
        // },
        // {
        //   x: '2016',
        //   y: 7132,
        //   goals: [
        //     {
        //       name: 'Expected',
        //       value: 7500,
        //       strokeHeight: 5,
        //       strokeColor: '#775DD0'
        //     }
        //   ]
        // },
        // {
        //   x: '2017',
        //   y: 7332,
        //   goals: [
        //     {
        //       name: 'Expected',
        //       value: 8700,
        //       strokeHeight: 5,
        //       strokeColor: '#775DD0'
        //     }
        //   ]
        // },
        // {
        //   x: '2018',
        //   y: 6553,
        //   goals: [
        //     {
        //       name: 'Expected',
        //       value: 7300,
        //       strokeHeight: 2,
        //       strokeDashArray: 2,
        //       strokeColor: '#775DD0'
        //     }
        //   ]
        // }
      ],
    },
  ];
  const cyTreatmentlanVsExpectedChartOptions = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "8%",
      },
    },
    colors: ["#27AE60", "#F2994A"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
      background: "#BFCFE7",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ["Current", "Expected"],
      markers: {
        fillColors: ["#00E396", "#775DD0"],
      },
      position: "top",
      horizontalAlign: "right",
      offsetX: 40,
    },
  };

  const cyRevPerTimeSpentSeries = [{ name: "Revenue", data: [50, 60, 80, 20] }];
  const cyRevPerTimeSpentOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ["#489677", "#917BD9", "#30A4FC", "#F2994A"],
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "25%",
        endingShape: "rounded",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        // return val + "% decrease compared to last year"
        return `$${Utils.amountFormatter(val)}`;
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Ortho", "Preventive", "Endo", "Perio"],
      labels: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      // max: 25,
      max: 500,
      tickAmount: 5,
      labels: {
        formatter: function (val, index) {
          return `${val}`;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Utils.amountFormatter(val);
        },
      },
    },
    grid: {
      show: false,
      background: "#BFCFE7",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
  };

  const cyRadialChartSeries = [88];
  const cyRadialChartOptions = {
    chart: {
      height: 350,
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: "#888",
            fontSize: "17px",
          },
          value: {
            formatter: function (val) {
              return `${parseInt(val)}%`;
            },
            color: "#111",
            fontSize: "36px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#ABE5A1"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Overall"],
  };

  const treatmentPlanVsActualSeries = [
    {
      name: "Actual",
      data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 1200, 1100],
    },
    {
      name: "Last Year",
      data: [200, 530, 648, 770, 240, 480, 990, 900, 1300, 1180, 900, 100],
    },
    {
      name: "Planned",
      data: [500, 230, 448, 270, 740, 880, 1190, 300, 1000, 1080, 200, 800],
    },
  ];
  const treatmentPlanVsActualOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ["#184332", "#917BD9", "#30A4FC"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    legend: {
      position: "top",
      // horizontalAlign: 'left',
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: "10px",
    },
  }));

  return (
    <NavAndTopBar>
      <div className="mx-6">
        {/* Title and filter section */}
        <div className="flex items-center justify-between gap-4 py-6">
          <div className="flex flex-col">
            <p className="font-bold text-2xl">Revenue</p>
          </div>
          <div className="flex gap-3">
            <div className="flex flex-col justify-center items-center relative">
              <div className="flex items-center gap-2">
                <div>From</div>
                <div
                  className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px] bg-white"
                  onClick={() => {
                    setFromDateOpen(!fromDateOpen);
                    setToDateOpen(false);
                  }}
                >
                  <div>{dayjs(fromDate).format("MMM, YYYY")}</div>
                  <CalendarMonthOutlinedIcon />
                </div>
              </div>
              {fromDateOpen && (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className="fixed bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateCalendar"]}>
                        <DateCalendar
                          value={fromDate}
                          onChange={(newValue) => {
                            setFromDate(newValue);
                            setFromDateOpen(!fromDateOpen);
                          }}
                          views={["month", "year"]}
                          disableFuture
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </ClickAwayListener>
                // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
              )}
            </div>
            <div className="flex flex-col justify-center items-center relative">
              <div className="flex items-center gap-2">
                <div>To</div>
                <div
                  className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px] bg-white"
                  onClick={() => {
                    setToDateOpen(!toDateOpen);
                    setFromDateOpen(false);
                  }}
                >
                  <div>{dayjs(toDate).format("MMM, YYYY")}</div>
                  <CalendarMonthOutlinedIcon />
                </div>
              </div>
              {toDateOpen && (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className="fixed bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateCalendar"]}>
                        <DateCalendar
                          value={toDate}
                          onChange={(newValue) => {
                            setToDate(newValue);
                            setToDateOpen(!toDateOpen);
                          }}
                          views={["month", "year"]}
                          disableFuture
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </ClickAwayListener>
                // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
              )}
            </div>
            <FormControl>
              <Select
                id="filterBy"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "500",
                  padding: "0 5px",
                  color: "#184332",
                  borderRadius: "30px",
                  height: "40px",
                  backgroundColor: "white",
                }}
                fullWidth
                value={filterBy}
                onChange={handleFilterBy}
                renderValue={(selected) => {
                  return (
                    <Box
                      sx={{ display: "flex", gap: 1 }}
                      className="flex items-center"
                    >
                      <SvgIcon color="primary">
                        <TuneIcon sx={{ color: "#184332" }} />
                      </SvgIcon>
                      <em>
                        {selected.length === 0
                          ? "Filter By"
                          : Utils.camelCaseToTitleCase(selected)}
                      </em>
                    </Box>
                  );
                }}
                displayEmpty
              >
                <MenuItem value="" sx={{ backgroundColor: "white" }}>
                  Filter By
                </MenuItem>
                <hr style={{ margin: "0 15px" }} />
                <MenuItem
                  value="industryAvg"
                  sx={{ margin: "0 15px", borderRadius: "10px" }}
                >
                  Industry Avg
                </MenuItem>
                <MenuItem
                  value="sameAsLastYear"
                  sx={{ margin: "0 15px", borderRadius: "10px" }}
                >
                  Same As Last Year
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {/* Main Metrics Section */}
        <div className="bg-white rounded-xl shadow-md w-full my-6">
          <div className="flex justify-between p-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">Main Metrics</p>
              <LightTooltip
                title={
                  <div>
                    <p className="font-bold">
                      Main Metrics{" "}
                      <span className="font-light text-sm">
                        : Main metrics description
                      </span>
                    </p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
            <div className="flex justify-evenly items-center bg-white shadow-lg rounded-full px-1 w-[220px] h-[40px]">
              <div
                className={`flex items-center justify-center w-[70px] h-[35px] ${
                  mainMetricsViewBy === "ebitda" ? "bg-[#184332]" : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setMainMetricsViewBy("ebitda")}
              >
                <p
                  className={`${
                    mainMetricsViewBy === "ebitda"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  EBITDA
                </p>
              </div>
              <div
                className={`flex items-center justify-center w-[70px] h-[35px] ${
                  mainMetricsViewBy === "revenue" ? "bg-[#184332]" : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setMainMetricsViewBy("revenue")}
              >
                <p
                  className={`${
                    mainMetricsViewBy === "revenue"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  Revenue
                </p>
              </div>
              <div
                className={`flex items-center justify-center w-[70px] h-[35px] ${
                  mainMetricsViewBy === "expense" ? "bg-[#184332]" : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setMainMetricsViewBy("expense")}
              >
                <p
                  className={`${
                    mainMetricsViewBy === "expense"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  Expense
                </p>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="flex flex-col justify-center items-center relative">
                <div className="flex items-center gap-2">
                  <div className="text-xs">From</div>
                  <div
                    className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[25px] w-[100px] bg-white text-xs"
                    onClick={() => {
                      setmmFromDateOpen(!mmfromDateOpen);
                      setmmToDateOpen(false);
                    }}
                  >
                    <div>{dayjs(mmfromDate).format("MMM, YYYY")}</div>
                    <CalendarMonthOutlinedIcon fontSize="small" />
                  </div>
                </div>
                {mmfromDateOpen && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateCalendar"]}>
                          <DateCalendar
                            value={mmfromDate}
                            onChange={(newValue) => {
                              setmmFromDate(newValue);
                              setmmFromDateOpen(!mmfromDateOpen);
                            }}
                            views={["month", "year"]}
                            disableFuture
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </ClickAwayListener>
                  // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
                )}
              </div>
              <div className="flex flex-col justify-center items-center relative">
                <div className="flex items-center gap-2">
                  <div className="text-xs">To</div>
                  <div
                    className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[25px] w-[100px] bg-white text-xs"
                    onClick={() => {
                      setmmToDateOpen(!mmtoDateOpen);
                      setmmFromDateOpen(false);
                    }}
                  >
                    <div>{dayjs(mmtoDate).format("MMM, YYYY")}</div>
                    <CalendarMonthOutlinedIcon fontSize="small" />
                  </div>
                </div>
                {mmtoDateOpen && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateCalendar"]}>
                          <DateCalendar
                            value={mmtoDate}
                            onChange={(newValue) => {
                              setmmToDate(newValue);
                              setmmToDateOpen(!mmtoDateOpen);
                            }}
                            views={["month", "year"]}
                            disableFuture
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </ClickAwayListener>
                  // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
                )}
              </div>
            </div>
          </div>
          <ReactApexChart
            options={mainMetricsOptions}
            series={mainMetricsSeries}
            type="line"
            height={350}
          />
        </div>
        {/* Revenue Section */}
        <div className="bg-white rounded-xl shadow-md w-full my-6">
          <div className="flex justify-between p-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">Revenue</p>
              <LightTooltip
                title={
                  <div>
                    <p className="font-bold">
                      Revenue{" "}
                      <span className="font-light text-sm">
                        : Revenue description
                      </span>
                    </p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
            <div className="flex justify-evenly items-center bg-white shadow-lg rounded-full px-1 w-[340px] h-[40px]">
              <div
                className={`flex items-center justify-center w-[170px] h-[35px] ${
                  revenueChartViewBy === "revPerAppointment"
                    ? "bg-[#184332]"
                    : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setRevenueChartViewBy("revPerAppointment")}
              >
                <p
                  className={`${
                    revenueChartViewBy === "revPerAppointment"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  Rev. Per Appointment
                </p>
              </div>
              <div
                className={`flex items-center justify-center w-[170px] h-[35px] ${
                  revenueChartViewBy === "revPerPat"
                    ? "bg-[#184332]"
                    : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setRevenueChartViewBy("revPerPat")}
              >
                <p
                  className={`${
                    revenueChartViewBy === "revPerPat"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  Rev. Per Patient
                </p>
              </div>
            </div>
            <div className="flex gap-3">
              <FormControl>
                <Select
                  id="filterBy"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0 5px",
                    color: "#184332",
                    borderRadius: "30px",
                    height: "30px",
                    backgroundColor: "#F3F4F5",
                  }}
                  fullWidth
                  value={filterBy}
                  onChange={handleFilterBy}
                  renderValue={(selected) => {
                    return (
                      <Box
                        sx={{ display: "flex", gap: 1 }}
                        className="flex items-center"
                      >
                        <em>
                          {selected.length === 0
                            ? "View By"
                            : Utils.camelCaseToTitleCase(selected)}
                        </em>
                      </Box>
                    );
                  }}
                  displayEmpty
                >
                  <MenuItem value="" sx={{ backgroundColor: "white" }}>
                    View By
                  </MenuItem>
                  <hr style={{ margin: "0 15px" }} />
                  <MenuItem
                    value="yearly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Yearly
                  </MenuItem>

                  <MenuItem
                    value="monthly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Monthly
                  </MenuItem>
                  {/*<MenuItem value="sameAsLastYear" sx={{margin: "0 15px", borderRadius: "10px"}}>Same As Last Year</MenuItem>*/}
                </Select>
              </FormControl>
            </div>
          </div>
          <ReactApexChart
            options={revenueChartOptions}
            series={revenueChartSeries}
            type="line"
            height={350}
          />
        </div>
        {/* Appointment Section */}
        <div className="bg-white rounded-xl shadow-md w-full my-6">
          <div className="flex justify-between p-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">Appointments</p>
              <LightTooltip
                title={
                  <div>
                    <p className="font-bold">
                      Appointments{" "}
                      <span className="font-light text-sm">
                        : Appointments description
                      </span>
                    </p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
            <div className="flex justify-evenly items-center bg-white shadow-lg rounded-full px-1 w-[220px] h-[40px]">
              <div
                className={`flex items-center justify-center w-[70px] h-[35px] ${
                  appointmentChartViewBy === "actual"
                    ? "bg-[#184332]"
                    : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setAppointmentChartViewBy("actual")}
              >
                <p
                  className={`${
                    appointmentChartViewBy === "actual"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  Actual
                </p>
              </div>
              <div
                className={`flex items-center justify-center w-[70px] h-[35px] ${
                  appointmentChartViewBy === "planned"
                    ? "bg-[#184332]"
                    : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setAppointmentChartViewBy("planned")}
              >
                <p
                  className={`${
                    appointmentChartViewBy === "planned"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  Planned
                </p>
              </div>
              <div
                className={`flex items-center justify-center w-[70px] h-[35px] ${
                  appointmentChartViewBy === "cancelled"
                    ? "bg-[#184332]"
                    : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setAppointmentChartViewBy("cancelled")}
              >
                <p
                  className={`${
                    appointmentChartViewBy === "cancelled"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  Cancelled
                </p>
              </div>
            </div>
            <div className="flex gap-3">
              <FormControl>
                <Select
                  id="filterBy"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0 5px",
                    color: "#184332",
                    borderRadius: "30px",
                    height: "30px",
                    backgroundColor: "#F3F4F5",
                  }}
                  fullWidth
                  value={filterBy}
                  onChange={handleFilterBy}
                  renderValue={(selected) => {
                    return (
                      <Box
                        sx={{ display: "flex", gap: 1 }}
                        className="flex items-center"
                      >
                        <em>
                          {selected.length === 0
                            ? "View By"
                            : Utils.camelCaseToTitleCase(selected)}
                        </em>
                      </Box>
                    );
                  }}
                  displayEmpty
                >
                  <MenuItem value="" sx={{ backgroundColor: "white" }}>
                    View By
                  </MenuItem>
                  <hr style={{ margin: "0 15px" }} />
                  <MenuItem
                    value="yearly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Yearly
                  </MenuItem>

                  <MenuItem
                    value="monthly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Monthly
                  </MenuItem>
                  {/*<MenuItem value="sameAsLastYear" sx={{margin: "0 15px", borderRadius: "10px"}}>Same As Last Year</MenuItem>*/}
                </Select>
              </FormControl>
            </div>
          </div>
          <ReactApexChart
            options={appointmentsChartOptions}
            series={appointmentsChartSeries}
            type="bar"
            height={350}
          />
        </div>
        {/* Revenue by type Section */}
        <div className="bg-white rounded-xl shadow-md w-full my-6">
          <div className="flex justify-between p-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">Revenue by Type</p>
              <LightTooltip
                title={
                  <div>
                    <p className="font-bold">
                      Revenue by Type{" "}
                      <span className="font-light text-sm">
                        : Revenue by Type description
                      </span>
                    </p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
            <div className="flex justify-evenly items-center bg-white shadow-lg rounded-full px-1 w-[220px] h-[40px]">
              <div
                className={`flex items-center justify-center w-[70px] h-[35px] ${
                  mainMetricsViewBy === "ebitda" ? "bg-[#184332]" : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setMainMetricsViewBy("ebitda")}
              >
                <p
                  className={`${
                    mainMetricsViewBy === "ebitda"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  EBITDA
                </p>
              </div>
              <div
                className={`flex items-center justify-center w-[70px] h-[35px] ${
                  mainMetricsViewBy === "revenue" ? "bg-[#184332]" : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setMainMetricsViewBy("revenue")}
              >
                <p
                  className={`${
                    mainMetricsViewBy === "revenue"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  Revenue
                </p>
              </div>
              <div
                className={`flex items-center justify-center w-[70px] h-[35px] ${
                  mainMetricsViewBy === "expense" ? "bg-[#184332]" : "bg-white"
                } rounded-full cursor-pointer`}
                onClick={() => setMainMetricsViewBy("expense")}
              >
                <p
                  className={`${
                    mainMetricsViewBy === "expense"
                      ? "button-linear-text"
                      : "button-dashboard-tab-text"
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  Expense
                </p>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="flex flex-col justify-center items-center relative">
                <div className="flex items-center gap-2">
                  <div className="text-xs">From</div>
                  <div
                    className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[25px] w-[100px] bg-white text-xs"
                    onClick={() => {
                      setmmFromDateOpen(!mmfromDateOpen);
                      setmmToDateOpen(false);
                    }}
                  >
                    <div>{dayjs(mmfromDate).format("MMM, YYYY")}</div>
                    <CalendarMonthOutlinedIcon fontSize="small" />
                  </div>
                </div>
                {mmfromDateOpen && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateCalendar"]}>
                          <DateCalendar
                            value={mmfromDate}
                            onChange={(newValue) => {
                              setmmFromDate(newValue);
                              setmmFromDateOpen(!mmfromDateOpen);
                            }}
                            views={["month", "year"]}
                            disableFuture
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </ClickAwayListener>
                  // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
                )}
              </div>
              <div className="flex flex-col justify-center items-center relative">
                <div className="flex items-center gap-2">
                  <div className="text-xs">To</div>
                  <div
                    className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[25px] w-[100px] bg-white text-xs"
                    onClick={() => {
                      setmmToDateOpen(!mmtoDateOpen);
                      setmmFromDateOpen(false);
                    }}
                  >
                    <div>{dayjs(mmtoDate).format("MMM, YYYY")}</div>
                    <CalendarMonthOutlinedIcon fontSize="small" />
                  </div>
                </div>
                {mmtoDateOpen && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateCalendar"]}>
                          <DateCalendar
                            value={mmtoDate}
                            onChange={(newValue) => {
                              setmmToDate(newValue);
                              setmmToDateOpen(!mmtoDateOpen);
                            }}
                            views={["month", "year"]}
                            disableFuture
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </ClickAwayListener>
                  // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
                )}
              </div>
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-[49.99%]">
              <ReactApexChart
                options={mainMetricsOptions}
                series={mainMetricsSeries}
                type="line"
                height={350}
              />
            </div>
            <div className="bg-[#E0E0E0] w-[0.02%] my-6"></div>
            <div className="w-[49.99%]">
              <ReactApexChart
                options={revenueByTypeOptions}
                series={revenueByTypeSeries}
                type="radialBar"
                height={350}
              />
            </div>
          </div>
        </div>
        {/* Revenue by Provider/Type Section */}
        <div className="bg-white rounded-xl shadow-md w-full my-6">
          <div className="flex justify-between p-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">Revenue by Provider/Type</p>
              <LightTooltip
                title={
                  <div>
                    <p className="font-bold">
                      Revenue by Provider/Type{" "}
                      <span className="font-light text-sm">
                        : Revenue by Provider/Type description
                      </span>
                    </p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
            <div className="flex gap-3">
              <FormControl>
                <Select
                  id="filterBy"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0 5px",
                    color: "#184332",
                    borderRadius: "30px",
                    height: "30px",
                    backgroundColor: "#F3F4F5",
                  }}
                  fullWidth
                  value={filterBy}
                  onChange={handleFilterBy}
                  renderValue={(selected) => {
                    return (
                      <Box
                        sx={{ display: "flex", gap: 1 }}
                        className="flex items-center"
                      >
                        <em>
                          {selected.length === 0
                            ? "View By"
                            : Utils.camelCaseToTitleCase(selected)}
                        </em>
                      </Box>
                    );
                  }}
                  displayEmpty
                >
                  <MenuItem value="" sx={{ backgroundColor: "white" }}>
                    View By
                  </MenuItem>
                  <hr style={{ margin: "0 15px" }} />
                  <MenuItem
                    value="yearly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Yearly
                  </MenuItem>

                  <MenuItem
                    value="monthly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Monthly
                  </MenuItem>
                  {/*<MenuItem value="sameAsLastYear" sx={{margin: "0 15px", borderRadius: "10px"}}>Same As Last Year</MenuItem>*/}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-[49.99%]">
              <ReactApexChart
                options={revenueByProvidersOptions}
                series={revenueByProviderSeries}
                type="bar"
                height={350}
              />
            </div>
            <div className="bg-[#E0E0E0] w-[0.02%] my-6"></div>
            <div className="w-[49.99%]">
              <ReactApexChart
                options={revenueByProviderSubCategoryOptions}
                series={revenueByProviderSubCategorySeries}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>
        {/* CY Treatment plan vs expected Section */}
        <div className="bg-white rounded-xl shadow-md w-full my-6">
          <div className="flex justify-between p-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">CY Treatment Plan vs Expected</p>
              <LightTooltip
                title={
                  <div>
                    <p className="font-bold">
                      CY Treatment Plan vs Expected{" "}
                      <span className="font-light text-sm">
                        : CY Treatment Plan vs Expected description
                      </span>
                    </p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
            <div className="flex gap-3">
              <FormControl>
                <Select
                  id="filterBy"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0 5px",
                    color: "#184332",
                    borderRadius: "30px",
                    height: "30px",
                    backgroundColor: "#F3F4F5",
                  }}
                  fullWidth
                  value={filterBy}
                  onChange={handleFilterBy}
                  renderValue={(selected) => {
                    return (
                      <Box
                        sx={{ display: "flex", gap: 1 }}
                        className="flex items-center"
                      >
                        <em>
                          {selected.length === 0
                            ? "View By"
                            : Utils.camelCaseToTitleCase(selected)}
                        </em>
                      </Box>
                    );
                  }}
                  displayEmpty
                >
                  <MenuItem value="" sx={{ backgroundColor: "white" }}>
                    View By
                  </MenuItem>
                  <hr style={{ margin: "0 15px" }} />
                  <MenuItem
                    value="yearly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Yearly
                  </MenuItem>

                  <MenuItem
                    value="monthly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Monthly
                  </MenuItem>
                  {/*<MenuItem value="sameAsLastYear" sx={{margin: "0 15px", borderRadius: "10px"}}>Same As Last Year</MenuItem>*/}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-[49.99%]">
              <ReactApexChart
                options={cyTreatmentlanVsExpectedChartOptions}
                series={cyTreatmentlanVsExpectedChartSeries}
                type="bar"
                height={350}
              />
            </div>
            <div className="bg-[#E0E0E0] w-[0.02%] my-6"></div>
            <div className="w-[50%]">
              <div className="flex w-full">
                <div className="w-[49.99%]">
                  <ReactApexChart
                    options={cyRevPerTimeSpentOptions}
                    series={cyRevPerTimeSpentSeries}
                    type="bar"
                    height={350}
                  />
                </div>
                <div className="w-[50%]">
                  <div className="">
                    <ReactApexChart
                      options={cyRadialChartOptions}
                      series={cyRadialChartSeries}
                      type="radialBar"
                      height={250}
                    />
                    <p className="px-6 text-center">
                      Dr.1 has very good turn over time with each Category
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Treatment Plan vs Actual Section */}
        <div className="bg-white rounded-xl shadow-md w-full my-6">
          <div className="flex justify-between p-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">% Treatment Plan vs Actual</p>
              <LightTooltip
                title={
                  <div>
                    <p className="font-bold">
                      % Treatment Plan vs Actual{" "}
                      <span className="font-light text-sm">
                        : % Treatment Plan vs Actual description
                      </span>
                    </p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
            <div className="flex gap-3">
              <FormControl>
                <Select
                  id="filterBy"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0 5px",
                    color: "#184332",
                    borderRadius: "30px",
                    height: "30px",
                    backgroundColor: "#F3F4F5",
                  }}
                  fullWidth
                  value={filterBy}
                  onChange={handleFilterBy}
                  renderValue={(selected) => {
                    return (
                      <Box
                        sx={{ display: "flex", gap: 1 }}
                        className="flex items-center"
                      >
                        <em>
                          {selected.length === 0
                            ? "View By"
                            : Utils.camelCaseToTitleCase(selected)}
                        </em>
                      </Box>
                    );
                  }}
                  displayEmpty
                >
                  <MenuItem value="" sx={{ backgroundColor: "white" }}>
                    View By
                  </MenuItem>
                  <hr style={{ margin: "0 15px" }} />
                  <MenuItem
                    value="yearly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Yearly
                  </MenuItem>

                  <MenuItem
                    value="monthly"
                    sx={{ margin: "0 15px", borderRadius: "10px" }}
                  >
                    Monthly
                  </MenuItem>
                  {/*<MenuItem value="sameAsLastYear" sx={{margin: "0 15px", borderRadius: "10px"}}>Same As Last Year</MenuItem>*/}
                </Select>
              </FormControl>
            </div>
          </div>
          <ReactApexChart
            options={treatmentPlanVsActualOptions}
            series={treatmentPlanVsActualSeries}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </NavAndTopBar>
  );
}
