import React from "react";
import CryptoJS from "crypto-js";
import {SecretKey} from "./envVariables";

class UtilsServices {
    SecretSalt = "CodeCoffeeCo";

    encryptedData = (data) => {
        return CryptoJS.AES.encrypt(
            JSON.stringify(data),
            this.SecretSalt
        ).toString();
    };

    decryptedData = (data) => {
        const bytes = CryptoJS.AES.decrypt(data, this.SecretSalt);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };

    getUserData = () => {
        const isLocalData = localStorage.getItem(SecretKey);
        return isLocalData ? this.decryptedData(localStorage.getItem(SecretKey)) : null;
    };

    camelCaseToTitleCase = (text) => {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1)
    }

    amountFormatter = (num)=> {
        if (Math.abs(num) > 999 && Math.abs(num) < 1000000) return Math.sign(num)*((Math.abs(num)/1000).toFixed()) + ' K'
        else if (Math.abs(num) > 999999) return Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + ' M'
        else return  Math.sign(num)*Math.abs(num)
    }

    amountFormatterWithDecimal = (num)=> {
        if (Math.abs(num) > 999 && Math.abs(num) < 1000000) return Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + ' K'
        else if (Math.abs(num) > 999999) return Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + ' M'
        else return  Math.sign(num)*Math.abs(num)
    }

    calcPercentage = (currentValue, percentageOfValue, fixed = 1)=> {
        const percent = (currentValue / percentageOfValue) * 100
        return percent.toFixed(fixed) ;
    }

    remainingMonthCount = (currentMonth)=> {
        return 12 - Number(currentMonth)
    }

    getMonthNameFromMonthNum =(num)=> {
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        return months[Number(num)-1]
    }

    getMonthNamesFromDateRange = (startDate, endDate)=> {
        let startMonth = new Date(startDate).getMonth()+1
        let endMonth = new Date(endDate).getMonth()+1
        let startYear = new Date(startDate).getFullYear()
        let endYear = new Date(endDate).getFullYear()
        // console.log("startDate : ",startDate)
        // console.log("endDate : ",endDate)
        // console.log("startMonth :", startMonth , "endMonth:", endMonth,  "startYear:",startYear, "endYear:",endYear)
        let yearCount = endYear - startYear
        // console.log("yearCount : ",yearCount)
        let monthArr = []
        for (let year = startYear; year <= endYear; year++) {
            if (year === startYear) {
                for (let i=startMonth; i<=12; i++) {
                    monthArr.push(`${this.getMonthNameFromMonthNum(i)}-${startYear}`)
                }
            } else if (year !== endYear) {
                for (let month=1; month<=12; month++) {
                    monthArr.push(`${this.getMonthNameFromMonthNum(month)}-${year}`)
                }
            } else if (year === endYear) {
                for (let i=1; i<=endMonth; i++) {
                    monthArr.push(`${this.getMonthNameFromMonthNum(i)}-${endYear}`)
                }
            }
        }

        return monthArr

    }

    getTenPerchantgeLessNumber = (number)=> {
        return number - (number * 0.10) > 0 ? number - (number * 0.10) : number;
    }

    getRandomHexColorCode = ()=> {
        let hexColor = '#' + Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0');
        if (hexColor !== '#ffffff') {
            return hexColor;
        }else {
            return this.getRandomHexColorCode()
        }
    }
}

const instance = new UtilsServices();
export default instance;
