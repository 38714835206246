import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {useState} from "react";
import {Controller} from "react-hook-form";

export default function SelectOptionsDropdown({label, control, errors, controllerName, placeholder, options = [], required = false}) {

    return (
        <>
            <p className="input-field-label-text">{label}</p>
            <Controller
                name={controllerName}
                control={control}
                render={({ field }) => (
                    <FormControl
                        error={!!errors[controllerName]}
                        fullWidth
                    >
                        <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{fontFamily: "Poppins",
                                fontSize: "8px",
                                fontWeight: "500",
                                padding: "0 5px",
                                color: "#7A828A",
                                borderRadius: "30px",
                                height: "40px",
                                backgroundColor: "#F2F2F2"
                            }}
                            displayEmpty
                        >
                            <MenuItem value=""  sx={{backgroundColor: "white"}}>
                                {placeholder || "Select Your Role"}
                            </MenuItem>
                            <hr style={{margin: "0 15px"}}/>
                            {options.map((option)=> (
                                <MenuItem value={option?.key || option?.id} sx={{margin: "0 15px", borderRadius: "10px"}}>{option?.title || option?.email}</MenuItem>
                            ))}
                            {/*<MenuItem value="dentist" sx={{margin: "0 15px", borderRadius: "10px"}}>Dentist</MenuItem>*/}
                            {/*<MenuItem value="manager" sx={{margin: "0 15px", borderRadius: "10px"}}>Manager</MenuItem>*/}
                            {/*<MenuItem value="receptionist" sx={{margin: "0 15px", borderRadius: "10px"}}>Receptionist</MenuItem>*/}
                            {/*<MenuItem value="other" sx={{margin: "0 15px", borderRadius: "10px"}}>Other</MenuItem>*/}
                        </Select>
                        <FormHelperText>
                            {errors[controllerName]?.message}
                        </FormHelperText>
                    </FormControl>
                )}
            />
        </>
    )
}