import { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import LocalPharmacyOutlinedIcon from "@mui/icons-material/LocalPharmacyOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Avatar, InputAdornment, Popover, TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AuthServices from "../../../data-access/services/authServices";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import VaccinesOutlinedIcon from "@mui/icons-material/VaccinesOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";

const drawerWidth = 230;

export default function NavAndTopBar({ children, window }) {
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const [avatarEl, setAvatarEl] = useState(null);
  const open = Boolean(avatarEl);
  const id = open ? "simpe-popover" : undefined;

  const menus = [
    {
      title: "Dashboard",
      route: "/dashboard",
    },
    {
      title: "Recommendations",
      route: "/recommendations",
    },
    {
      title: "Revenue",
      route: "/revenue-dashboard",
    },
    {
      title: "Patients",
      route: "/patients",
    },
    {
      title: "Operations",
      route: "/operations",
    },
    {
      title: "Profile",
      route: "/profile",
    },
    {
      title: "Permissions",
      route: "/permissions",
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    AuthServices.logout();
  };

  const handleAvatarClick = (e) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const drawer = (
    <div>
      <div style={{ height: "75px" }} className="flex justify-center p-3">
        <img src="/WAIG-Logo-2.png" alt="" />
      </div>
      <div className="navbarMenuItemContainer">
        {menus.map((menu, index) => (
          <div
            key={menu.title}
            className={`flex gap-3 py-1 px-2 items-center mb-3 ${
              location.pathname === menu?.route ? "navbarMenuItemActive" : ""
            }`}
            onClick={() =>
              [
                "/dashboard",
                "/profile",
                "/permissions",
                "/revenue-dashboard",
                "/recommendations",
              ].includes(menu.route)
                ? navigate(menu.route)
                : navigate("/active-your-plan")
            }
          >
            <p
              style={{
                color: location.pathname === menu.route ? "#B68340" : "#828282",
              }}
            >
              {index === 0 ? (
                <HomeOutlinedIcon />
              ) : index === 1 ? (
                <ThumbUpOffAltOutlinedIcon />
              ) : index === 2 ? (
                <AttachMoneyOutlinedIcon />
              ) : index === 3 ? (
                <LocalPharmacyOutlinedIcon />
              ) : index === 4 ? (
                <VaccinesOutlinedIcon />
              ) : index === 5 ? (
                <PersonOutlineOutlinedIcon />
              ) : index === 6 ? (
                <PeopleOutlineOutlinedIcon />
              ) : (
                ""
              )}
            </p>
            <p
              className={`${
                location.pathname === menu.route
                  ? "navbarMenuItemActive"
                  : "navbarMenuItem"
              }`}
            >
              {menu.title}
            </p>
          </div>
          // <ListItem key={text} disablePadding>
          //     <ListItemButton>
          //         <ListItemIcon>
          //
          //         </ListItemIcon>
          //         <ListItemText primary={text} primaryTypographyProps={{ fontSize: "13px"}} />
          //     </ListItemButton>
          // </ListItem>
        ))}
      </div>
      <Divider />
      <div className="flex justify-center py-4">
        <p
          style={{ fontFamily: "Poppins", color: "#828282" }}
          className="font-normal"
        >
          Support
        </p>
      </div>
      <div className="navbarMenuItemContainer">
        {["Get Started", "Contact Us"].map((text, index) => (
          <div key={text} className="flex gap-3 py-1 px-2 items-center mb-3">
            <p style={{ color: "#828282" }}>
              {index === 1 ? (
                <ConnectWithoutContactOutlinedIcon />
              ) : (
                <InfoOutlinedIcon />
              )}
            </p>
            <p className="navbarMenuItem">{text}</p>
          </div>
        ))}
      </div>
      <div className="navbarMenuItemContainer pt-[50px]">
        {["Settings", "Logout"].map((text, index) => (
          <div
            key={text}
            className="flex gap-3 py-1 px-2 items-center mb-3"
            onClick={() => (index === 1 ? handleLogout() : null)}
          >
            <p style={{ color: "#828282" }}>
              {index === 0 ? <SettingsOutlinedIcon /> : <LogoutOutlinedIcon />}
            </p>
            <p className="navbarMenuItem">{text}</p>
          </div>
          // <ListItem key={text} disablePadding>
          //     <ListItemButton>
          //         <ListItemIcon>
          //             {index === 0 ? <SettingsOutlinedIcon/> : <LogoutOutlinedIcon/>}
          //         </ListItemIcon>
          //         <ListItemText primary={text} primaryTypographyProps={{ fontSize: "13px"}} />
          //     </ListItemButton>
          // </ListItem>
        ))}
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div style={{ display: "flex", backgroundColor: "white" }}>
      <div
        style={{
          position: "fixed",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        className="flex"
      >
        <div className="flex w-full">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 1, mt: 2.5, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </div>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#F3F4F5",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/*<Toolbar />*/}
        <div
          style={{ height: "80px" }}
          className="bg-white flex justify-between items-center px-10"
        >
          <TextField
            InputProps={{
              sx: {
                borderRadius: "50px",
                width: { xs: "200px", md: "400px" },
                height: "50px",
                backgroundColor: "#F2F2F2",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon sx={{ color: "#969696" }} />
                </InputAdornment>
              ),
            }}
            placeholder="Search..."
          />
          <div className="flex gap-2">
            <div className="flex gap-3 items-center">
              <HelpOutlineOutlinedIcon
                sx={{ color: "#969696", cursor: "pointer" }}
              />
              <NotificationsOutlinedIcon
                sx={{ color: "#969696", cursor: "pointer" }}
              />
            </div>
            <Avatar
              alt="Remy Sharp"
              src="/avatar.png"
              sx={{ cursor: "pointer" }}
              aria-describedby={id}
              onClick={handleAvatarClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={avatarEl}
              onClose={handleAvatarClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List>
                <ListItem disablePadding>
                  {/*<ListItemButton onClick={()=> navigate("/settings")}>*/}
                  <ListItemButton onClick={() => navigate("/active-your-plan")}>
                    <ListItemText primary="Settings" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleLogout()}>
                    <ListItemText primary="Log out" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>
          </div>
        </div>
        {children}
      </Box>
    </div>
  );
}
