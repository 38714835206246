import React from "react";
import axios from "axios";
import {EnvVariable, SecretKey} from "../../../utils/envVariables";
import jwtDecode from "jwt-decode";
import UtilsService from "../../UtilsService";
import Utils from "../../../utils/Utils";

class AuthService extends UtilsService.EventEmitter {
    setSession = (access_token) => {
        if (access_token) {
            axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        } else {
            delete axios.defaults.headers.common.Authorization;
        }
    };

    init = ()=> {
        this.handleAuthentication()
    }

    handleAuthentication = () => {
        // const access_token = this.getAccessToken();
        const user = this.getUserInfo();
        const access_token = user?.token_data?.access_token

        if (!access_token) {
            this.emit('onNoAccessToken');

            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    autoSignIn = () => {
        const userInfo = this.getUserInfo();
        if (userInfo) {
            return new Promise((resolve, reject) => {
                this.setSession(userInfo.token_data.access_token);
                // this.setAutoLoginInfo(cred);
                this.setUserInfo(userInfo);
                resolve(userInfo);
                this.emit("onLogin", userInfo);
            });
        } else return null;
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        }

        return true;
    };

    setUserInfo = (userInfo) => {
        if (userInfo) {
            localStorage.removeItem(SecretKey);
            localStorage.setItem(SecretKey, Utils.encryptedData(userInfo));
        } else {
            localStorage.removeItem(SecretKey);
        }
    };

    getUserInfo = () => {
        return Utils.getUserData();
    };

    loginSuccessHandler = (userData)=> {
        this.setSession(userData.token_data.access_token)
        this.setUserInfo(userData)
        this.emit("onLogin", userData)
    }

    signUp = (params) => {
        return new Promise((resolve, reject) => {
            const URL = `${EnvVariable.BASEURL}/user/signup`;
            const data = {
                ...params,
                role: "other",
                status: "active",
                // fullName: ""
            }

            return axios
                .post(URL, data)
                .then((response) => {
                    if (response?.data?.status_code === 201) {
                        resolve(response);
                    } else reject("somethingWentWrong");
                })
                .catch((error) => {
                    reject(error.response.data.error);
                });
        });
    };

    profileSetupSkip = (stage, userId) => {
        return new Promise((resolve, reject) => {
            const URL = `${EnvVariable.BASEURL}/user/profile-setup-skip`;
            const data = {
                id: userId,
                isskip: true,
                stage: `${stage}`,
            }

            return axios
                .post(URL, data)
                .then((response) => {
                    if (response?.data?.status_code === 202) {
                        resolve(response);
                    } else reject("somethingWentWrong");
                })
                .catch((error) => {
                    reject(error.response.data.error);
                });
        });
    };

    login = (data) => {
        return new Promise((resolve, reject) => {
            const URL = `${EnvVariable.BASEURL}/user/login`;
            return axios
                .post(URL, data)
                .then((response) => {
                    if (response?.data?.status_code === 200) {
                        this.loginSuccessHandler(response.data.data)
                        resolve(response);
                    } else reject("somethingWentWrong");
                })
                .catch((error) => {
                    reject(error?.response?.data?.message);
                });
        });
    };

    logout = () => {
        this.emit("onLogout", "Logged out");
    };

    profileSetupScreenOne = (data) => {
        return new Promise((resolve, reject) => {
            const URL = `${EnvVariable.BASEURL}/user/profile-setup-step-one`;
            return axios
                .post(URL, data)
                .then((response) => {
                    if (response?.data?.status_code === 202) {
                        resolve(response);
                    } else reject("somethingWentWrong");
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        });
    };

    profileSetupScreenTwo = (data) => {
        return new Promise((resolve, reject) => {
            const URL = `${EnvVariable.BASEURL}/user/profile-setup-step-two`;
            return axios
                .post(URL, data)
                .then((response) => {
                    if (response?.data?.status_code === 202) {
                        resolve(response);
                    } else reject("somethingWentWrong");
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        });
    };

    forgetPassword = (email, id) => {
        return new Promise((resolve, reject) => {
            const URL = `${EnvVariable.BASEURL}/user/forget-password`;
            return axios
                .post(URL, {email, id})
                .then((response) => {
                    if (response?.data?.status_code === 201) {
                        resolve(response);
                    } else reject("somethingWentWrong");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    resetPassword = (password, token) => {
        return new Promise((resolve, reject) => {
            const URL = `${EnvVariable.BASEURL}/user/reset-password`;
            return axios
                .post(URL, {password, token})
                .then((response) => {
                    if (response?.data?.status_code === 202) {
                        resolve(response);
                    } else reject("somethingWentWrong");
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        });
    };
}

const instance = new AuthService();
export default instance;
