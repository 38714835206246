import { useContext, useEffect, useState } from "react";
import NavAndTopBar from "../../shared/layouts/NavAndTopBar";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import {
  Box,
  ClickAwayListener,
  FormControl,
  MenuItem,
  Select,
  SvgIcon,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import Utils from "../../../utils/Utils";
import ReactApexChart from "react-apexcharts";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateCalendar } from "@mui/x-date-pickers";
import AuthService from "../../../data-access/services/authServices/AuthService";
import { AuthContext } from "../../../contexts/AuthContext";

export default function TrendChart({ trendChartData, closeModal }) {
  const {fromDate, setFromDate, toDate, setToDate} = useContext(AuthContext)
  const {
    chartTitle,
    description,
    data,
    monthlyData,
    yearlyData,
    amount,
    descriptionLogoBg,
    topProvidersName,
  } = trendChartData;
  const [chartData, setChartData] = useState(
    yearlyData.length > 0
      ? yearlyData.map(
          (dta) =>
            dta?.ebidta ||
            dta?.total_income ||
            dta?.total_expenses ||
            dta?.practice_growth_rate ||
            dta?.churn_rate !== undefined ||
            dta?.production
        )
      : []
  );
  const [chartDataComp, setChartDataComp] = useState(
    yearlyData.length > 0
      ? yearlyData.map(
          (dta) =>
            dta?.ebidta ||
            dta?.total_income ||
            dta?.total_expenses ||
            dta?.practice_growth_rate ||
            dta?.churn_rate ||
            dta?.production
        )
      : []
  );
  const [chartLabels, setChartLabels] = useState(
    yearlyData.length > 0 ? yearlyData.map((dta) => dta?.year) : []
  );
  const [chartLabelsComp, setChartLabelsComp] = useState(
    yearlyData.length > 0 ? yearlyData.map((dta) => dta?.year) : []
  );
  const [filterBy, setFilterBy] = useState("yearly");
  const [fromDateOpen, setFromDateOpen] = useState(false);
  const [toDateOpen, setToDateOpen] = useState(false);
  // const [fromDate, setFromDate] = useState(
  //   dayjs(`${new Date().getFullYear() - 1}-01-01`)
  // );
  // const [toDate, setToDate] = useState(dayjs(`2023-11-28 00:00:00`));
  const user = AuthService.getUserInfo();
  const isDemoTeam = user?.user_data?.isDemoTeam;
  const [filterByProvider, setFilterByProvider] = useState(
    !!topProvidersName
      ? isDemoTeam
        ? topProvidersName[0].split("(")[1]
        : topProvidersName[0]
      : ""
  );

  // console.log("chartData : ", chartData)
  // console.log("yearlyData : ", yearlyData)
  // console.log("monthlyData : ", monthlyData)
  // console.log("chartLabels : ", chartLabels)
  // console.log("topProvidersName : ", topProvidersName)

  useEffect(() => {
    if (filterBy === "sameAsLastYear") {
      if (chartTitle === "EBITDA") {
        setChartData(
          yearlyData
            .filter((d) => {
              return d?.year >= `${dayjs(new Date()).year() - 3}`;
            })
            .map((dt) => dt?.ebidta)
        );
        // }).map((dt)=> dt?.ebitda))
        setChartLabels(
          yearlyData
            .filter((d) => {
              return d?.year >= `${dayjs(new Date()).year() - 3}`;
            })
            .map((dt) => dt?.year.split("-")[0])
        );
      } else if (chartTitle === "Revenue") {
        setChartData(
          yearlyData
            .filter((d) => {
              return d?.year >= `${dayjs(new Date()).year() - 3}`;
            })
            .map((dt) => dt?.total_income)
        );
        // }).map((dt)=> dt?.net_income))
        setChartLabels(
          yearlyData
            .filter((d) => {
              return d?.year >= `${dayjs(new Date()).year() - 3}`;
            })
            .map((dt) => dt?.year.split("-")[0])
        );
      } else if (chartTitle === "Expense") {
        setChartData(
          yearlyData
            .filter((d) => {
              return d?.year >= `${dayjs(new Date()).year() - 3}`;
            })
            .map((dt) => dt?.total_expenses)
        );
        // }).map((dt)=> dt?.interest_expense))
        setChartLabels(
          yearlyData
            .filter((d) => {
              return d?.year >= `${dayjs(new Date()).year() - 3}`;
            })
            .map((dt) => dt?.year.split("-")[0])
        );
      } else if (chartTitle === "Churn Rate") {
        setChartLabels(
          yearlyData
            .filter((d) => {
              return Number(d.year) >= `${dayjs(new Date()).year() - 2}`;
            })
            .map((dt) => dt?.year)
        );
        setChartData(
          yearlyData
            .filter((d) => {
              return Number(d.year) >= `${dayjs(new Date()).year() - 2}`;
            })
            .map((dt) => Number(dt?.churn_rate))
        );
      } else if (chartTitle === "Growth Rate") {
        setChartLabels(
          yearlyData
            .filter((d) => {
              return d.year >= `${dayjs(new Date()).year() - 2}`;
            })
            .map((dt) => dt?.year)
        );
        setChartData(
          yearlyData
            .filter((d) => {
              return d.year >= `${dayjs(new Date()).year() - 2}`;
            })
            .map((dt) => Number(dt?.practice_growth_rate))
        );
      }
    } else if (filterBy === "yearly") {
      if (chartTitle === "EBITDA") {
        setChartLabels(
          yearlyData.length > 0 ? yearlyData.map((dta) => dta?.year) : []
        );
        setChartData(
          yearlyData.length > 0 ? yearlyData.map((dta) => dta?.ebidta) : []
        );
      } else if (chartTitle === "Revenue") {
        setChartLabels(
          yearlyData.length > 0 ? yearlyData.map((dta) => dta?.year) : []
        );
        setChartData(
          yearlyData.length > 0
            ? yearlyData.map((dta) => dta?.total_income)
            : []
        );
      } else if (chartTitle === "Expense") {
        setChartLabels(
          yearlyData.length > 0 ? yearlyData.map((dta) => dta?.year) : []
        );
        setChartData(
          yearlyData.length > 0
            ? yearlyData.map((dta) => dta?.total_expenses)
            : []
        );
      } else if (chartTitle === "Churn Rate") {
        setChartLabels(
          yearlyData.length > 0
            ? yearlyData.filter((dt) => dt?.year > 2010).map((dta) => dta?.year)
            : []
        );
        setChartData(
          yearlyData.length > 0
            ? yearlyData
                .filter((dt) => dt?.year > 2010)
                .map((dta) => Math.round(Number(dta?.churn_rate)))
            : []
        );
      } else if (chartTitle === "Growth Rate") {
        setChartLabels(
          yearlyData.length > 0
            ? yearlyData
                .filter((dt) => Number(dt?.year) > 2010)
                .map((dta) => dta?.year)
            : []
        );
        setChartData(
          yearlyData.length > 0
            ? yearlyData
                .filter((dt) => Number(dt?.year) > 2010)
                .map((dta) => Number(dta?.practice_growth_rate))
            : []
        );
      } else if (chartTitle === "Revenue By Provider") {
        setChartLabels(
          yearlyData.length > 0
            ? yearlyData
                .filter(
                  (dt) =>
                    Number(dt?.year) > 2010 && dt?.Name === filterByProvider
                )
                .map((dta) => dta?.year)
            : []
        );
        setChartData(
          yearlyData.length > 0
            ? yearlyData
                .filter(
                  (dt) =>
                    Number(dt?.year) > 2010 && dt?.Name === filterByProvider
                )
                .map((dta) => Number(dta?.production))
            : []
        );
      }
    } else if (filterBy === "monthly") {
      if (chartTitle === "Churn Rate") {
        let months = Utils.getMonthNamesFromDateRange(
          dayjs(fromDate).format("MM-DD-YYYY"),
          dayjs(toDate).format("MM-DD-YYYY")
        );
        setChartLabels(months);
        let fromDt = dayjs(fromDate);
        let toDt = dayjs(toDate);
        let preparedFromDate = dayjs(
          `${fromDt.$y}-${fromDt.$M - 0}-${fromDt.$D}`
        );
        let preparedToDate = dayjs(`${toDt.$y}-${toDt.$M}-${toDt.$D}`).endOf(
          "month"
        );
        let preparedFromDateComp = dayjs(
          `${fromDt.$y - 1}-${fromDt.$M + 1}-${fromDt.$D}`
        );
        let preparedToDateComp = dayjs(
          `${toDt.$y - 1}-${toDt.$M + 1}-${toDt.$D}`
        ).endOf("month");
        let monthsComp = Utils.getMonthNamesFromDateRange(
          dayjs(preparedFromDateComp).format("MM-DD-YYYY"),
          dayjs(preparedToDateComp).format("MM-DD-YYYY")
        );
        setChartLabelsComp(monthsComp);
        // setChartLabels(monthlyData.length > 0 ? monthlyData.map((dta)=> dta?.month) : [])
        // setChartData(monthlyData.length > 0 ? monthlyData.map((dta)=> Number(dta?.churn_rate)) : [])
        setChartData(
          monthlyData.length > 0
            ? monthlyData
                .filter(
                  (dta) =>
                    dayjs(dta?.month?.value) >= preparedFromDate &&
                    dayjs(dta?.month?.value) <= preparedToDate
                )
                .map((filteredData) => parseInt(filteredData?.churn_rate))
            : []
        );
        setChartDataComp(
          monthlyData.length > 0
            ? monthlyData
                .filter(
                  (dta) =>
                    dayjs(dta?.month?.value) >= preparedFromDateComp &&
                    dayjs(dta?.month?.value) <= preparedToDateComp
                )
                .map((filteredData) => parseInt(filteredData?.churn_rate))
            : []
        );
      } else if (chartTitle === "Growth Rate") {
        let months = Utils.getMonthNamesFromDateRange(
          dayjs(fromDate).format("MM-DD-YYYY"),
          dayjs(toDate).format("MM-DD-YYYY")
        );
        setChartLabels(months);
        let fromDt = dayjs(fromDate);
        let toDt = dayjs(toDate);
        let preparedFromDate = dayjs(
          `${fromDt.$y}-${fromDt.$M - 0}-${fromDt.$D}`
        );
        let preparedToDate = dayjs(`${toDt.$y}-${toDt.$M}-${toDt.$D}`).endOf(
          "month"
        );
        let preparedFromDateComp = dayjs(
          `${fromDt.$y - 1}-${fromDt.$M + 1}-${fromDt.$D}`
        );
        let preparedToDateComp = dayjs(
          `${toDt.$y - 1}-${toDt.$M + 1}-${toDt.$D}`
        ).endOf("month");
        let monthsComp = Utils.getMonthNamesFromDateRange(
          dayjs(preparedFromDateComp).format("MM-DD-YYYY"),
          dayjs(preparedToDateComp).format("MM-DD-YYYY")
        );
        setChartLabelsComp(monthsComp);
        // setChartLabels(monthlyData.length > 0 ? monthlyData.map((dta)=> dta?.year) : [])
        // setChartData(monthlyData.length > 0 ? monthlyData.map((dta)=> Number(dta?.practice_growth_rate)) : [])
        setChartData(
          monthlyData.length > 0
            ? monthlyData
                .filter(
                  (dta) =>
                    dayjs(dta?.month?.value) >= preparedFromDate &&
                    dayjs(dta?.month?.value) <= preparedToDate
                )
                .map((filteredData) =>
                  parseInt(filteredData?.practice_growth_rate)
                )
            : []
        );
        setChartDataComp(
          monthlyData.length > 0
            ? monthlyData
                .filter(
                  (dta) =>
                    dayjs(dta?.month?.value) >= preparedFromDateComp &&
                    dayjs(dta?.month?.value) <= preparedToDateComp
                )
                .map((filteredData) =>
                  parseInt(filteredData?.practice_growth_rate)
                )
            : []
        );
      } else if (chartTitle === "Revenue By Provider") {
        let months = Utils.getMonthNamesFromDateRange(
          dayjs(fromDate).format("MM-DD-YYYY"),
          dayjs(toDate).format("MM-DD-YYYY")
        );
        setChartLabels(months);
        let fromDt = dayjs(fromDate);
        let toDt = dayjs(toDate);
        let preparedFromDate = dayjs(
          `${fromDt.$y}-${fromDt.$M - 0}-${fromDt.$D}`
        );
        let preparedToDate = dayjs(`${toDt.$y}-${toDt.$M}-${toDt.$D}`).endOf(
          "month"
        );
        let preparedFromDateComp = dayjs(
          `${fromDt.$y - 1}-${fromDt.$M + 1}-${fromDt.$D}`
        );
        let preparedToDateComp = dayjs(
          `${toDt.$y - 1}-${toDt.$M + 1}-${toDt.$D}`
        ).endOf("month");
        let monthsComp = Utils.getMonthNamesFromDateRange(
          dayjs(preparedFromDateComp).format("MM-DD-YYYY"),
          dayjs(preparedToDateComp).format("MM-DD-YYYY")
        );
        setChartLabelsComp(monthsComp);
        // setChartLabels(monthlyData.length > 0 ? monthlyData.map((dta)=> dta?.year) : [])
        // setChartData(monthlyData.length > 0 ? monthlyData.map((dta)=> Number(dta?.practice_growth_rate)) : [])
        setChartData(
          monthlyData.length > 0
            ? monthlyData
                .filter(
                  (dta) =>
                    dayjs(dta?.month?.value) >= preparedFromDate &&
                    dayjs(dta?.month?.value) <= preparedToDate &&
                    dta?.Name === filterByProvider
                )
                .map((filteredData) => parseInt(filteredData?.production))
            : []
        );
        setChartDataComp(
          monthlyData.length > 0
            ? monthlyData
                .filter(
                  (dta) =>
                    dayjs(dta?.month?.value) >= preparedFromDateComp &&
                    dayjs(dta?.month?.value) <= preparedToDateComp &&
                    dta?.Name === filterByProvider
                )
                .map((filteredData) => parseInt(filteredData?.production))
            : []
        );
      }
    }
  }, [filterBy, fromDate, toDate, filterByProvider]);

  const series = [
    {
      name: `${chartLabels[0]} - ${chartLabels[chartData.length - 1]}`,
      data: chartData,
    },
    {
      name: `${chartLabelsComp[0]} - ${
        chartLabelsComp[chartDataComp.length - 1]
      }`,
      data: chartDataComp,
    },
  ];
  const seriesYearly = [
    {
      name: chartTitle,
      data: chartData,
    },
  ];
  const options = {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#184332", "#ff8686"],
      dashArray: [0, 2],
    },
    colors: ["#184332", "#ff8686"],
    // labels: ["Jan", "Feb", "March", "April", "May", "June"],
    xaxis: {
      categories: chartLabels,
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    // fill: {
    //     type: "gradient",
    //     gradient: {
    //         type: 'vertical',
    //         shadeIntensity: 1,
    //         opacityFrom: 0,
    //         opacityTo: 1,
    //         colorStops: [
    //             {
    //                 offset: 0,
    //                 color: "#184332",
    //                 opacity: 1
    //             },
    //             {
    //                 offset: 99,
    //                 color: "#FFFFFF",
    //                 opacity: 1
    //             },
    //         ]
    //     }
    // },
    yaxis: [
      {
        // opposite: true
        min: Utils.getTenPerchantgeLessNumber(
          Math.min(...chartData, ...chartDataComp)
        ),
        max: Math.max(...chartData, ...chartDataComp),
        tickAmount: 6,
        labels: {
          formatter: function (val) {
            return ["Churn Rate", "Growth Rate"].includes(chartTitle)
              ? `${Utils.amountFormatter(Math.round(val))}%`
              : `$${Utils.amountFormatter(Math.round(val))}`;
          },
          style: {
            colors: "#184332",
          },
        },
        axisBorder: {
          show: true,
          color: "#184332",
        },
      },
      // {
      //   opposite: true,
      //   min: Math.min(...chartDataComp),
      //   max: Math.max(...chartDataComp),
      //   tickAmount: 6,
      //   labels: {
      //     formatter: function (val) {
      //       return ["Churn Rate", "Growth Rate"].includes(chartTitle)
      //         ? `${Utils.amountFormatter(Math.round(val))}%`
      //         : `$${Utils.amountFormatter(Math.round(val))}`;
      //     },
      //     style: {
      //       colors: "#ff8686",
      //     },
      //   },
      //   axisBorder: {
      //     show: true,
      //     color: "#ff8686",
      //   },
      // },
    ],
    legend: {
      position: "top",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    // legend: {
    //     horizontalAlign: 'center'
    // }
  };
  const optionsYearly = {
    chart: {
      type: "bar",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#184332"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    xaxis: {
      categories: chartLabels,
    },
    yaxis: {
      min: 0,
      max: Math.max(...chartData),
      tickAmount: 5,
      labels: {
        formatter: function (val, index) {
          return chartTitle === "Revenue By Provider"
            ? `$${Utils.amountFormatter(val)}`
            : `${Math.round(val)}%`;
        },
        style: {
          colors: "#184332",
        },
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#184332",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      horizontalAlign: "left",
    },
  };
  const optionsYearlyArea = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#184332", "#B68340"],
    },
    colors: ["#184332", "#B68340"],
    // labels: ["Jan", "Feb", "March", "April", "May", "June"],
    xaxis: {
      categories: chartLabels,
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0,
        opacityTo: 1,
        colorStops: [
          {
            offset: 0,
            color: "#184332",
            opacity: 1,
          },
          {
            offset: 99,
            color: "#FFFFFF",
            opacity: 1,
          },
        ],
      },
    },
    yaxis: {
      // opposite: true
      min: Math.min(...chartData),
      max: Math.max(...chartData),
      tickAmount: 6,
      labels: {
        formatter: function (val) {
          return ["Churn Rate", "Growth Rate"].includes(chartTitle)
            ? `${Utils.amountFormatter(Math.round(val))}%`
            : `$${Utils.amountFormatter(Math.round(val))}`;
        },
        style: {
          colors: "#184332",
        },
      },
      axisBorder: {
        show: true,
        color: "#184332",
      },
    },
    legend: {
      position: "top",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    // legend: {
    //     horizontalAlign: 'center'
    // }
  };

  const handleFilterBy = (event) => {
    const {
      target: { value },
    } = event;
    setFilterBy(value);
  };

  const handleClickAway = () => {
    setFromDateOpen(false);
    setToDateOpen(false);
  };

  const handleProviderChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterByProvider(value);
  };

  return (
    <div className="max-h-[80vh] overflow-y-scroll">
      <div className="flex justify-between px-4 pb-2">
        <p className="text-2xl font-bold">{chartTitle}</p>
        <CloseIcon onClick={closeModal} className="cursor-pointer" />
      </div>
      <div className="bg-white shadow-lg rounded-md px-8 mx-4">
        <div className="flex gap-4">
          <div
            className={`bg-[${descriptionLogoBg}] h-[70px] w-[70px] rounded-md flex justify-center`}
          >
            <img src="./watch-video.svg" alt="" />
          </div>
          <div className="flex flex-col justify-center">
            <p>{description}</p>
            {/*<p className="text-blue-500"><PlayCircleOutlinedIcon /> Watch Video</p>*/}
          </div>
        </div>
        <div
          className={`bg-[${descriptionLogoBg}] h-[70px] w-full rounded-md flex flex-col justify-center items-center`}
        >
          <p className="font-medium">{chartTitle}</p>
          {chartTitle !== "Revenue By Provider" && (
            <p className="font-extrabold">
              {["Churn Rate", "Growth Rate"].includes(chartTitle)
                ? `${Utils.amountFormatter(amount)}%`
                : `$${Utils.amountFormatter(amount)}`}
            </p>
          )}
        </div>
        <div className="flex justify-end gap-3">
          {["Churn Rate", "Growth Rate", "Revenue By Provider"].includes(
            chartTitle
          ) && (
            <div className="flex gap-3">
              <div className="flex flex-col justify-center items-center relative">
                <div
                  className="flex items-center gap-2"
                  onClick={() => {
                    setFromDateOpen(!fromDateOpen);
                    setToDateOpen(false);
                  }}
                >
                  <div>From</div>
                  <div className="flex gap-4 justify-center items-center bg-[#F3F4F5] border-[1px] border-[#969696] z-50 text-[#184332] rounded-full h-[40px] w-[157px]">
                    <div>{dayjs(fromDate).format("MMM, YYYY")}</div>
                    <CalendarMonthOutlinedIcon />
                  </div>
                </div>
                {fromDateOpen && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="bg-white shadow-lg rounded-2xl absolute top-12 mr-28 z-20">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateCalendar"]}>
                          <DateCalendar
                            value={fromDate}
                            onChange={(newValue) => {
                              setFromDate(newValue);
                              // setFromDateOpen(!fromDateOpen);
                            }}
                            onMonthChange={() => setFromDateOpen(!fromDateOpen)}
                            views={["month", "year"]}
                            disableFuture
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
              <div className="flex flex-col justify-center items-center relative">
                <div
                  className="flex items-center gap-2"
                  onClick={() => {
                    setToDateOpen(!toDateOpen);
                    setFromDateOpen(false);
                  }}
                >
                  <div>To</div>
                  <div className="flex gap-4 justify-center items-center text-[#184332] bg-[#F3F4F5] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px]">
                    <div>{dayjs(toDate).format("MMM, YYYY")}</div>
                    <CalendarMonthOutlinedIcon />
                  </div>
                </div>
                {toDateOpen && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="bg-white shadow-lg rounded-2xl absolute top-12 mr-28 z-20">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateCalendar"]}>
                          <DateCalendar
                            value={toDate}
                            onChange={(newValue) => {
                              setToDate(newValue);
                              // setToDateOpen(!toDateOpen);
                            }}
                            onMonthChange={() => setToDateOpen(!toDateOpen)}
                            views={["month", "year"]}
                            disableFuture
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
          )}
          {chartTitle === "Revenue By Provider" && (
            <FormControl>
              <Select
                id="providerFilterBy"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "500",
                  padding: "0 5px",
                  color: "#184332",
                  borderRadius: "30px",
                  height: "40px",
                  backgroundColor: "#F3F4F5",
                }}
                fullWidth
                value={filterByProvider}
                onChange={handleProviderChange}
                renderValue={(selected) => {
                  return (
                    <Box
                      sx={{ display: "flex", gap: 1 }}
                      className="flex items-center"
                    >
                      <SvgIcon color="primary">
                        <TuneIcon sx={{ color: "#184332" }} />
                      </SvgIcon>
                      <em>
                        {selected.length === 0
                          ? "Provider"
                          : isDemoTeam
                          ? topProvidersName
                              .find((vl) => vl.includes(selected))
                              .split("(")[0]
                          : Utils.camelCaseToTitleCase(selected)}
                      </em>
                    </Box>
                  );
                }}
                displayEmpty
              >
                <MenuItem value="" sx={{ backgroundColor: "white" }}>
                  Provider
                </MenuItem>
                <hr style={{ margin: "0 15px" }} />
                {!!topProvidersName &&
                  topProvidersName.length > 0 &&
                  topProvidersName.map((provider) => (
                    <MenuItem
                      value={isDemoTeam ? provider.split("(")[1] : provider}
                      sx={{ margin: "0 15px", borderRadius: "10px" }}
                    >
                      {isDemoTeam ? provider.split("(")[0] : provider}
                    </MenuItem>
                  ))}
                {/*<MenuItem value="dr.1" sx={{margin: "0 15px", borderRadius: "10px"}}>Dr. 1</MenuItem>*/}
                {/*<MenuItem value="dr.2" sx={{margin: "0 15px", borderRadius: "10px"}}>Dr. 2</MenuItem>*/}
                {/*<MenuItem value="hygienist1" sx={{margin: "0 15px", borderRadius: "10px"}}>Hygienist 1</MenuItem>*/}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <Select
              id="filterBy"
              sx={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "500",
                padding: "0 5px",
                color: "#184332",
                borderRadius: "30px",
                height: "40px",
                backgroundColor: "#F3F4F5",
              }}
              fullWidth
              value={filterBy}
              onChange={handleFilterBy}
              renderValue={(selected) => {
                return (
                  <Box
                    sx={{ display: "flex", gap: 1 }}
                    className="flex items-center"
                  >
                    <em>
                      {selected.length === 0
                        ? "View By"
                        : Utils.camelCaseToTitleCase(selected)}
                    </em>
                  </Box>
                );
              }}
              displayEmpty
            >
              <MenuItem value="" sx={{ backgroundColor: "white" }}>
                View By
              </MenuItem>
              <hr style={{ margin: "0 15px" }} />
              <MenuItem
                value="yearly"
                sx={{ margin: "0 15px", borderRadius: "10px" }}
              >
                Yearly
              </MenuItem>
              {["Churn Rate", "Growth Rate", "Revenue By Provider"].includes(
                chartTitle
              ) && (
                <MenuItem
                  value="monthly"
                  sx={{ margin: "0 15px", borderRadius: "10px" }}
                >
                  Monthly
                </MenuItem>
              )}
              {!["Revenue By Provider"].includes(chartTitle) && (
                <MenuItem
                  value="sameAsLastYear"
                  sx={{ margin: "0 15px", borderRadius: "10px" }}
                >
                  Same As Last Year
                </MenuItem>
              )}
              {/*<MenuItem value="sameAsLastYear" sx={{margin: "0 15px", borderRadius: "10px"}}>Same As Last Year</MenuItem>*/}
            </Select>
          </FormControl>
        </div>
        <div className="my-1 max-h-96 overflow-y-scroll">
          {/*<ReactApexChart options={filterBy === "monthly" ? options : optionsYearly} series={filterBy === "monthly" ? series : c}  height={350} />*/}
          {filterBy === "monthly" &&
            ["Churn Rate", "Growth Rate", "Revenue By Provider"].includes(
              chartTitle
            ) && (
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={305}
              />
            )}
          {filterBy === "yearly" &&
            ["Churn Rate", "Growth Rate", "Revenue By Provider"].includes(
              chartTitle
            ) && (
              <ReactApexChart
                options={optionsYearly}
                series={seriesYearly}
                type="bar"
                height={305}
              />
            )}
          {filterBy === "sameAsLastYear" &&
            ["Churn Rate", "Growth Rate"].includes(chartTitle) && (
              <ReactApexChart
                options={optionsYearly}
                series={seriesYearly}
                type="line"
                height={305}
              />
            )}
          {(filterBy === "yearly" || filterBy === "sameAsLastYear") &&
            !["Churn Rate", "Growth Rate", "Revenue By Provider"].includes(
              chartTitle
            ) && (
              <ReactApexChart
                options={optionsYearlyArea}
                series={seriesYearly}
                type="area"
                height={305}
              />
            )}
        </div>
      </div>
    </div>
  );
}
