import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ProfileSetup from "./components/profile/profile-setup/ProfileSetup";
import {SnackbarProvider} from "notistack";
import Dashboard from "./components/dashboard/Dashboard";
import {AuthProvider} from "./contexts/AuthContext";
import Authorization from "./core/Authorization";
import Recommendations from "./components/recommendations/Recommendations";
import ProfileSetupScreen from "./components/profile/profile-setup";
import DashboardV2 from "./components/dashboard/DashboardV2";
import SummaryDashboard from "./components/dashboard/SummaryDashboard";
import ActiveYourPlan from "./components/shared/active-your-plan/ActiveYourPlan";
import TrendChart from "./components/dashboard/trend-charts/TrendChart";
import Profile from "./components/profile";
import Permissions from "./components/permissions";
import { store } from './store/configureStore'
import { Provider } from 'react-redux'
import RevenueDashboard from './components/dashboard/revenue';


function App() {
  return (
      <AuthProvider>
          <BrowserRouter>
              <Authorization>
                  <SnackbarProvider
                      maxSnack={5}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}
                      autoHideDuration={3000}
                  >
                      <Provider store={store}>
                          <Routes>
                              <Route path="/" element={<Login />} />
                              <Route path="/login" element={<Login />} />
                              <Route path="/signup" element={<Signup />} />
                              <Route path="/forget-password" element={<ForgetPassword />} />
                              <Route path="/reset-password/:token" element={<ResetPassword />} />
                              <Route path="/profile-setup" element={<ProfileSetupScreen />} />
                              <Route path="/profile" element={<Profile />} />
                              <Route path="/permissions" element={<Permissions />} />
                              {/*<Route path="/dashboard" element={<Dashboard />} />*/}
                              {/*<Route path="/dashboard" element={<DashboardV2 />} />*/}
                              <Route path="/dashboard" element={<SummaryDashboard />} />
                              <Route path="/revenue-dashboard" element={<RevenueDashboard />} />
                              <Route path="/recommendations" element={<Recommendations />} />
                              <Route path="/active-your-plan" element={<ActiveYourPlan />} />
                              <Route path="/trend-chart" element={<TrendChart />} />
                          </Routes>
                      </Provider>
                  </SnackbarProvider>
              </Authorization>
          </BrowserRouter>
      </AuthProvider>
  );
}

export default App;
