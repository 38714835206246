import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Controller} from "react-hook-form";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";

export default function InputFieldWithLabel({label, placeholder, control, errors, controllerName, required = true, type = "text"}) {

    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword((prevState)=> !prevState)
    };

    return (
        <>
            <p className="input-field-label-text">{label}</p>
            <Controller
                name={controllerName}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        className="mx-10 textfield"
                        onWheel={event => { event.target.blur()}}
                        error={!!errors[controllerName]}
                        helperText={errors[controllerName]?.message ? errors[controllerName]?.message : ""}
                        InputProps={controllerName === "password" || controllerName === "confirmPassword" ? {
                            sx: {borderRadius: "30px", height: "40px", backgroundColor: "#F2F2F2"},
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        } : {
                            sx: {borderRadius: "30px", height: "40px", backgroundColor: "#F2F2F2"},
                        }}
                        variant="outlined"
                        required
                        fullWidth
                        placeholder={placeholder}
                        type={(controllerName === "password" || controllerName === "newPassword" || controllerName === "confirmPassword") && !showPassword ? "password" : type}
                    />
                )}
            />
        </>
    )
}