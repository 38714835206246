import NavAndTopBar from "../shared/layouts/NavAndTopBar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  Box,
  ClickAwayListener,
  Fade,
  FormControl,
  Hidden,
  MenuItem,
  Modal,
  Select,
  styled,
  SvgIcon,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateUpdateModal from "./CreateUpdateModal";
import Backdrop from "@mui/material/Backdrop";
import RecommendationsTable from "./RecommendationsTable";
import { useGetRecommendationsListQuery } from "../../store/apiSlice";
import ArchiveIcon from "@mui/icons-material/Archive";
import RecommendIcon from '@mui/icons-material/Recommend';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #184332",
  boxShadow: 40,
  p: 4,
};

export default function Recommendations() {
  const [expanded, setExpanded] = useState(false);
  const [openCreateUpdateModal, setOpenCreateUpdateModal] = useState(false);
  const {
    data: recommendationsList,
    error: recommendationsListError,
    isFetching: isRecommendationsListIF,
  } = useGetRecommendationsListQuery();
  const [isArchive, setIsArchive] = useState(false);

  let isExpanded = document.getElementsByClassName("MuiAccordionSummary-root");
  let h = isExpanded.classList;

  const handleCreateUpdateModalClose = () => {
    setOpenCreateUpdateModal(false);
  };

  return (
    <NavAndTopBar>
      <div className="px-7 py-6">
        <div className="flex items-center gap-4">
          <p className="font-bold text-2xl">Recommendations 👋</p>
        </div>
        {/* <div className="mt-12">
                    <div className="mb-8">
                        <p className="font-bold text-2xl">Recommendations</p>
                    </div>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color: `${expanded ? "white" : "#4F4F4F"}`}}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={()=> setExpanded(!expanded)}
                        >
                            <div>
                                <div>
                                    <p className="accordion-title">Recommendation of the Day 🚀</p>
                                    {
                                        !expanded && (
                                            <div className="flex gap-1">
                                                <p className="accordion-subtitle">Drive sales with an informative blog. Businesses with blogs generate 67% more leads.</p>
                                                <p className="accordion-title">Read More</p>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    !expanded && (
                                        <div className="flex">
                                            <div className="flex justify-center mt-1 h-[7px] w-[7px]">
                                                <img src="./timer-icon.png" alt=""/>
                                            </div>
                                            <p className="accordion-unlock-title">Unlock your next in: <span className="accordion-unlock-time">46:42:30</span></p>
                                        </div>
                                    )
                                }
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="accordion-subtitle">Drive sales with an informative blog. Businesses with blogs generate 67% more leads. </p>
                            <p className="accordion-subtitle">
                                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia conNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur maNemo enim ipsam
                            </p>
                        </AccordionDetails>
                    </Accordion>
                </div> */}
        <div className="flex gap-2 justify-end">
          {/* <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setOpenCreateUpdateModal(true)}
          >
            Create
          </Button> */}
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#184332",
              height: "40px",
              width: isArchive ? "170px" : "130px",
              borderRadius: "30px",
            }}
            startIcon={isArchive ? <RecommendIcon className="text-[#B68340]" /> : <ArchiveIcon  className="text-[#B68340]"/>}
            onClick={() => setIsArchive((prevState)=> !prevState)}
          >
            <p
              className="button-linear-text"
              style={{ textTransform: "capitalize" }}
            >
              {isArchive ? "Recommend List" : "Archive List"}
            </p>
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#184332",
              height: "40px",
              width: "100px",
              borderRadius: "30px",
            }}
            startIcon={<AddCircleOutlineIcon className="text-[#B68340]" />}
            onClick={() => setOpenCreateUpdateModal(true)}
          >
            <p
              className="button-linear-text"
              style={{ textTransform: "capitalize" }}
            >
              Create
            </p>
          </Button>
        </div>
        <div>
          {!isArchive && (
            <div className="py-4">
              {!!recommendationsList && recommendationsList.data.length > 0 && (
                <RecommendationsTable
                  rows={recommendationsList.data.filter(
                    (dta) => dta.perchantagedone < 100
                  )}
                />
              )}
            </div>
          )}
          {isArchive && (
            <div className="py-4">
              {!!recommendationsList && recommendationsList.data.length > 0 && (
                <RecommendationsTable
                  rows={recommendationsList.data.filter(
                    (dta) => dta.perchantagedone >= 100
                  )}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCreateUpdateModal}
        onClose={handleCreateUpdateModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCreateUpdateModal}>
          <Box sx={style}>
            <CreateUpdateModal
              actionType={"create"}
              params={[]}
              closeModal={handleCreateUpdateModalClose}
            />
          </Box>
        </Fade>
      </Modal>
    </NavAndTopBar>
  );
}
