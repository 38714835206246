import {
    Avatar,
    Box,
    Button, Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Link,
    Paper,
    TextField, Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {useState} from "react";
import _ from "lodash";
import InputFieldWithLabel from "../shared/input-fields/InputFieldWithLabel";
import { useNavigate } from 'react-router-dom';
import AuthServices from "../../data-access/services/authServices";
import {enqueueSnackbar} from "notistack";
import Logo from "../../assets/images/WAIG-Logo-2.png"

export default function ForgetPassword() {
    const [loading, setLoading] = useState(false)
    const [isLinkSent, setIsLinkSent] = useState(false)
    const [data, setData] = useState({
        email: "",
        password: "",
        remember: false,
        showPassword: false,
    });
    const navigate = useNavigate()

    // yup schema
    const schema = yup.object().shape({
        email: yup
            .string()
            .email("You must enter a valid email")
            .required("You must enter a email"),
    });

    // default form values
    const defaultValues = {
    };

    // form controler
    const { control, formState, handleSubmit, reset } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });
    const { isValid, dirtyFields, errors } = formState;


    const onSubmit = (values)=> {
        AuthServices.forgetPassword(values.email, "65283b24e21cf105f23ca479")
            .then((response)=> {
                // enqueueSnackbar(response?.data?.message, {variant: "success"})
                setIsLinkSent(true)
            })
            .catch((err)=> {
                enqueueSnackbar(err, {variant: "error"})
            })
    }

    const handleClickShowPassword = ()=> {

    }

    return (
        <div className="flex flex-col flex-auto items-center justify-around sm:justify-center h-auto min-w-0 md:p-32">
            <Paper className="flex w-11/12 sm:w-auto min-h-auto max-h-3xl h-661 overflow-hidden" sx={{borderRadius: "20px"}}>
                <Box className="relative hidden lg:flex flex-auto justify-center items-center pt-36 pb-36 overflow-hidden lg:min-w-[500px] max-w-[500px] h-auto w-full bg-[#184332]">
                    <div className="flex flex-col gap-[60px] justify-center items-center z-10 relative w-full">
                        <div className="flex justify-center items-center h-[190px] w-[190px] bg-[#133B2B] rounded-full">
                            {/*<div className="flex justify-center h-[95px] w-[95px]">*/}
                            {/*    <img src="./login-logo.png" alt="" />*/}
                            {/*</div>*/}
                            <div className="flex justify-center items-center rounded-full overflow-hidden h-[200px] w-[200px]">
                                <img src={Logo} alt="" />
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center max-w-[433px]">
                            <p className="heading-text-1 pb-2">
                                Welcome to WAIG
                            </p>
                            <p className="body-text text-center">
                                Welcome to World AI Group, where data-driven insights
                            </p>
                            <p className="body-text text-center">
                                meet innovation, powering your journey towards
                            </p>
                            <p className="body-text text-center">
                                optimized KPIs and business excellence.
                            </p>
                        </div>
                    </div>
                </Box>
                <div className="w-full sm:w-auto flex flex-col justify-items-center justify-center py-[104px] px-6 md:px-24 lg:min-w-[500px] max-w-[500px]">
                    {!!isLinkSent && (
                        <div className="w-auto">
                            <p className="welcome-back-text">Password Reset Link Sent</p>
                            <p className="body-text-2 pb-8">
                                A link has been sent to your email ID for resetting your password.
                            </p>
                            <div className="pt-4">
                                <Button variant="outlined" sx={{backgroundColor: "#184332", height: "40px", borderRadius: "30px"}} fullWidth onClick={()=> navigate("/login")}>
                                    <p className="button-linear-text" style={{textTransform: "capitalize"}}>Back To Login</p>
                                </Button>
                            </div>
                        </div>
                    )}
                    {!isLinkSent && (
                        <form name="forgetPasswordForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <div className="w-auto">
                                <p className="welcome-back-text">Forget Password ?</p>
                                <p className="body-text-2 pb-8">Get reset password link via your registered email.</p>
                            </div>
                            <div>
                                <InputFieldWithLabel label="E-mail" placeholder="Your Registered E-mail" control={control} errors={errors} controllerName="email" />
                            </div>
                            <div className="pt-4">
                                <Button type="submit" variant="outlined" sx={{backgroundColor: "#184332", height: "40px", borderRadius: "30px"}} fullWidth>
                                    <p className="button-linear-text" style={{textTransform: "capitalize"}}>Confirm</p>
                                </Button>
                            </div>
                        </form>
                    )}
                    {/*<div className="pt-4">*/}
                    {/*    <fieldset><legend>or sign in via other accounts</legend></fieldset>*/}
                    {/*</div>*/}
                    {/*<div className="flex justify-center gap-5 pt-3">*/}
                    {/*    <div className="OAuthIconButton flex justify-center items-center rounded-full">*/}
                    {/*        <div className="flex justify-center items-center " style={{height: "15px", width: "15px"}}>*/}
                    {/*            <img src="./icon-Google.png" alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="OAuthIconButton flex justify-center items-center rounded-full bg-black">*/}
                    {/*        <div className="flex justify-center items-center " style={{height: "15px", width: "15px"}}>*/}
                    {/*            <img src="./icon-Apple.png" alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="OAuthIconButton flex justify-center items-center rounded-full bg-[#2F80ED]">*/}
                    {/*        <div className="flex justify-center items-center p-1" style={{height: "17px", width: "17px"}}>*/}
                    {/*            <img src="./icon-Facebook.png" alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="flex justify-center pt-10">
                        <p style={{color: "#718096", fontSize: "10px", fontWeight: "600", fontFamily: "Poppins"}}>Don’t have an account? <span onClick={()=> navigate("/signup")} style={{color: "#B68340", cursor: "pointer"}}>Sign Up</span></p>
                    </div>
                </div>
            </Paper>
        </div>
    )
}