import { Button, Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import {
  useUpdateUserPermissionsMutation,
  useCreateUserWithPermissionsMutation,
} from "../../store/apiSlice";
import SelectOptionsDropdown from "../shared/dropdowns/SelectOptionsDropdown";
import InputFieldWithLabel from "../shared/input-fields/InputFieldWithLabel";

export default function PermissionModal({
  closeModal,
  accessForOptions,
  userExistingPermissions = null,
  userId = null,
  modalType,
  users,
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [updateUserPermissions] = useUpdateUserPermissionsMutation();
  const [createUserWithPermissions] = useCreateUserWithPermissionsMutation();
  const [permissions, setPermissions] = useState(accessForOptions);
  const [checkedItems, setCheckedItems] = useState({});
  const [isRegisteredUser, setIsRegisteredUser] = useState(true);

  const onSubmit = (data) => {
    let permissions = data?.options.filter((dt) => dt != false).toString();

    if (isRegisteredUser) {
      updateUserPermissions({
        permissions,
        userId: modalType === "create" ? data?.id : userId,
      })
        .then((response) => {
          // console.log("response : ", response);
        })
        .catch((e) => console.warn(e));
    } else {
      createUserWithPermissions({
        permissions,
        email: data?.email,
        fullName: data?.fullName,
        password: data?.password,
      })
        .then((response) => {
          // console.log("response : ", response);
        })
        .catch((e) => console.warn(e));
    }
  };

  return (
    <div className="h-full overflow-y-scroll">
      <div className="flex justify-end">
        <CloseIcon className="pointer-cursor" onClick={() => closeModal()} />
      </div>
      <div className="w-full">
        <div className="flex justify-center items center">
          <p className="font-bold text-xl pb-8">Manage Access</p>
        </div>
        <div className="flex justify-center items center">
          <div className="flex flex-col gap-1">
            <div className="flex justify-between items-center gap-24 border-b-2">
              <p className="font-bold">Choose</p>
              <p className="font-bold">Access</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {modalType === "create" && (
                <div className="py-2 flex justify-center">
                  <div className="flex justify-between items-center bg-white shadow-lg rounded-xl px-1 w-[160px] h-[30px]">
                    <div
                      className={`flex items-center justify-center w-[70px] h-[25px] ${
                        isRegisteredUser ? "bg-[#184332]" : "bg-white"
                      } rounded-[10px] cursor-pointer`}
                      onClick={() => setIsRegisteredUser(true)}
                    >
                      <p
                        className={`${
                          isRegisteredUser
                            ? "button-linear-text"
                            : "button-dashboard-tab-text"
                        }`}
                        style={{ textTransform: "capitalize" }}
                      >
                        Users
                      </p>
                    </div>
                    <div
                      className={`flex items-center justify-center w-[70px] h-[25px] ${
                        !isRegisteredUser ? "bg-[#184332]" : "bg-white"
                      } rounded-[10px] cursor-pointer`}
                      onClick={() => setIsRegisteredUser(false)}
                    >
                      <p
                        className={`${
                          !isRegisteredUser
                            ? "button-linear-text"
                            : "button-dashboard-tab-text"
                        }`}
                        style={{ textTransform: "capitalize" }}
                      >
                        New User
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {modalType === "create" && isRegisteredUser && (
                <div style={{ paddingBottom: "15px" }}>
                  <SelectOptionsDropdown
                    label="Select User"
                    placeholder="Choose Here"
                    control={control}
                    errors={errors}
                    controllerName="id"
                    options={users}
                  />
                </div>
              )}
              {modalType === "create" && !isRegisteredUser && (
                <div style={{ paddingBottom: "15px" }}>
                  <div style={{ paddingBottom: "5px" }}>
                    <InputFieldWithLabel
                      label="Full Name"
                      placeholder="Your Full Name"
                      control={control}
                      errors={errors}
                      controllerName="fullName"
                    />
                  </div>
                  <div style={{ paddingBottom: "5px" }}>
                    <InputFieldWithLabel
                      label="E-mail"
                      placeholder="Your E-mail"
                      control={control}
                      errors={errors}
                      controllerName="email"
                    />
                  </div>
                  <div>
                    <InputFieldWithLabel
                      label="Password"
                      placeholder="Your Password"
                      control={control}
                      errors={errors}
                      controllerName="password"
                    />
                  </div>
                </div>
              )}
              {permissions.map((option, index) => (
                <div key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={
                          userExistingPermissions
                            ? userExistingPermissions
                                .split(",")
                                .includes(option?.name)
                            : false
                        }
                        {...register(`options.${index}`)}
                      />
                    }
                    label={option.name}
                    value={option?.name}
                  />
                </div>
              ))}
              <div className="pt-4 flex justify-center items-center">
                <Button
                  type="submit"
                  variant="outlined"
                  sx={{
                    backgroundColor: "#184332",
                    height: "40px",
                    borderRadius: "10px",
                    paddingX: "50px",
                  }}
                  onClick={() => closeModal(false)}
                >
                  <p
                    className="button-linear-text"
                    style={{ textTransform: "capitalize" }}
                  >
                    Save
                  </p>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
