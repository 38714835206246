import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useDeleteRecommendationMutation } from "../../store/apiSlice";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import CreateUpdateModal from "./CreateUpdateModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #184332",
  boxShadow: 40,
  p: 4,
};

export default function RecommendationsTable({ rows }) {
  const [deleteRecommendation] = useDeleteRecommendationMutation();
  const [openCreateUpdateModal, setOpenCreateUpdateModal] = useState(false);
  const [createUpdateModalData, setCreateUpdateModalData] = useState([]);
  const handleCreateUpdateModalClose = () => {
    setOpenCreateUpdateModal(false);
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="bg-[#E4FFF4]">
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="left"
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="left"
              >
                Priority
              </TableCell>
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="left"
              >
                StartDate
              </TableCell>
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="left"
              >
                EndDate
              </TableCell>
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="left"
              >
                Duration
              </TableCell>
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="left"
              >
                TaskName
              </TableCell>
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="left"
              >
                Assignee
              </TableCell>
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="left"
              >
                Description
              </TableCell>
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="left"
              >
                %Done
              </TableCell>
              <TableCell
                sx={{
                  color: "#184332",
                  fontWeight: "550",
                  fontSize: "14px",
                }}
                align="right"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.uuid}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.priority}</TableCell>
                <TableCell align="left">{row.startdate}</TableCell>
                <TableCell align="left">{row.enddate}</TableCell>
                <TableCell align="left">{row.duration}</TableCell>
                <TableCell scope="row" align="left">
                  {row.taskname}
                </TableCell>
                <TableCell align="right">{row.assignee}</TableCell>
                <TableCell scope="row" align="left">
                  {row.description}
                </TableCell>
                <TableCell align="left">{row.perchantagedone}</TableCell>
                <TableCell align="right">
                  <div className="flex justify-end gap-2">
                    <ModeEditOutlineOutlinedIcon
                      onClick={() => {
                        setCreateUpdateModalData(row);
                        setOpenCreateUpdateModal(true);
                      }}
                      className="cursor-pointer text-green-600"
                    />
                    <DeleteOutlinedIcon
                      onClick={() => deleteRecommendation(row.uuid)}
                      className="cursor-pointer text-red-700"
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCreateUpdateModal}
        onClose={handleCreateUpdateModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCreateUpdateModal}>
          <Box sx={style}>
            <CreateUpdateModal
              actionType={"update"}
              params={createUpdateModalData}
              closeModal={handleCreateUpdateModalClose}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
