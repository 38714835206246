import {
    Box,
    Button, LinearProgress,
    Paper
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {useState} from "react";
import InputFieldWithLabel from "../shared/input-fields/InputFieldWithLabel";
import { useNavigate } from 'react-router-dom';
import SelectOptionsDropdown from "../shared/dropdowns/SelectOptionsDropdown";
import AuthService from "../../data-access/services/authServices/AuthService";
import {enqueueSnackbar} from "notistack";
import NavAndTopBar from "../shared/layouts/NavAndTopBar";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const ageOfPractice = [
    {
        key : "lessThanOne",
        title : "Less Than One"
    },
    {
        key : "one",
        title : "One"
    },
    {
        key : "two",
        title : "Two"
    },
    {
        key : "three",
        title : "Three"
    },
    {
        key : "four",
        title : "Four"
    },
    {
        key : "five",
        title : "Five"
    },
    {
        key : "six",
        title : "Six"
    },
    {
        key : "moreThanSix",
        title : "More Than Six"
    },
    {
        key : "notSure",
        title : "Not Sure"
    },
]
const numOfOperations = [
    {
        key : "zeroToFive",
        title : "0-5"
    },
    {
        key : "tenToFifteen",
        title : "10-15"
    },
    {
        key : "twentyToTwentyFive",
        title : "20-25"
    },
    {
        key : "twentyFiveToThirty",
        title : "25-30"
    },
    {
        key : "notSure",
        title : "Not Sure"
    },
]
const numOfActivePatients = [
    {
        key : "zeroToTwenty",
        title : "0-20"
    },
    {
        key : "twentyOneToFifty",
        title : "21-50"
    },
    {
        key : "fiftyOneToOneHundred",
        title : "51-100"
    },
    {
        key : "oneHundredOneToTwoHundred",
        title : "101-200"
    },
    {
        key : "moreThanTwoHundred",
        title : "200+"
    },
    {
        key : "notSure",
        title : "Not Sure"
    },
]

export default function Profile({setIsSetupDoneFirstScreen, userId}) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        email: "",
        password: "",
        remember: false,
        showPassword: false,
    });
    const navigate = useNavigate()
    const [profileStrength, setProfileStrength] = useState("Intermediate")
    const [progress, setProgress] = useState(40)

    // yup schema
    const schema = yup.object().shape({
        // fullName: yup
        //     .string()
        //     .required("You must enter your full name"),
        role: yup.string().required("You must select your role"),
        countryOrProvince: yup.string().required("You must select your country/province"),
        dentistryType: yup.string().required("You must select your dentistry type"),
    });

    // default form values
    const defaultValues = {
        // fullName: "",
        role: "",
        countryOrProvince: "",
        dentistryType: "",
    };

    // form controler
    const { control, formState, handleSubmit, reset } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });
    const { isValid, dirtyFields, errors } = formState;


    const onSubmit = (values)=> {
        AuthService.profileSetupScreenOne({...values, id : userId})
            .then((response)=> {
                enqueueSnackbar(response?.data?.message, {variant: "success"})
                // navigate("/dashboard")
                setIsSetupDoneFirstScreen(true)
            })
            .catch((err)=> {
                enqueueSnackbar(err, {variant: "error"})
            })
        setIsSetupDoneFirstScreen(true)
    }

    const roles = [
        {
            key : "dentist",
            title : "Dentist"
        },
        {
            key : "manager",
            title : "Manager"
        },
        {
            key : "receptionist",
            title : "Receptionist"
        },
        {
            key : "other",
            title : "Other"
        },
        {
            key : "notSure",
            title : "Not Sure"
        },
    ]

    const dentistryType = [
        {
            key : "generalDentist",
            title : "General Dentist"
        },
        {
            key : "pediatric",
            title : "Pedodontist or Pediatric Dentis"
        },
        {
            key : "orthodontist",
            title : "Orthodontistt"
        },
        {
            key : "periodontist",
            title : "Periodontist or Gum Specialist"
        },
        {
            key : "endodontist",
            title : "Endodontist or Root Canal Specialist"
        },
        {
            key : "oralpathologist",
            title : "Oral Pathologist or Oral Surgeon"
        },
        {
            key : "prosthodontist",
            title : "Prosthodontist"
        },
        {
            key : "notSure",
            title : "Not Sure"
        },
    ]

    const profileSetupSkip = (stage)=> {
        AuthService.profileSetupSkip(stage, userId)
            .then((response)=> {
                enqueueSnackbar(response?.data?.message, {variant: "success"})
                setIsSetupDoneFirstScreen(true)
            })
            .catch((err)=> {
                enqueueSnackbar(err, {variant: "error"})
            })
    }

    return (
        <NavAndTopBar>
            <p className="font-bold text-2xl px-6 pt-6">
                Profile
            </p>
            <div className="flex flex-col flex-auto  sm:justify-center h-auto min-w-0 md:p-6 w-full">
                <Paper className="flex justify-center md:min-w-[1020px] sm:w-auto min-h-auto max-h-3xl h-661 overflow-hidden relative" sx={{borderRadius: "20px"}}>
                    <div className="w-full h-auto">
                        <div className="flex justify-between px-6">
                            <div className="h-[80px] w-[80px] rounded-full z-50">
                                <div className="flex justify-center items-center h-full w-full" >
                                    <img src="./WAIG-Logo.png" height={60} width={60} alt=""/>
                                </div>
                            </div>
                            <div className="flex justify-center items-center">
                                <Button sx={{backgroundColor: "#184332", color: "white", borderRadius: "10px", paddingX: "15px"}} endIcon={<DriveFileRenameOutlineIcon />}>Edit</Button>
                            </div>
                        </div>
                        <div className="py-2 px-6">
                            <div className="flex gap-2 py-1">
                                <p>Profile Strength : </p>
                                <p className="font-bold">{profileStrength}</p>
                            </div>
                            <LinearProgress variant="determinate" value={progress} sx={{color: "red"}}/>
                        </div>
                        <div className="px-6">
                            <form name="loginForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex gap-4">
                                    <div className="w-full">
                                        <div style={{paddingBottom: "15px"}}>
                                            <InputFieldWithLabel label="Full Name" placeholder="Your E-mail" control={control} errors={errors} controllerName="email" />
                                        </div>
                                        <div style={{paddingBottom: "15px"}}>
                                            <InputFieldWithLabel label="Phone" placeholder="Your E-mail" control={control} errors={errors} controllerName="email" />
                                        </div>
                                        <div style={{paddingBottom: "15px"}}>
                                            <InputFieldWithLabel label="Bio" placeholder="Your E-mail" control={control} errors={errors} controllerName="email" />
                                        </div>
                                        <div style={{paddingBottom: "5px"}}>
                                            <SelectOptionsDropdown label="Age of Practice" placeholder="Choose Here" control={control} errors={errors} controllerName="ageOfPractice" options={ageOfPractice}/>
                                        </div>
                                        <div className="py-4">
                                            <Button type="submit" variant="outlined" sx={{backgroundColor: "#184332", color: "white", borderRadius: "10px"}}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div style={{paddingBottom: "15px"}}>
                                            <InputFieldWithLabel label="Email" placeholder="Your E-mail" control={control} errors={errors} controllerName="email" />
                                        </div>
                                        <div style={{paddingBottom: "15px"}}>
                                            <InputFieldWithLabel label="Your Role" placeholder="Your E-mail" control={control} errors={errors} controllerName="email" />
                                        </div>
                                        <div style={{paddingBottom: "5px"}}>
                                            <SelectOptionsDropdown label="Number of Operations" placeholder="Choose Here" control={control} errors={errors} controllerName="numOfOperations" options={numOfOperations}/>
                                        </div>
                                        <div style={{paddingBottom: "5px"}}>
                                            <SelectOptionsDropdown label="No of Active Patients" placeholder="Choose Here" control={control} errors={errors} controllerName="numOfActivePatients" options={numOfActivePatients}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Paper>
            </div>
        </NavAndTopBar>
    )
}