import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import InputFieldWithLabel from "../shared/input-fields/InputFieldWithLabel";
import { Button, ClickAwayListener } from "@mui/material";
import SelectOptionsDropdown from "../shared/dropdowns/SelectOptionsDropdown";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  useCreateRecommendationMutation,
  useUpdateRecommendationMutation,
} from "../../store/apiSlice";

dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.extend(isSameOrAfter);
// dayjs.extend(isSameOrBefore);

const statusOptions = [
  {
    key: "Backlog",
    title: "Backlog",
  },
  {
    key: "Not Started",
    title: "Not Started",
  },
  {
    key: "Pending",
    title: "Pending",
  },
  {
    key: "In Progres",
    title: "In Progres",
  },
  {
    key: "Completed",
    title: "Completed",
  },
];
const priorityOptions = [
  {
    key: "Heighest",
    title: "Heighest",
  },
  {
    key: "High",
    title: "High",
  },
  {
    key: "Medium",
    title: "Medium",
  },
  {
    key: "Low",
    title: "Low",
  },
  {
    key: "Lowest",
    title: "Lowest",
  },
];

export default function CreateUpdateModal({ closeModal, actionType, params }) {
  const [fromDateOpen, setFromDateOpen] = useState(false);
  const [toDateOpen, setToDateOpen] = useState(false);
  const [fromDate, setFromDate] = useState(
    dayjs(
      actionType === "create"
        ? `${new Date().getFullYear() - 1}-01-01`
        : `${params?.startdate}`
    )
  );
  // const [toDate, setToDate] = useState(dayjs(new Date()));
  const [toDate, setToDate] = useState(
    dayjs(actionType === "create" ? `${new Date()}` : `${params?.enddate}`)
  );
  const [createRecommendation] = useCreateRecommendationMutation();
  const [updateRecommendation] = useUpdateRecommendationMutation();

  const schema = yup.object().shape({
    status: yup.string().required("Please enter your status."),
    priority: yup.string().required("Please enter your priority."),
    perchantageDone: yup
      .number()
      .required("Please enter your perchantage done."),
    taskName: yup.string().required("Please enter your task name."),
    assignee: yup.string().required("Please enter your assignee."),
    duration: yup.string().required("Please enter your duration."),
    description: yup.string().required("Please enter your description."),
  });

  // default form values
  const defaultValues = {
    status: "",
    priority: "",
    perchantageDone: "",
    taskName: "",
    assignee: "",
    duration: "",
    description: "",
  };

  // form controler
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = (values) => {
    let fromDt = dayjs(fromDate);
    let toDt = dayjs(toDate);
    let preparedFromDate = `${fromDt.$y}-${fromDt.$M + 1}-${fromDt.$D}`;
    let preparedToDate = `${toDt.$y}-${toDt.$M + 1}-${toDt.$D}`;
    let preparedParams = {
      ...values,
      startDate: preparedFromDate,
      endDate: preparedToDate,
      uuid: actionType === "update" ? params?.uuid : null,
    };

    if (actionType === "create") {
      createRecommendation(preparedParams)
        .then((response) => {
          closeModal();
        })
        .catch((e) => {
          console.warn("error : ", e);
        });
    } else if (actionType === "update") {
      updateRecommendation(preparedParams)
        .then((response) => {
          closeModal();
        })
        .catch((e) => {
          console.warn("error : ", e);
        });
    }
  };

  const handleClickAway = () => {
    setFromDateOpen(false);
    setToDateOpen(false);
  };

  useEffect(() => {
    if (actionType === "update") {
      defaultValues.status = params?.status || "";
      defaultValues.priority = params?.priority || "";
      defaultValues.duration = params?.duration || "";
      defaultValues.taskName = params?.taskname || "";
      defaultValues.assignee = params?.assignee || "";
      defaultValues.description = params?.description || "";
      defaultValues.perchantageDone = params?.perchantagedone || "";
      reset({ ...defaultValues });
    }
  }, []);

  return (
    <div className="max-h-[80vh] overflow-y-scroll">
      <div className="flex justify-end">
        <CloseIcon onClick={closeModal} className="cursor-pointer" />
      </div>
      <div>
        <p className="font-bold text-2xl text-[#B68340]">
          Create Recommandations!
        </p>
      </div>
      <div>
        <form name="loginForm" noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-center gap-3">
            <div className="flex flex-col justify-center items-center relative">
              <div className="flex items-center gap-2">
                <div>Start Date</div>
                <div
                  className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px] bg-white"
                  onClick={() => {
                    setFromDateOpen(!fromDateOpen);
                    setToDateOpen(false);
                  }}
                >
                  <div>{dayjs(fromDate).format("MMM, YYYY")}</div>
                  <CalendarMonthOutlinedIcon />
                </div>
              </div>
              {fromDateOpen && (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className="bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateCalendar"]}>
                        <DateCalendar
                          value={fromDate}
                          onChange={(newValue) => {
                            setFromDate(newValue);
                            // setFromDateOpen(!fromDateOpen);
                          }}
                          onMonthChange={() => setFromDateOpen(!fromDateOpen)}
                          views={["year", "month"]}
                          disableFuture
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </ClickAwayListener>
                // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
              )}
            </div>
            <div className="flex flex-col justify-center items-center relative">
              <div className="flex items-center gap-2">
                <div>End Date</div>
                <div
                  className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px] bg-white"
                  onClick={() => {
                    setToDateOpen(!toDateOpen);
                    setFromDateOpen(false);
                  }}
                >
                  <div>{dayjs(toDate).format("MMM, YYYY")}</div>
                  <CalendarMonthOutlinedIcon />
                </div>
              </div>
              {toDateOpen && (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className="bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateCalendar"]}>
                        <DateCalendar
                          value={toDate}
                          onChange={(newValue) => {
                            setToDate(newValue);
                            // setToDateOpen(!toDateOpen);
                          }}
                          onMonthChange={() => setToDateOpen(!toDateOpen)}
                          views={["month", "year"]}
                          disableFuture
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </ClickAwayListener>
                // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
              )}
            </div>
          </div>
          <div className="flex gap-4 px-6">
            <div style={{ paddingBottom: "5px" }} className="w-full">
              <SelectOptionsDropdown
                label="Status"
                placeholder="Select"
                control={control}
                errors={errors}
                controllerName="status"
                options={statusOptions}
              />
            </div>
            <div style={{ paddingBottom: "5px" }} className="w-full">
              <SelectOptionsDropdown
                label="Priority"
                placeholder="Select"
                control={control}
                errors={errors}
                controllerName="priority"
                options={priorityOptions}
              />
            </div>
          </div>
          <div className="flex gap-4 px-6">
            <div style={{ paddingBottom: "15px" }} className="w-full">
              <InputFieldWithLabel
                label="% Done"
                placeholder="% Done"
                control={control}
                errors={errors}
                controllerName="perchantageDone"
                type="number"
              />
            </div>
            <div style={{ paddingBottom: "15px" }} className="w-full">
              <InputFieldWithLabel
                label="Task Name"
                placeholder="Task Name"
                control={control}
                errors={errors}
                controllerName="taskName"
              />
            </div>
          </div>
          <div className="flex gap-4 px-6">
            <div style={{ paddingBottom: "15px" }} className="w-full">
              <InputFieldWithLabel
                label="Assignee"
                placeholder="Assignee"
                control={control}
                errors={errors}
                controllerName="assignee"
              />
            </div>
            <div style={{ paddingBottom: "15px" }} className="w-full">
              <InputFieldWithLabel
                label="Duration"
                placeholder="Duration"
                control={control}
                errors={errors}
                controllerName="duration"
              />
            </div>
          </div>
          <div className="flex">
            <div style={{ paddingBottom: "15px" }} className="w-full px-6">
              <InputFieldWithLabel
                label="Description"
                placeholder="Description"
                control={control}
                errors={errors}
                controllerName="description"
              />
            </div>
            <div></div>
          </div>
          <div className="pt-4 px-6">
            <Button
              type="submit"
              variant="outlined"
              sx={{
                backgroundColor: "#184332",
                height: "40px",
                borderRadius: "30px",
              }}
              fullWidth
            >
              <p
                className="button-linear-text"
                style={{ textTransform: "capitalize" }}
              >
                {actionType === "create" ? "Create" : "Update"}
              </p>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
