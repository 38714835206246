import { useContext, useEffect, useState } from "react";
import {
  Box,
  ClickAwayListener,
  FormControl,
  MenuItem,
  Select,
  SvgIcon,
} from "@mui/material";
import Utils from "../../../utils/Utils";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateCalendar } from "@mui/x-date-pickers";
import { AuthContext } from "../../../contexts/AuthContext";

export default function TrendChartMultipleCategory({
  trendChartData,
  closeModal,
}) {
  const {fromDate, setFromDate, toDate, setToDate} = useContext(AuthContext)
  const [filterBy, setFilterBy] = useState("monthly");
  const {
    chartTitle,
    description,
    dataTotalPatientsYearly,
    dataActivePatientsYearly,
    dataActivePatientsYearly12,
    dataNewPatientsYearly,
    dataTotalPatientsMonthly,
    dataActivePatientsMonthly,
    dataActivePatientsMonthly12,
    dataNewPatientsMonthly,
    amounts,
    descriptionLogoBg,
  } = trendChartData;
  const [chartDataTotalPatients, setChartDataTotalPatients] = useState(
    dataTotalPatientsMonthly.length > 0
      ? dataTotalPatientsMonthly.map((data) => data?.number_of_patients)
      : []
  );
  const [chartDataActivePatients, setChartDataActivePatients] = useState(
    dataActivePatientsMonthly.length > 0
      ? dataActivePatientsMonthly.map((data) => data?.APV_6_month)
      : []
  );
  const [chartDataActivePatients12, setChartDataActivePatients12] = useState(
    dataActivePatientsMonthly12.length > 0
      ? dataActivePatientsMonthly12.map((data) => data?.APV_12_month)
      : []
  );
  const [chartDataNewPatients, setChartDataNewPatients] = useState(
    dataNewPatientsMonthly.length > 0
      ? dataNewPatientsMonthly.map((data) => data?.APV_12_month)
      : []
  );
  const [chartDataActivePatientsComp, setChartDataActivePatientsComp] =
    useState(
      dataActivePatientsMonthly.length > 0
        ? dataActivePatientsMonthly.map((data) => data?.APV_6_month)
        : []
    );
  const [chartDataActivePatientsComp12, setChartDataActivePatientsComp12] =
    useState(
      dataActivePatientsMonthly12.length > 0
        ? dataActivePatientsMonthly12.map((data) => data?.APV_12_month)
        : []
    );
  const [chartDataNewPatientsComp, setChartDataNewPatientsComp] = useState(
    dataNewPatientsMonthly.length > 0
      ? dataNewPatientsMonthly.map((data) => data?.APV_12_month)
      : []
  );
  const [chartLabels, setChartLabels] = useState(
    filterBy === "yearly" && dataTotalPatientsYearly.length > 0
      ? dataTotalPatientsYearly.map((data) => data?.year)
      : []
  );
  const [chartLabelsComp, setChartLabelsComp] = useState(
    filterBy === "yearly" && dataTotalPatientsYearly.length > 0
      ? dataTotalPatientsYearly.map((data) => data?.year)
      : []
  );
  const [fromDateOpen, setFromDateOpen] = useState(false);
  const [toDateOpen, setToDateOpen] = useState(false);
  // const [fromDate, setFromDate] = useState(
  //   dayjs(`${new Date().getFullYear() - 1}-01-01`)
  // );
  // const [toDate, setToDate] = useState(dayjs(`2023-11-28 00:00:00`));

  useEffect(() => {
    if (filterBy === "sameAsLastYear") {
      if (chartTitle === "Patients") {
        setChartDataTotalPatients(
          dataTotalPatientsYearly
            .filter((d) => {
              return d.year >= `${dayjs(new Date()).year() - 2}`;
            })
            .map((dt) => dt?.number_of_patients)
        );
        setChartDataActivePatients(
          dataActivePatientsYearly
            .filter((d) => {
              return d.year >= `${dayjs(new Date()).year() - 2}`;
            })
            .map((dt) => dt?.APV)
        );
        setChartDataActivePatients12(
          dataActivePatientsYearly12
            .filter((d) => {
              return d.year >= `${dayjs(new Date()).year() - 2}`;
            })
            .map((dt) => dt?.APV)
        );
        setChartDataNewPatients(
          dataNewPatientsYearly
            .filter((d) => {
              return d.year >= `${dayjs(new Date()).year() - 2}`;
            })
            .map((dt) => dt?.new_patients)
        );
        setChartLabels(
          dataTotalPatientsYearly
            .filter((d) => {
              return d.year >= `${dayjs(new Date()).year() - 2}`;
            })
            .map((dt) => dt?.year)
        );
      }
    } else if (filterBy === "yearly") {
      if (chartTitle === "Patients") {
        setChartLabels(
          dataActivePatientsYearly.length > 0
            ? dataActivePatientsYearly.map((data) => data?.year)
            : []
        );
        setChartDataTotalPatients(
          dataTotalPatientsYearly.length > 0
            ? dataTotalPatientsYearly.map((data) =>
                Number(data?.number_of_patients)
              )
            : []
        );
        setChartDataActivePatients(
          dataActivePatientsYearly.length > 0
            ? dataActivePatientsYearly.map((data) => Number(data?.APV))
            : []
        );
        setChartDataActivePatients12(
          dataActivePatientsYearly12.length > 0
            ? dataActivePatientsYearly12.map((data) => Number(data?.APV))
            : []
        );
        setChartDataNewPatients(
          dataNewPatientsYearly.length > 0
            ? dataNewPatientsYearly.map((data) => Number(data?.new_patients))
            : []
        );
      }
    } else if (filterBy === "monthly") {
      if (chartTitle === "Patients") {
        let months = Utils.getMonthNamesFromDateRange(
          dayjs(fromDate).format("MM-DD-YYYY"),
          dayjs(toDate).format("MM-DD-YYYY")
        );
        setChartLabels(months);
        let fromDt = dayjs(fromDate);
        let toDt = dayjs(toDate);
        // let preparedFromDate = dayjs(
        //   `${fromDt.$y}-${fromDt.$M + 0}-${fromDt.$D}`
        // );
        // let preparedToDate = dayjs(`${toDt.$y}-${toDt.$M}-${toDt.$D}`).endOf(
        //   "month"
        // );
        // let preparedFromDateComp = dayjs(
        //   `${fromDt.$y - 1}-${fromDt.$M}-${fromDt.$D}`
        // );
        // let preparedToDateComp = dayjs(
        //   `${toDt.$y - 1}-${toDt.$M + 0}-${toDt.$D}`
        // ).endOf("month");
        let preparedFromDate = dayjs(fromDate).startOf("month").add(0, "month").format('YYYY-MM-DD');
        let preparedToDate = dayjs(toDate).endOf("month").add(0, "month").format('YYYY-MM-DD');
        let preparedFromDateComp = dayjs(fromDate).startOf("month").add(0, "month").subtract(1, 'year').format('YYYY-MM-DD');
        let preparedToDateComp = dayjs(toDate).endOf("month").add(0, "month").subtract(1, 'year').format('YYYY-MM-DD');
        let monthsComp = Utils.getMonthNamesFromDateRange(
          dayjs(fromDate).startOf("month").subtract(1, 'year').format('YYYY-MM-DD'),
          dayjs(toDate).endOf("month").subtract(1, 'year').format('YYYY-MM-DD')
        );
        setChartLabelsComp(monthsComp);
        let monthlyTotalPatientsData = dataTotalPatientsMonthly
          .filter(
            (dta) =>{
              return dayjs(dta?.month?.value) >= dayjs(preparedFromDate) &&
              dayjs(dta?.month?.value) <= dayjs(preparedToDate)}
          )
          .map((filteredData) => Number(filteredData?.number_of_patients));
          
        let monthlyActivePatientsData = dataActivePatientsMonthly
          .filter(
            (dta) =>
              dayjs(dta?.month?.value) >= dayjs(preparedFromDate) &&
              dayjs(dta?.month?.value) <= dayjs(preparedToDate)
          )
          .map((filteredData) => Number(filteredData?.APV_6_month));
        let monthlyActivePatientsData12 = dataActivePatientsMonthly12
          .filter(
            (dta) =>
              dayjs(dta?.month?.value) >= dayjs(preparedFromDate) &&
              dayjs(dta?.month?.value) <= dayjs(preparedToDate)
          )
          .map((filteredData) => Number(filteredData?.APV_12_month));
        let monthlyActivePatientsDataComp = dataActivePatientsMonthly
          .filter(
            (dta) =>
              dayjs(dta?.month?.value) >= dayjs(preparedFromDateComp) &&
              dayjs(dta?.month?.value) <= dayjs(preparedToDateComp)
          )
          .map((filteredData) => Number(filteredData?.APV_6_month));
        let monthlyActivePatientsDataComp12 = dataActivePatientsMonthly12
          .filter(
            (dta) =>
              dayjs(dta?.month?.value) >= dayjs(preparedFromDateComp) &&
              dayjs(dta?.month?.value) <= dayjs(preparedToDateComp)
          )
          .map((filteredData) => Number(filteredData?.APV_12_month));
        let monthlyNewPatientsData = dataNewPatientsMonthly
          .filter(
            (dta) =>
              dayjs(dta?.month?.value) >= dayjs(preparedFromDate) &&
              dayjs(dta?.month?.value) <= dayjs(preparedToDate)
          )
          .map((filteredData) => Number(filteredData?.new_patients));
        let monthlyNewPatientsDataComp = dataNewPatientsMonthly
          .filter(
            (dta) =>
              dayjs(dta?.month?.value) >= dayjs(preparedFromDateComp) &&
              dayjs(dta?.month?.value) <= dayjs(preparedToDateComp)
          )
          .map((filteredData) => Number(filteredData?.new_patients));
        setChartDataTotalPatients(
          !!monthlyTotalPatientsData ? [...monthlyTotalPatientsData] : []
        );
        setChartDataActivePatients(
          !!monthlyActivePatientsData ? [...monthlyActivePatientsData] : []
        );
        setChartDataActivePatients12(
          !!monthlyActivePatientsData12 ? [...monthlyActivePatientsData12] : []
        );
        setChartDataActivePatientsComp(
          !!monthlyActivePatientsData ? [...monthlyActivePatientsDataComp] : []
        );
        setChartDataActivePatientsComp12(
          !!monthlyActivePatientsData12
            ? [...monthlyActivePatientsDataComp12]
            : []
        );
        setChartDataNewPatients(
          !!monthlyNewPatientsData ? [...monthlyNewPatientsData] : []
        );
        setChartDataNewPatientsComp(
          !!monthlyNewPatientsData ? [...monthlyNewPatientsDataComp] : []
        );
      }
    }
  }, [filterBy, fromDate, toDate]);

  const seriesTotalPatients = [
    {
      name: "Total Patients",
      data: chartDataTotalPatients,
    },
  ];

  const seriesActivePatients = [
    {
      name: `${chartLabels[0]} - ${
        chartLabels[chartDataActivePatients.length - 1]
      }`,
      data: chartDataActivePatients,
    },
    {
      name: `${chartLabelsComp[0]} - ${
        chartLabelsComp[chartDataActivePatientsComp.length - 1]
      }`,
      data: chartDataActivePatientsComp,
    },
  ];

  const seriesActivePatients12 = [
    {
      name: `${chartLabels[0]} - ${
        chartLabels[chartDataActivePatients12.length - 1]
      }`,
      data: chartDataActivePatients12,
    },
    {
      name: `${chartLabelsComp[0]} - ${
        chartLabelsComp[chartDataActivePatientsComp12.length - 1]
      }`,
      data: chartDataActivePatientsComp12,
    },
  ];

  const seriesNewPatients = [
    {
      name: `${chartLabels[0]} - ${
        chartLabels[chartDataNewPatients.length - 1]
      }`,
      data: chartDataNewPatients,
    },
    {
      name: `${chartLabelsComp[0]} - ${
        chartLabelsComp[chartDataNewPatientsComp.length - 1]
      }`,
      data: chartDataNewPatientsComp,
    },
  ];

  const seriesActivePatientsYearly = [
    {
      name: `6M Active Patients`,
      data: chartDataActivePatients,
    },
  ];

  const seriesActivePatientsYearly12 = [
    {
      name: `12M Active Patients`,
      data: chartDataActivePatients12,
    },
  ];

  const seriesNewPatientsYearly = [
    {
      name: `New Patients`,
      data: chartDataNewPatients,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#184332", "#F2C94C", "#B68340", "#27AE60"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: chartLabels,
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    // yaxis: {
    //     // opposite: true
    //     min : 0,
    //     max: Math.max(...chartDataTotalPatients, ...chartDataActivePatients, ...chartDataNewPatients),
    //     tickAmount: 6,
    //     labels : {
    //         formatter: function (val) {
    //             return `${Utils.amountFormatter(Math.round(val))}`
    //         }
    //     }
    // },
    yaxis: [
      {
        title: {
          text: "Total Patients",
          style: {
            color: "#184332",
          },
        },
        min: 0,
        max: Math.max(...chartDataTotalPatients),
        tickAmount: 5,
        labels: {
          formatter: function (val, index) {
            return `${Utils.amountFormatter(Math.floor(val))}`;
          },
          style: {
            colors: "#184332",
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#184332",
        },
      },
      {
        opposite: true,
        title: {
          text: "6M Active Patients",
          style: {
            color: "#F2C94C",
          },
        },
        min: 0,
        max: Math.max(...chartDataActivePatients),
        tickAmount: 5,
        labels: {
          formatter: function (val, index) {
            return `${Utils.amountFormatter(Math.floor(val))}`;
          },
          style: {
            colors: "#F2C94C",
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#F2C94C",
        },
      },
      {
        opposite: true,
        title: {
          text: "New Patients",
          style: {
            color: "#27AE60",
          },
        },
        min: 0,
        max: Math.max(...chartDataNewPatients),
        tickAmount: 5,
        labels: {
          formatter: function (val, index) {
            return `${Utils.amountFormatter(Math.floor(val))}`;
          },
          style: {
            colors: "#27AE60",
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#27AE60",
        },
      },
    ],
    legend: {
      horizontalAlign: "center",
    },
  };
  const barChartOptions = {
    chart: {
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#184332", "#F2C94C", "#27AE60"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    legend: {
      position: "top",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: chartLabels,
    },
    yaxis: [
      {
        title: {
          text: "Total Patients",
          style: {
            color: "#184332",
          },
        },
        min: 0,
        max: Math.max(...chartDataTotalPatients),
        tickAmount: 5,
        labels: {
          formatter: function (val, index) {
            return `${Utils.amountFormatter(Math.floor(val))}`;
          },
          style: {
            colors: "#184332",
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#184332",
        },
      },
      {
        opposite: true,
        title: {
          text: "6M Active Patients",
          style: {
            color: "#F2C94C",
          },
        },
        min: 0,
        max: Math.max(...chartDataActivePatients),
        tickAmount: 5,
        labels: {
          formatter: function (val, index) {
            return `${Utils.amountFormatter(Math.floor(val))}`;
          },
          style: {
            colors: "#F2C94C",
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#F2C94C",
        },
      },
      {
        opposite: true,
        title: {
          text: "New Patients",
          style: {
            color: "#27AE60",
          },
        },
        min: 0,
        max: Math.max(...chartDataNewPatients),
        tickAmount: 5,
        labels: {
          formatter: function (val, index) {
            return `${Utils.amountFormatter(Math.floor(val))}`;
          },
          style: {
            colors: "#27AE60",
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#27AE60",
        },
      },
    ],
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  const optionsTotalPatient = {
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Total Patients",
      align: "center",
    },
    colors: ["#184332"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: chartLabels,
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    yaxis: {
      title: {
        text: `${chartLabels[0]} - ${
          chartLabels[chartDataNewPatients.length - 1]
        }`,
        style: {
          color: "#184332",
        },
      },
      min: Math.min(...chartDataTotalPatients),
      max: Math.max(...chartDataTotalPatients),
      tickAmount: 5,
      labels: {
        formatter: function (val, index) {
          return `${Utils.amountFormatter(Math.floor(val))}`;
        },
        style: {
          colors: "#184332",
        },
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#184332",
      },
    },
    legend: {
      horizontalAlign: "center",
    },
  };
  const optionsActivePatient = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "6M Active Patients",
      align: "center",
    },
    colors: ["#F2C94C", "#ff8686"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 2],
    },
    xaxis: {
      categories: chartLabels,
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    yaxis: [
      {
        title: {
          text: `${chartLabels[0]} - ${
            chartLabels[chartDataActivePatients.length - 1]
          }`,
          style: {
            color: "#F2C94C",
          },
        },
        min: Utils.getTenPerchantgeLessNumber(
          Math.min(...chartDataActivePatients, ...chartDataActivePatientsComp)
        ),
        max: Math.max(
          ...chartDataActivePatients,
          ...chartDataActivePatientsComp
        ),
        tickAmount: 5,
        labels: {
          formatter: function (val, index) {
            return `${Utils.amountFormatter(Math.floor(val))}`;
          },
          style: {
            colors: "#F2C94C",
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#F2C94C",
        },
      },
      // {
      //   opposite: true,
      //   title: {
      //     text: `${chartLabelsComp[0]} - ${
      //       chartLabelsComp[chartDataActivePatientsComp.length - 1]
      //     }`,
      //     style: {
      //       color: "#ff8686",
      //     },
      //   },
      //   min: Math.min(...chartDataActivePatientsComp),
      //   max: Math.max(...chartDataActivePatientsComp),
      //   tickAmount: 5,
      //   labels: {
      //     formatter: function (val, index) {
      //       return `${Utils.amountFormatter(Math.floor(val))}`;
      //     },
      //     style: {
      //       colors: "#ff8686",
      //     },
      //   },
      //   axisTicks: {
      //     show: true,
      //   },
      //   axisBorder: {
      //     show: true,
      //     color: "#ff8686",
      //   },
      // },
    ],
    legend: {
      horizontalAlign: "center",
    },
  };
  const optionsActivePatient12 = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "12M Active Patients",
      align: "center",
    },
    colors: ["#B68340", "#ff8686"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 2],
    },
    xaxis: {
      categories: chartLabels,
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    yaxis: [
      {
        title: {
          text: `${chartLabels[0]} - ${
            chartLabels[chartDataActivePatients12.length - 1]
          }`,
          style: {
            color: "#B68340",
          },
        },
        min: Utils.getTenPerchantgeLessNumber(
          Math.min(
            ...chartDataActivePatients12,
            ...chartDataActivePatientsComp12
          )
        ),
        max: Math.max(
          ...chartDataActivePatients12,
          ...chartDataActivePatientsComp12
        ),
        tickAmount: 5,
        labels: {
          formatter: function (val, index) {
            return `${Utils.amountFormatter(Math.floor(val))}`;
          },
          style: {
            colors: "#B68340",
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#B68340",
        },
      },
      // {
      //   opposite: true,
      //   title: {
      //     text: `${chartLabelsComp[0]} - ${
      //       chartLabelsComp[chartDataActivePatientsComp12.length - 1]
      //     }`,
      //     style: {
      //       color: "#ff8686",
      //     },
      //   },
      //   min: Math.min(...chartDataActivePatientsComp12),
      //   max: Math.max(...chartDataActivePatientsComp12),
      //   tickAmount: 5,
      //   labels: {
      //     formatter: function (val, index) {
      //       return `${Utils.amountFormatter(Math.floor(val))}`;
      //     },
      //     style: {
      //       colors: "#ff8686",
      //     },
      //   },
      //   axisTicks: {
      //     show: true,
      //   },
      //   axisBorder: {
      //     show: true,
      //     color: "#ff8686",
      //   },
      // },
    ],
    legend: {
      horizontalAlign: "center",
    },
  };
  const optionsNewPatient = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "New Patients",
      align: "center",
    },
    colors: ["#27AE60", "#ff8686"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 2],
    },
    xaxis: {
      categories: chartLabels,
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    yaxis: [
      {
        title: {
          text: `${chartLabels[0]} - ${
            chartLabels[chartDataNewPatients.length - 1]
          }`,
          style: {
            color: "#27AE60",
          },
        },
        min: Utils.getTenPerchantgeLessNumber(
          Math.min(...chartDataNewPatients, ...chartDataNewPatientsComp)
        ),
        max: Math.max(...chartDataNewPatients, ...chartDataNewPatientsComp),
        tickAmount: 5,
        labels: {
          formatter: function (val, index) {
            return `${Utils.amountFormatter(Math.floor(val))}`;
          },
          style: {
            colors: "#27AE60",
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#27AE60",
        },
      },
      // {
      //   opposite: true,
      //   title: {
      //     text: `${chartLabelsComp[0]} - ${
      //       chartLabelsComp[chartDataNewPatientsComp.length - 1]
      //     }`,
      //     style: {
      //       color: "#ff8686",
      //     },
      //   },
      //   min: Math.min(...chartDataNewPatientsComp),
      //   max: Math.max(...chartDataNewPatientsComp),
      //   tickAmount: 5,
      //   labels: {
      //     formatter: function (val, index) {
      //       return `${Utils.amountFormatter(Math.floor(val))}`;
      //     },
      //     style: {
      //       colors: "#ff8686",
      //     },
      //   },
      //   axisTicks: {
      //     show: true,
      //   },
      //   axisBorder: {
      //     show: true,
      //     color: "#ff8686",
      //   },
      // },
    ],
    legend: {
      horizontalAlign: "center",
    },
  };
  const barChartOptionsTotalPatients = {
    chart: {
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Total Patients",
      align: "center",
    },
    colors: ["#184332"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    legend: {
      position: "top",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: chartLabels,
    },
    yaxis: {
      title: {
        text: "Total Patients",
        style: {
          color: "#184332",
        },
      },
      min: 0,
      max: Math.max(...chartDataTotalPatients),
      tickAmount: 5,
      labels: {
        formatter: function (val, index) {
          return `${Utils.amountFormatter(Math.floor(val))}`;
        },
        style: {
          colors: "#184332",
        },
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#184332",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  const barChartOptionsActivePatients = {
    chart: {
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "6M Active Patients",
      align: "center",
    },
    colors: ["#F2C94C"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    legend: {
      position: "top",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: chartLabels,
    },
    yaxis: {
      title: {
        text: "6M Active Patients",
        style: {
          color: "#F2C94C",
        },
      },
      min: 0,
      max: Math.max(...chartDataActivePatients),
      tickAmount: 5,
      labels: {
        formatter: function (val, index) {
          return `${Utils.amountFormatter(Math.floor(val))}`;
        },
        style: {
          colors: "#F2C94C",
        },
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#F2C94C",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  const barChartOptionsActivePatients12 = {
    chart: {
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "12M Active Patients",
      align: "center",
    },
    colors: ["#B68340"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    legend: {
      position: "top",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: chartLabels,
    },
    yaxis: {
      title: {
        text: "12M Active Patients",
        style: {
          color: "#B68340",
        },
      },
      min: 0,
      max: Math.max(...chartDataActivePatients12),
      tickAmount: 5,
      labels: {
        formatter: function (val, index) {
          return `${Utils.amountFormatter(Math.floor(val))}`;
        },
        style: {
          colors: "#B68340",
        },
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#B68340",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  const barChartOptionsNewPatients = {
    chart: {
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "New Patients",
      align: "center",
    },
    colors: ["#27AE60"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    legend: {
      position: "top",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: chartLabels,
    },
    yaxis: {
      title: {
        text: "New Patients",
        style: {
          color: "#27AE60",
        },
      },
      min: 0,
      max: Math.max(...chartDataNewPatients),
      tickAmount: 5,
      labels: {
        formatter: function (val, index) {
          return `${Utils.amountFormatter(Math.floor(val))}`;
        },
        style: {
          colors: "#27AE60",
        },
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#27AE60",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  const handleFilterBy = (event) => {
    const {
      target: { value },
    } = event;
    setFilterBy(value);
  };

  const handleClickAway = () => {
    setFromDateOpen(false);
    setToDateOpen(false);
  };

  return (
    <div className="max-h-[80vh] overflow-y-scroll">
      <div className="flex justify-between px-4 pb-2">
        <p className="text-2xl font-bold">{chartTitle}</p>
        <CloseIcon onClick={closeModal} className="cursor-pointer" />
      </div>
      <div className="bg-white shadow-lg rounded-md px-8 mx-4 max-h-[99%]">
        <div className="flex gap-4">
          <div
            className={`bg-[${descriptionLogoBg}] h-[70px] w-[70px] rounded-md flex justify-center`}
          >
            <img src="./watch-video.svg" alt="" />
          </div>
          <div className="flex flex-col justify-center">
            <p className="font-medium text-[#184332]">
              {description?.totalPatients}
            </p>
            <p className="font-medium text-[#F2C94C]">
              {description?.activePatients}
            </p>
            <p className="font-medium text-[#27AE60]">
              {description?.newPatients}
            </p>
          </div>
        </div>
        <div
          className={`bg-[${descriptionLogoBg}] h-[70px] w-full rounded-md flex flex-col justify-center items-center`}
        >
          <p className="font-medium">{chartTitle} Count</p>
          <div className="flex gap-2">
            <p className="font-medium text-[#184332]">
              Total :{" "}
              <span className="font-extrabold">
                {Utils.amountFormatterWithDecimal(amounts?.totalPatients)},
              </span>
            </p>
            <p className="font-medium text-[#F2C94C]">
              6M Active :{" "}
              <span className="font-extrabold">
                {Utils.amountFormatter(amounts?.activePatients)},
              </span>
            </p>
            <p className="font-medium text-[#B68340]">
              12M Active :{" "}
              <span className="font-extrabold">
                {Utils.amountFormatter(amounts?.activePatients12)},
              </span>
            </p>
            <p className="font-medium text-[#27AE60]">
              New :{" "}
              <span className="font-extrabold">
                {Utils.amountFormatter(amounts?.newPatients)}
              </span>
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <div className="flex flex-col justify-center items-center relative">
            <div
              className="flex items-center gap-2"
              onClick={() => {
                setFromDateOpen(!fromDateOpen);
                setToDateOpen(false);
              }}
            >
              <div>From</div>
              <div className="flex gap-4 justify-center items-center bg-[#F3F4F5] border-[1px] border-[#969696] z-50 text-[#184332] rounded-full h-[40px] w-[157px]">
                <div>{dayjs(fromDate).format("MMM, YYYY")}</div>
                <CalendarMonthOutlinedIcon />
              </div>
            </div>
            {fromDateOpen && (
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className="bg-white shadow-lg rounded-2xl absolute top-12 mr-28 z-20">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateCalendar"]}>
                      <DateCalendar
                        value={fromDate}
                        onChange={(newValue) => {
                          setFromDate(newValue);
                          // setFromDateOpen(!fromDateOpen);
                        }}
                        onMonthChange={() => setFromDateOpen(!fromDateOpen)}
                        views={["month", "year"]}
                        disableFuture
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </ClickAwayListener>
            )}
          </div>
          <div className="flex flex-col justify-center items-center relative">
            <div
              className="flex items-center gap-2"
              onClick={() => {
                setToDateOpen(!toDateOpen);
                setFromDateOpen(false);
              }}
            >
              <div>To</div>
              <div className="flex gap-4 justify-center items-center text-[#184332] bg-[#F3F4F5] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px]">
                <div>{dayjs(toDate).format("MMM, YYYY")}</div>
                <CalendarMonthOutlinedIcon />
              </div>
            </div>
            {toDateOpen && (
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className="bg-white shadow-lg rounded-2xl absolute top-12 mr-28 z-20">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateCalendar"]}>
                      <DateCalendar
                        value={toDate}
                        onChange={(newValue) => {
                          setToDate(newValue);
                          // setToDateOpen(!toDateOpen);
                        }}
                        onMonthChange={() => setToDateOpen(!toDateOpen)}
                        views={["month", "year"]}
                        disableFuture
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </ClickAwayListener>
            )}
          </div>
          <FormControl>
            <Select
              id="filterBy"
              sx={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "500",
                padding: "0 5px",
                color: "#184332",
                borderRadius: "30px",
                height: "40px",
                backgroundColor: "#F3F4F5",
              }}
              fullWidth
              value={filterBy}
              onChange={handleFilterBy}
              renderValue={(selected) => {
                return (
                  <Box
                    sx={{ display: "flex", gap: 1 }}
                    className="flex items-center"
                  >
                    <em>
                      {selected.length === 0
                        ? "View By"
                        : Utils.camelCaseToTitleCase(selected)}
                    </em>
                  </Box>
                );
              }}
              displayEmpty
            >
              <MenuItem value="" sx={{ backgroundColor: "white" }}>
                View By
              </MenuItem>
              <hr style={{ margin: "0 15px" }} />
              <MenuItem
                value="yearly"
                sx={{ margin: "0 15px", borderRadius: "10px" }}
              >
                Yearly
              </MenuItem>
              <MenuItem
                value="monthly"
                sx={{ margin: "0 15px", borderRadius: "10px" }}
              >
                Monthly
              </MenuItem>
              <MenuItem
                value="sameAsLastYear"
                sx={{ margin: "0 15px", borderRadius: "10px" }}
              >
                Same As Last Year
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="my-1 max-h-96 overflow-y-scroll">
          {/*<ReactApexChart options={filterBy === "yearly" ? barChartOptions : options} series={series} type={filterBy === "yearly" ? "bar" : "area"} height={350} />*/}
          <ReactApexChart
            options={
              filterBy === "yearly"
                ? barChartOptionsTotalPatients
                : optionsTotalPatient
            }
            series={seriesTotalPatients}
            type={filterBy === "yearly" ? "bar" : "area"}
            height={300}
          />
          <ReactApexChart
            options={
              filterBy === "yearly"
                ? barChartOptionsActivePatients
                : optionsActivePatient
            }
            series={
              filterBy === "yearly"
                ? seriesActivePatientsYearly
                : seriesActivePatients
            }
            type={filterBy === "yearly" ? "bar" : "line"}
            height={300}
          />
          <ReactApexChart
            options={
              filterBy === "yearly"
                ? barChartOptionsActivePatients12
                : optionsActivePatient12
            }
            series={
              filterBy === "yearly"
                ? seriesActivePatientsYearly12
                : seriesActivePatients12
            }
            type={filterBy === "yearly" ? "bar" : "line"}
            height={300}
          />
          <ReactApexChart
            options={
              filterBy === "yearly"
                ? barChartOptionsNewPatients
                : optionsNewPatient
            }
            series={
              filterBy === "yearly"
                ? seriesNewPatientsYearly
                : seriesNewPatients
            }
            type={filterBy === "yearly" ? "bar" : "line"}
            height={300}
          />
        </div>
      </div>
    </div>
  );
}
