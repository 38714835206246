import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import AuthService from "../data-access/services/authServices/AuthService";
import { EnvVariable } from "../utils/envVariables";
import dayjs from "dayjs";

const baseQuery = fetchBaseQuery({
  baseUrl: EnvVariable.BASEURL,
  prepareHeaders: (headers, { getState }) => {
    const user = AuthService.getUserInfo();
    const access_token = user?.token_data?.access_token;
    headers.set("authorization", `Bearer ${access_token}`);
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQuery,
  tagTypes: ["RecommendationsList", "UsersList"],
  endpoints: (builder) => ({
    getIndustryAvgData: builder.query({
      query: () => `/overview/summary/industry-avg-data`,
    }),
    getNumOfTotalPatientMonth: builder.query({
      query: () => `/overview/summary/number-of-total-patients-month`,
    }),
    getNumOfTotalPatientYear: builder.query({
      query: () => `/overview/summary/number-of-total-patients-year`,
    }),
    //6M
    getNumOfActivePatient: builder.query({
      query: () => `/overview/summary/number-of-active-patient`,
    }),
    //12M
    getNumOfActivePatient12: builder.query({
      query: () => `/overview/summary/number-of-active-patient12`,
    }),
    //6M
    getNumOfActivePatientYear: builder.query({
      query: () => `/overview/summary/number-of-active-patient-year`,
    }),
    //6M
    getNumOfActivePatientYear12: builder.query({
      query: () => `/overview/summary/number-of-active-patient-year12`,
    }),
    getNumOfNewPatientsMonth: builder.query({
      query: () => `/overview/summary/number-of-new-patients-month`,
    }),
    getNumOfNewPatientsYear: builder.query({
      query: () => `/overview/summary/number-of-new-patients-year`,
    }),
    getEbidtaData: builder.query({
      query: () => `/overview/summary/ebitda-data`,
    }),
    getRevenueData: builder.query({
      query: () => `/overview/summary/revenue-data`,
    }),
    getExpenseData: builder.query({
      query: () => `/overview/summary/expense-data`,
    }),
    //MonthToSixMonthCutOff
    getChurnRateMonthly: builder.query({
      query: () => `/overview/summary/churn-rate-monthly`,
    }),
    //MonthToSixMonthCutOff
    getChurnYearlyData: builder.query({
      query: () => `/overview/summary/churn-rate-yearly`,
    }),
    getGrowthRateYearlyData: builder.query({
      query: () => `/overview/summary/growth-rate-yearly`,
    }),
    getGrowthRateYearlyMonthlyBasisData: builder.query({
      query: () => `/overview/summary/growth-rate-mb-yearly`,
    }),
    getGrowthRateMonthlyData: builder.query({
      query: () => `/overview/summary/growth-rate-monthly`,
    }),
    getAvgRevenuePerPatient: builder.query({
      query: () => `/overview/summary/avg-rev-per-patient`,
    }),
    getAvgRevenuePerPatientYearly: builder.query({
      query: () => `/overview/summary/avg-rev-per-patient-yearly`,
    }),
    getAvgRevenuePerAppointment: builder.query({
      query: () => `/overview/summary/avg-rev-per-appointment`,
    }),
    getAvgRevenuePerAppointmentYearly: builder.query({
      query: () => `/overview/summary/avg-rev-per-appointment-yearly`,
    }),
    getAvgRevenuePerNewPatient: builder.query({
      query: () => `/overview/summary/avg-rev-per-new-patient`,
    }),
    getAvgRevenuePerNewPatientYearly: builder.query({
      query: () => `/overview/summary/avg-rev-per-new-patient-yearly`,
    }),
    getTopProviders: builder.query({
      query: () => `/overview/summary/top-providers`,
    }),
    getTopProvidersYearly: builder.query({
      query: () => `/overview/summary/top-providers-yearly`,
    }),
    getServiceCategoryById: builder.query({
      query: (id) => `/overview/summary/service-by-category-id/${id}`,
    }),
    getTopLocationsByDate: builder.query({
      query: (dateParam) => {
        let date = dayjs(dateParam);
        let preparedDate = dayjs(`${date.$y}-${date.$M}-${date.$D}`)
          .endOf("month")
          .format("YYYY-MM-DD");
        return `/overview/summary/top-locations/${preparedDate}`;
      },
    }),
    getTopLocationsByDateActiveP: builder.query({
      query: (startDateParam, endDateParam) => {
        let startDate = dayjs(startDateParam);
        let endDate = dayjs(endDateParam);
        let preparedStartDate = dayjs(
          `${startDate.$y}-${startDate.$M}-${startDate.$D}`
        )
          .startOf("month")
          .format("YYYY-MM-DD");
        let preparedEndDate = dayjs(`${endDate.$y}-${endDate.$M}-${endDate.$D}`)
          .endOf("month")
          .format("YYYY-MM-DD");
        return `/overview/summary/top-locations-active-pat/${preparedStartDate}/${preparedEndDate}`;
      },
    }),
    getTopLocationsByDateNewP: builder.query({
      query: (startDateParam, endDateParam) => {
        let startDate = dayjs(startDateParam);
        let endDate = dayjs(endDateParam);
        let preparedStartDate = dayjs(
          `${startDate.$y}-${startDate.$M}-${startDate.$D}`
        )
          .startOf("month")
          .format("YYYY-MM-DD");
        let preparedEndDate = dayjs(`${endDate.$y}-${endDate.$M}-${endDate.$D}`)
          .endOf("month")
          .format("YYYY-MM-DD");
        return `/overview/summary/top-locations-new-pat/${preparedStartDate}/${preparedEndDate}`;
      },
    }),
    getPatientsCountStudent: builder.query({
      query: (id) => `/overview/summary/patient-count-student`,
    }),
    getPatientsCountRefugee: builder.query({
      query: (id) => `/overview/summary/patient-count-refugee`,
    }),
    getPatientsCountOthers: builder.query({
      query: (id) => `/overview/summary/patient-count-others`,
    }),
    getRevenueByTreatmentType: builder.query({
      query: (id) => `/overview/summary/num-of-services-by-category`,
    }),
    getChurnRateLoosedPatientsDetailsByIds: builder.query({
      query: (params) => ({
        url: `/overview/summary/loosed-patients-details`,
        method: "GET",
        body: {
          patIdsArray: params,
        },
      }),
    }),
    createRecommendation: builder.mutation({
      query: (payload) => {
        return {
          url: `/recommendations/create`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["RecommendationsList"],
    }),
    updateRecommendation: builder.mutation({
      query: (payload) => {
        return {
          url: `/recommendations/update/${payload?.uuid}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["RecommendationsList"],
    }),
    getRecommendationsList: builder.query({
      query: (params) => ({
        url: `/recommendations`,
        method: "GET",
      }),
      providesTags: ["RecommendationsList"],
    }),
    deleteRecommendation: builder.mutation({
      query: (uuid) => {
        return {
          url: `/recommendations/delete/${uuid}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["RecommendationsList"],
    }),
    getAllUsers: builder.query({
      query: () => `/user/all`,
      providesTags: ["UsersList"]
    }),
    updateUserPermissions: builder.mutation({
      query: (payload) =>{
        return {
          url: `/user/update-permissions`,
          method: "PUT",
          body: payload
        };
      },
      invalidatesTags: ["UsersList"],
    }),
    createUserWithPermissions: builder.mutation({
      query: (payload) =>{
        return {
          url: `/user/signup-with-permission`,
          method: "POST",
          body: payload
        };
      },
      invalidatesTags: ["UsersList"],
    }),
  }),
});

export const {
  useGetIndustryAvgDataQuery,
  useGetNumOfTotalPatientMonthQuery,
  useGetNumOfTotalPatientYearQuery,
  useGetNumOfActivePatientQuery,
  useGetNumOfActivePatient12Query,
  useGetNumOfActivePatientYearQuery,
  useGetNumOfActivePatientYear12Query,
  useGetNumOfNewPatientsMonthQuery,
  useGetNumOfNewPatientsYearQuery,
  useGetEbidtaDataQuery,
  useGetRevenueDataQuery,
  useGetExpenseDataQuery,
  useGetChurnRateMonthlyQuery,
  useGetChurnYearlyDataQuery,
  useGetGrowthRateYearlyDataQuery,
  useGetGrowthRateYearlyMonthlyBasisDataQuery,
  useGetGrowthRateMonthlyDataQuery,
  useGetAvgRevenuePerPatientQuery,
  useGetAvgRevenuePerPatientYearlyQuery,
  useGetAvgRevenuePerAppointmentQuery,
  useGetAvgRevenuePerAppointmentYearlyQuery,
  useGetAvgRevenuePerNewPatientQuery,
  useGetAvgRevenuePerNewPatientYearlyQuery,
  useGetTopProvidersQuery,
  useGetTopProvidersYearlyQuery,
  useGetServiceCategoryByIdQuery,
  useGetTopLocationsByDateQuery,
  useGetTopLocationsByDateActivePQuery,
  useGetTopLocationsByDateNewPQuery,
  useGetPatientsCountStudentQuery,
  useGetPatientsCountRefugeeQuery,
  useGetPatientsCountOthersQuery,
  useGetRevenueByTreatmentTypeQuery,
  useGetChurnRateLoosedPatientsDetailsByIdsQuery,
  useCreateRecommendationMutation,
  useUpdateRecommendationMutation,
  useGetRecommendationsListQuery,
  useDeleteRecommendationMutation,
  useGetAllUsersQuery,
  useUpdateUserPermissionsMutation,
  useCreateUserWithPermissionsMutation,
} = apiSlice;
