import React, {useEffect, useState} from "react";
import NavAndTopBar from "../shared/layouts/NavAndTopBar";
import ReactApexChart from "react-apexcharts";
import {
    Box, ClickAwayListener, Fade,
    FormControl,
    Hidden,
    MenuItem,
    Modal,
    Select,
    styled,
    SvgIcon,
    Tooltip,
    tooltipClasses, Typography
} from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import Utils from "../../utils/Utils";
import GaugeChart from "../charts/GaugeChart";
import RadialBarChart from "../charts/RadialBarChart";
import OverviewSummaryDashboardService
    from "../../data-access/services/dashboards/overview-summary/OverviewSummaryDashboardService";
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateCalendar} from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import {useNavigate} from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import TrendChart from "./trend-charts/TrendChart";
import TrendChartMultipleCategory from "./trend-charts/TrendChartMultipleCategory";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import AuthService from "../../data-access/services/authServices/AuthService";
import useIdleTimeout from "../../custom-hooks/useIdleTimeout";
import {useSnackbar} from "notistack";

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: "auto",
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #184332',
    boxShadow: 40,
    p: 4,
};

export default function DashboardV2() {
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()
    const [filterBy, setFilterBy] = React.useState([]);
    const [avgRevViewBy, setAvgRevViewBy] = React.useState("monthly");
    const [filterByProvider, setFilterByProvider] = React.useState([]);
    //==========REAL DATA START==========
    const [isLoading, setIsLoading] = useState(true)
    const [avgOfNewPatientsPerMonthIA, setAvgOfNewPatientsPerMonthIA] = useState(0)
    const [lifeCycleStageValue, setLifeCycleStageValue] = useState(0)
    const [avgOfExpenseIA, setAvgOffExpenseIA] = useState(0)
    const [avgOfEbitdaIA, setAvgOfEbitdaIA] = useState(0)
    const [avgOfGrossRevenueIA, setAvgOfGrossRevenueIA] = useState(0)
    const [averageRevenueMonths, setAverageRevenueMonths] = useState([])
    const [averageRevenueMonthsLY, setAverageRevenueMonthsLY] = useState([])
    const [avgOfPracticeValueIA, setAvgOfPracticeValueIA] = useState(0)
    const [isTotalPatientsLoading, setIsTotalPatientsLoading] = useState(true)
    const [isActivePatientsLoading, setIsActivePatientsLoading] = useState(true)
    const [isNewPatientsLoading, setIsNewPatientsLoading] = useState(true)
    const [totalPatients, setTotalPatients] = useState(0)
    const [activePatients, setActivePatients] = useState(0)
    const [activePatients12, setActivePatients12] = useState(0)
    const [newPatients, setNewPatients] = useState(0)
    const [trendChartTotalPatientsMonthly, setTrendChartTotalPatientsMonthly] = useState([])
    const [trendChartTotalPatientsYearly, setTrendChartTotalPatientsYearly] = useState([])
    const [trendChartActivePatientsMonthly, setTrendChartActivePatientsMonthly] = useState([])
    const [trendChartActivePatientsMonthly12, setTrendChartActivePatientsMonthly12] = useState([])
    const [trendChartActivePatientsYearly, setTrendChartActivePatientsYearly] = useState([])
    const [trendChartActivePatientsYearly12, setTrendChartActivePatientsYearly12] = useState([])
    const [trendChartNewPatientsMonthly, setTrendChartNewPatientsMonthly] = useState([])
    const [trendChartNewPatientsYearly, setTrendChartNewPatientsYearly] = useState([])
    const [trendChartGrowthRateYearly, setTrendChartGrowthRateYearly] = useState([])
    const [trendChartGrowthRateMonthly, setTrendChartGrowthRateMonthly] = useState([])
    const [trendChartChurnRateYearly, setTrendChartChurnRateYearly] = useState([])
    const [trendChartChurnRateMonthly, setTrendChartChurnRateMonthly] = useState([])
    const [trendChartEbidtaYearly, setTrendChartEbidtaYearly] = useState([])
    const [trendChartRevenueYearly, setTrendChartRevenueYearly] = useState([])
    const [trendChartExpenseYearly, setTrendChartExpenseYearly] = useState([])
    const [trendChartEbidtaMonthly, setTrendChartEbidtaMonthly] = useState([])
    const [trendChartRevByProviderMonthly, setTrendChartRevByProviderMonthly] = useState([])
    const [trendChartRevByProviderYearly, setTrendChartRevByProviderYearly] = useState([])
    const [ebidta, setEbidta] = useState(0)
    const [lastYearEbidta, setLastyearEbidta] = useState(0)
    const [revenue, setRevenue] = useState(0)
    const [lastYearRevenue, setLastYearRevenue] = useState(0)
    const [expense, setExpense] = useState(0)
    const [lastYearExpense, setLastYearExpense] = useState(0)
    const [avgRevenuePerAppointment, setAvgRevenuePerAppointment] = useState([])
    const [avgRevenuePerNewPatient, setAvgRevenuePerNewPatient] = useState([])
    const [avgRevenuePerPatient, setAvgRevenuePerPatient] = useState([])
    const [avgRevenuePerAppointmentLY, setAvgRevenuePerAppointmentLY] = useState([])
    const [avgRevenuePerNewPatientLY, setAvgRevenuePerNewPatientLY] = useState([])
    const [avgRevenuePerPatientLY, setAvgRevenuePerPatientLY] = useState([])
    const [avgRevenueYearlyLabels, setAvgRevenueYearlyLabels] = useState([])
    const [avgRevenuePerAppointmentYearly, setAvgRevenuePerAppointmentYearly] = useState([])
    const [avgRevenuePerNewPatientYearly, setAvgRevenuePerNewPatientYearly] = useState([])
    const [avgRevenuePerPatientYearly, setAvgRevenuePerPatientYearly] = useState([])
    const [topProviders, setTopProviders] = useState([0,0,0,0,0])
    const [topProvidersName, setTopProvidersName] = useState(["","","","",""])
    const [topServices, setTopServices] = useState([0,0,0,0,0])
    const [topServiceCategories, setTopServiceCategories] = useState([])
    const [topSrcSrvcServices, setTopSrcSrvcServices] = useState([0,0,0,0,0,0,0,0,0,0])
    const [revenueByTreatmentType, setRevenueByTreatmentType] = useState([0,0,0,0,0,0,0,0,0,0])
    const [serviceTypes, setServiceTypes] = useState([])
    const [topLocationSeries, setTopLocationSeries] = useState([])
    const [topLocationSeriesActiveP, setTopLocationSeriesActiveP] = useState([])
    const [topLocationSeriesNewP, setTopLocationSeriesNewP] = useState([])
    const [topLocationSeriesTooltipData, setTopLocationSeriesTooltipData] = useState([])
    const [topLocationSeriesTooltipDataActiveP, setTopLocationSeriesTooltipDataActiveP] = useState([])
    const [topLocationSeriesTooltipDataNewP, setTopLocationSeriesTooltipDataNewP] = useState([])
    const [topLocationNames, setTopLocationNames] = useState(["", "",""])
    const [topLocationPatCout, setTopLocationPatCout] = useState([0,0, 0])
    const [topServicesMaxCount, setTopServicesMaxCount] = useState([0,0,0,0,0])
    const [churnRate, setChurnRate] = useState(0)
    const [retentionRate, setRetentionRate] = useState(0)
    const [growthRate, setGrowthRate] = useState(0)
    const [patientCountStudent, setPatientCountStudent] = useState(0)
    const [patientCountRefugee, setPatientCountRefugee] = useState(0)
    const [patientCountOthers, setPatientCountOthers] = useState(0)
    const [selectedServiceType, setSelectedServiceType] = useState("")
    const [viewByPercentage, setViewByPercentage] = useState(true)
    const [patLocViewBy, setPatLocViewBy] = useState("total")
    const [showChurnRate, setShowChurnRate] = useState(true)
    const [activePatientsLost, setActivePatientsLost] = useState(0)
    //==========REAL DATA END==========
    const [fromDateOpen, setFromDateOpen] = useState(false);
    const [toDateOpen, setToDateOpen] = useState(false);
    const [fromDate, setFromDate] = useState(dayjs(`${new Date().getFullYear()-1}-01-01`));
    // const [toDate, setToDate] = useState(dayjs(new Date()));
    const [toDate, setToDate] = useState(dayjs(`2023-11-28 00:00:00`));
    // const [toDate, setToDate] = useState(dayjs("2023-11-27").endOf('month'));
    const [trendChartModalData, setTrendChartModalData] = useState({
        chartTitle: "",
        monthlyData : [],
        yearlyData : [],
        topProvidersName : [],
        amount : 0,
        description : "",
        descriptionLogoBg : "#BFD6C9",
    })
    const [trendChartMultipleCategoryModalData, setTrendChartMultipleCategoryModalData] = useState({
        chartTitle: "",
        dataTotalPatientsYearly : [],
        dataTotalPatientsMonthly : [],
        dataActivePatientsYearly : [],
        dataActivePatientsMonthly : [],
        dataActivePatientsYearly12 : [],
        dataActivePatientsMonthly12 : [],
        dataNewPatientsYearly : [],
        dataNewPatientsMonthly : [],
        amounts : {
            totalPatients: 0,
            activePatients: 0,
            activePatients12: 0,
            newPatients: 0
        },
        description : "",
        descriptionLogoBg : "#BFD6C9",
    })
    const [openTrendChartModal, setOpenTrendChartModal] = React.useState(false);
    const [openTrendChartMultipleCategoryModal, setOpenTrendChartMultipleCategoryModal] = React.useState(false);
    const handleTrendChartModalOpen = () => setOpenTrendChartModal(true);
    const handleTrendChartModalClose = () => {
        setOpenTrendChartModal(false)
        setOpenTrendChartMultipleCategoryModal(false)
    };

    const {idleTimer, isIdle} = useIdleTimeout({ idleTime: 10 })

    useEffect(()=>{
        let months = Utils.getMonthNamesFromDateRange(dayjs(fromDate).format('MM-DD-YYYY'), dayjs(toDate).format('MM-DD-YYYY'))
        setAverageRevenueMonths(months)
        let fromDt = dayjs(fromDate)
        let toDt = dayjs(toDate)
        let preparedFromDateComp = dayjs(`${fromDt.$y-1}-${fromDt.$M+1}-${fromDt.$D}`)
        let preparedToDateComp = dayjs(`${toDt.$y-1}-${toDt.$M+1}-${toDt.$D}`).endOf("month")
        let monthsComp = Utils.getMonthNamesFromDateRange(dayjs(preparedFromDateComp).format('MM-DD-YYYY'), dayjs(preparedToDateComp).format('MM-DD-YYYY'))
        setAverageRevenueMonthsLY(monthsComp)
    },[fromDate,toDate])
    useEffect(()=>{

    },[showChurnRate])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const getServiceCategoryByName = (name)=> {
        let id = serviceTypes.find((dt)=> dt?.Category_Name === name)?.Service_Category
        OverviewSummaryDashboardService.getServiceCategoryById(id)
            .then((res)=> {
                setSelectedServiceType(name || "")
                setTopServiceCategories(res?.data?.data)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
    }

    const calculateLifeCycleDataStagePercentage = (start, end, value)=> {
        if (start >= end) {
            throw new Error("End number must be greater than the start number.");
        }
        const percentage = ((value - start) / (end - start)) * 100;
        return Math.round(percentage * 100) / 100;
    }
    useEffect(()=> {
        OverviewSummaryDashboardService.getEbitdaData()
            .then((res)=> {
                let data = res?.data?.data[res?.data?.data.length -1]
                setEbidta(data?.ebidta)
                setLastyearEbidta(res?.data?.data[res?.data?.data.length -2]?.ebidta)
                setTrendChartEbidtaYearly(res?.data?.data)
                const EBITDA = data?.ebidta
                if (EBITDA > 0 && EBITDA <= 150000) {
                    setLifeCycleStageValue(Math.round(calculateLifeCycleDataStagePercentage(0, 150000, EBITDA)*20/100))
                }else if (EBITDA > 150000 && EBITDA <= 350000){
                    setLifeCycleStageValue(Math.round(calculateLifeCycleDataStagePercentage(150000, 350000, EBITDA)*20/100)+20)
                }else if (EBITDA > 350000 && EBITDA <= 550000){
                    setLifeCycleStageValue(Math.round(calculateLifeCycleDataStagePercentage(350000, 550000, EBITDA)*20/100)+40)
                }else if (EBITDA > 550000 && EBITDA <= 750000){
                    setLifeCycleStageValue(Math.round(calculateLifeCycleDataStagePercentage(550000, 750000, EBITDA)*20/100)+60)
                }else if (EBITDA > 750000){
                    setLifeCycleStageValue(Math.round(calculateLifeCycleDataStagePercentage(750000, 1500000, EBITDA)*20/100)+80)
                }
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getRevenueData()
            .then((res)=> {
                let data = res?.data?.data[res?.data?.data.length -1]
                setRevenue(data?.total_income)
                setLastYearRevenue(res?.data?.data[res?.data?.data.length -2]?.total_income)
                setTrendChartRevenueYearly(res?.data?.data)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getExpenseData()
            .then((res)=> {
                let data = res?.data?.data[res?.data?.data.length -1]
                setExpense(data?.total_expenses)
                setLastYearExpense(res?.data?.data[res?.data?.data.length -2]?.total_expenses)
                setTrendChartExpenseYearly(res?.data?.data)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        // OverviewSummaryDashboardService.getEbidtaRevenueExpenseData()
        //     .then((res)=> {
        //         setTrendChartEbidtaYearly(res?.data?.data.filter((dt)=> !dt?.year.includes(".")) || [])
        //         setIsLoading(false)
        //     })
        //     .catch((err)=> {
        //         console.warn("ERR : ",err)
        //     })
    }, [isLoading])

    useEffect(()=> {
        OverviewSummaryDashboardService.getIndustryAvgData()
            .then((res)=> {
                let avgPatients = res?.data?.data?.find((d)=> d?.Metric === "Avg of New Patients/month").Industry_Avg_Amount
                let avgExpense = res?.data?.data?.find((d)=> d?.Metric === "Expenses").Industry_Avg_Amount
                let avgEbitda = res?.data?.data?.find((d)=> d?.Metric === "EBITDA").Industry_Avg_Amount
                let avgPracticeValue = res?.data?.data?.find((d)=> d?.Metric === "Practice Value").Industry_Avg_Amount
                let avgGrossValue = res?.data?.data?.find((d)=> d?.Metric === "Gross Revenu").Industry_Avg_Amount

                setAvgOfNewPatientsPerMonthIA(parseInt(avgPatients))
                setAvgOfEbitdaIA(parseInt(avgEbitda))
                setAvgOffExpenseIA(parseInt(avgExpense))
                setAvgOfGrossRevenueIA(parseInt(avgGrossValue))
                setAvgOfPracticeValueIA(parseInt(avgPracticeValue))
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        // if (isTotalPatientsLoading) {
            OverviewSummaryDashboardService.getNumOfTotalPatientMonth()
                .then((res)=> {
                    // let totalPatients = res?.data?.data.find((dta)=> new Date(dta?.month) >= new Date("2023-07-01"))
                    let patientsArr = res?.data?.data.filter((patient, index)=> {
                        return (dayjs(patient?.month?.value).year() === dayjs(toDate).year() && dayjs(patient?.month?.value).month() === dayjs(toDate).month()) || index === res?.data?.data.length - 1
                    })
                    setTrendChartTotalPatientsMonthly(res?.data?.data)
                    // console.log("FILTERED TOTAL PATIENTS : ",patientsArr)
                    let patientNumArr = patientsArr.map((pt)=> {
                        return parseInt(pt?.number_of_patients || 0) || 0
                    })
                    let sumOfPatients = patientNumArr.reduce((prev, curr)=> prev+curr, 0)
                    // let totalPatients = res?.data?.data.find((dta)=> dta?.month === "2023-08-31T18:00:00.000Z")
                    // console.log("totalPatients : ",totalPatients?.number_of_patients)
                    setTotalPatients(Math.round(sumOfPatients) || 0)
                    setIsTotalPatientsLoading(false)
                })
                .catch((err)=> {
                    console.warn("ERR : ",err)
                })
            OverviewSummaryDashboardService.getNumOfTotalPatientYear()
                .then((res)=> {
                    setTrendChartTotalPatientsYearly(res?.data?.data.filter((patientData)=> Number(patientData.year) >= 2018))
                })
                .catch((err)=> {
                    console.warn("ERR : ",err)
                })
        // }
        // if (isActivePatientsLoading) {
            OverviewSummaryDashboardService.getNumOfActivePatient()
                .then((res)=> {
                    // console.log("getNumOfActivePatient : ",res)
                    // let activePatients = res?.data?.data.find((dta)=> dta?.month === "2023-11-30T18:00:00.000Z")
                    // =======================================
                    let patientsArr = res?.data?.data.filter((patient)=> {
                        return dayjs(patient?.month?.value) >= dayjs(fromDate) && dayjs(patient?.month?.value) <= dayjs(toDate)
                    })
                    setTrendChartActivePatientsMonthly(res?.data?.data)
                    let patientNumArr = patientsArr.map((pt)=> {
                        return parseInt(pt?.APV_6_month || 0) || 0
                    })
                    let sumOfPatients = patientNumArr.reduce((prev, curr)=> prev+curr, 0)
                    // =======================================
                    // let activePatients = res?.data?.data.find((dta)=> dta?.month === "2023-08-31T18:00:00.000Z")
                    setActivePatients(Math.round(sumOfPatients/patientsArr.length) || 0)
                    setIsActivePatientsLoading(false)
                })
                .catch((err)=> {
                    console.warn("ERR : ",err)
                })
            OverviewSummaryDashboardService.getNumOfActivePatient12()
                .then((res)=> {
                    // console.log("getNumOfActivePatient : ",res)
                    // let activePatients = res?.data?.data.find((dta)=> dta?.month === "2023-11-30T18:00:00.000Z")
                    // =======================================
                    let patientsArr = res?.data?.data.filter((patient)=> {
                        return dayjs(patient?.month?.value) >= dayjs(fromDate) && dayjs(patient?.month?.value) <= dayjs(toDate)
                    })
                    setTrendChartActivePatientsMonthly12(res?.data?.data)
                    let patientNumArr = patientsArr.map((pt)=> {
                        return parseInt(pt?.APV_12_month || 0) || 0
                    })
                    let sumOfPatients = patientNumArr.reduce((prev, curr)=> prev+curr, 0)
                    // =======================================
                    // let activePatients = res?.data?.data.find((dta)=> dta?.month === "2023-08-31T18:00:00.000Z")
                    setActivePatients12(Math.round(sumOfPatients/patientsArr.length) || 0)
                    setIsActivePatientsLoading(false)
                })
                .catch((err)=> {
                    console.warn("ERR : ",err)
                })
            OverviewSummaryDashboardService.getNumOfActivePatientYear()
                .then((res)=> {
                    setTrendChartActivePatientsYearly(res?.data?.data.filter((patientData)=> Number(patientData.year) >= 2018))
                })
                .catch((err)=> {
                    console.warn("ERR : ",err)
                })
            OverviewSummaryDashboardService.getNumOfActivePatientYear12()
                .then((res)=> {
                    setTrendChartActivePatientsYearly12(res?.data?.data.filter((patientData)=> Number(patientData.year) >= 2018))
                })
                .catch((err)=> {
                    console.warn("ERR : ",err)
                })
        // }
        // if (isNewPatientsLoading) {
            OverviewSummaryDashboardService.getNumOfNewPatientsMonth()
                .then((res)=> {
                    // console.log("getNumOfNewPatientsInDatabaseMonth : ",res)
                    // let activePatients = res?.data?.data.find((dta)=> dta?.month === "2023-11-30T18:00:00.000Z")
                    // =======================================
                    let patientsArr = res?.data?.data.filter((patient)=> {
                        return dayjs(patient?.month?.value) >= dayjs(fromDate) && dayjs(patient?.month?.value) <= dayjs(toDate)
                    })
                    setTrendChartNewPatientsMonthly(res?.data?.data)
                    let patientNumArr = patientsArr.map((pt)=> {
                        return parseInt(pt?.APV_12_month || 0) || 0
                    })
                    let monthCount = patientNumArr.length || 0
                    let sumOfPatients = patientNumArr.reduce((prev, curr)=> prev+curr, 0)

                    // =======================================
                    // let newPatients = res?.data?.data.find((dta)=> dta?.month === "2023-08-31T18:00:00.000Z")
                    // console.log("newPatients : ",newPatients?.number_of_patients)
                    setNewPatients(Math.round(sumOfPatients/monthCount) || 0)
                    setIsNewPatientsLoading(false)
                })
                .catch((err)=> {
                    console.warn("ERR : ",err)
                })
            OverviewSummaryDashboardService.getNumOfNewPatientsYear()
                .then((res)=> {
                    setTrendChartNewPatientsYearly(res?.data?.data.filter((patientData)=> Number(patientData.year) >= 2018))
                })
                .catch((err)=> {
                    console.warn("ERR : ",err)
                })
        // }
    }, [isTotalPatientsLoading, isActivePatientsLoading, isNewPatientsLoading, fromDate, toDate])

    useEffect(()=>{
        // OverviewSummaryDashboardService.getEBITDAVsIndustryAvg()
        //     .then((res)=> {
        //         // =======================================
        //         let patientsArr = res?.data?.data.filter((patient)=> {
        //             let year = patient?.year.split("Year_")[1]
        //             return year >= dayjs(fromDate).year() && year <= dayjs(toDate).year()
        //         })
        //         let EBITDA = patientsArr?.map((pt)=> pt?.ebitda || 0)
        //         let NET_INCOME = patientsArr?.map((pt)=> pt?.net_income || 0)
        //         let EXPENSE = patientsArr?.map((pt)=> pt?.interest_expense || 0)
        //         // res?.data?.data.find((dta)=> dayjs(`01-01-${dta?.year.split("Year_")[0]}`).year() >= dayjs(fromDate).year() && dayjs(`01-01-${dta?.year.split("Year_")[0]}`).year() <= dayjs(fromDate).year())
        //         // =======================================
        //         // let sortedResponse = res?.data?.data.find((dta)=> dta?.year === "Year_2022")
        //         setEbidta(EBITDA.reduce((prev, curr)=> prev+curr, 0))
        //         setRevenue(NET_INCOME.reduce((prev, curr)=> prev+curr, 0))
        //         setExpense(EXPENSE.reduce((prev, curr)=> prev+curr, 0))
        //         // setEbidta(sortedResponse?.ebitda)
        //         // setRevenue(sortedResponse?.net_income)
        //         // setExpense(sortedResponse?.interest_expense)
        //         setIsLoading(false)
        //     })
        //     .catch((err)=> {
        //         console.warn("ERR : ",err)
        //     })
        // OverviewSummaryDashboardService.getChurnRate()
        //     .then((res)=> {
        //         // console.log("getChurnRate",res?.data?.data)
        //         // let sortedResponse = res?.data?.data.find((dta)=> dta?.year === "Year_2022")
        //         let churnRateArr = res?.data?.data.filter((churnRate)=> {
        //             return dayjs(churnRate?.month) >= dayjs(fromDate) && dayjs(churnRate?.month) <= dayjs(toDate)
        //         })
        //         let churnRateCount = churnRateArr.length || 0
        //         let preparedChurnRateArr = churnRateArr?.map((data)=> parseInt(data?.churn_rate) || 0)
        //         setChurnRate(Math.round(preparedChurnRateArr.reduce((prev, curr)=> prev+curr, 0)/churnRateCount))
        //         setRetentionRate(Math.round((1 - ((preparedChurnRateArr.reduce((prev, curr)=> prev+curr, 0)/churnRateCount)/100))*100))
        //         // setChurnRate(Math.round(churnRate?.churn_rate))
        //         // setRetentionRate(Math.round((1 - (churnRate?.churn_rate/100))*100))
        //         setIsLoading(false)
        //     })
        //     .catch((err)=> {
        //         console.warn("ERR : ",err)
        //     })
        OverviewSummaryDashboardService.getChurnYearlyData()
            .then((res)=> {
                setTrendChartChurnRateYearly(res?.data?.data)
                const churnRate = res?.data?.data.find((dta)=> dta?.year === 2023)
                setChurnRate(Math.round(Number(churnRate?.churn_rate)))
                setRetentionRate(100 - Math.round(churnRate?.churn_rate))
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getChurnRateMonthly()
            .then((res)=> {
                // {
                //     "month": {
                //     "value": "2023-12-01T00:00:00.000Z"
                // },
                //     "APV_12_month": 750,
                //     "APV_13_month": 772,
                //     "active_patients_lost": 22,
                //     "churn_rate": 2.933333333333333
                // }
                setTrendChartChurnRateMonthly(res?.data?.data)
                const activePatientsLostArr = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate)).map((dt)=> dt?.active_patients_lost || 0)
                const activePatientsLost = activePatientsLostArr.reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
                setActivePatientsLost(activePatientsLost)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getGrowthRateYearlyData()
            .then((res)=> {
                setTrendChartGrowthRateYearly(res?.data?.data)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getGrowthRateYearlyMonthlyBasisData()
            .then((res)=> {
                // console.log("getGrowthRateYearlyData : ",res?.data?.data)
                const growthRate = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate)).map((filteredData)=> Math.round(Number(filteredData?.practice_growth_rate)))
                // const growthRate2 = res?.data?.data.filter((dta)=> dayjs(dta?.month).isSameOrAfter(dayjs(fromDate))).map((filteredData)=> Number(filteredData?.practice_growth_rate))
                // const growthRate3 = res?.data?.data.filter((dta)=> dayjs(dta?.month).isSameOrAfter(dayjs(fromDate).tz("America/Toronto")) && dayjs(dta?.month).isSameOrBefore(dayjs(toDate).tz("America/Toronto"))).map((filteredData)=> Number(filteredData?.practice_growth_rate))
                setGrowthRate(growthRate[growthRate.length -1])
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getGrowthRateMonthlyData()
            .then((res)=> {
                // console.log("getGrowthRateMonthlyData : ",res?.data?.data)
                // const growthRate = res?.data?.data.find((dta)=> dta?.year === `${new Date().getFullYear() === 2023 ? 2022 : 2023}`)
                setTrendChartGrowthRateMonthly(res?.data?.data)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getAvgRevenuePerAppointment()
            .then((res)=> {
                // let fromDt = dayjs(fromDate)
                let fromDt = dayjs(fromDate)
                let toDt = dayjs(toDate)
                let preparedFromDate = dayjs(`${fromDt.$y}-${fromDt.$M-0}-${fromDt.$D}`)
                let preparedToDate = dayjs(`${toDt.$y}-${toDt.$M}-${toDt.$D}`).endOf("month")
                let preparedFromDateComp = dayjs(`${fromDt.$y-1}-${fromDt.$M+1}-${fromDt.$D}`)
                let preparedToDateComp = dayjs(`${toDt.$y-1}-${toDt.$M+1}-${toDt.$D}`).endOf("month")
                let avgRevPerAppointment = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= preparedFromDate && dayjs(dta?.month?.value) <= preparedToDate).map((filteredData)=> parseInt(filteredData?.revenue_per_appointment))
                let avgRevPerAppointmentLY = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= preparedFromDateComp && dayjs(dta?.month?.value) <= preparedToDateComp).map((filteredData)=> parseInt(filteredData?.revenue_per_appointment))
                setAvgRevenuePerAppointment(avgRevPerAppointment)
                setAvgRevenuePerAppointmentLY(avgRevPerAppointmentLY)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getAvgRevenuePerPatient()
            .then((res)=> {
                let fromDt = dayjs(fromDate)
                let toDt = dayjs(toDate)
                let preparedFromDate = dayjs(`${fromDt.$y}-${fromDt.$M-0}-${fromDt.$D}`)
                let preparedToDate = dayjs(`${toDt.$y}-${toDt.$M}-${toDt.$D}`).endOf("month")
                let preparedFromDateComp = dayjs(`${fromDt.$y-1}-${fromDt.$M+1}-${fromDt.$D}`)
                let preparedToDateComp = dayjs(`${toDt.$y-1}-${toDt.$M+1}-${toDt.$D}`).endOf("month")
                let avgRevPerPatient = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= preparedFromDate && dayjs(dta?.month?.value) <= preparedToDate).map((filteredData)=> parseInt(filteredData?.avg_revenue_per_patient))
                let avgRevPerPatientLY = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= preparedFromDateComp && dayjs(dta?.month?.value) <= preparedToDateComp).map((filteredData)=> parseInt(filteredData?.avg_revenue_per_patient))
                setAvgRevenuePerPatient(avgRevPerPatient)
                setAvgRevenuePerPatientLY(avgRevPerPatientLY)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getAvgRevenuePerNewPatient()
            .then((res)=> {
                let fromDt = dayjs(fromDate)
                let toDt = dayjs(toDate)
                let preparedFromDate = dayjs(`${fromDt.$y}-${fromDt.$M-0}-${fromDt.$D}`)
                let preparedToDate = dayjs(`${toDt.$y}-${toDt.$M}-${toDt.$D}`).endOf("month")
                let preparedFromDateComp = dayjs(`${fromDt.$y-1}-${fromDt.$M+1}-${fromDt.$D}`)
                let preparedToDateComp = dayjs(`${toDt.$y-1}-${toDt.$M+1}-${toDt.$D}`).endOf("month")
                let avgRevPerNewPatient = res?.data?.data.filter((dta)=> dayjs(dta?.join_date?.value) >= preparedFromDate && dayjs(dta?.join_date?.value) <= preparedToDate).map((filteredData)=> parseInt(filteredData?.avg_new_patient_revenue))
                let avgRevPerNewAppointmentLY = res?.data?.data.filter((dta)=> dayjs(dta?.join_date?.value) >= preparedFromDateComp && dayjs(dta?.join_date?.value) <= preparedToDateComp).map((filteredData)=> parseInt(filteredData?.avg_new_patient_revenue))
                setAvgRevenuePerNewPatient(avgRevPerNewPatient)
                setAvgRevenuePerNewPatientLY(avgRevPerNewAppointmentLY)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getAvgRevenuePerAppointmentYearly()
            .then((res)=> {
                let preparedData = res?.data?.data.filter((dt)=> Number(dt?.year) > 2010).map((dta)=> dta?.revenue_per_appointment)
                setAvgRevenuePerAppointmentYearly(preparedData)
                // setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getAvgRevenuePerPatientYearly()
            .then((res)=> {
                let preparedData = res?.data?.data.filter((dt)=> Number(dt?.year) > 2010).map((dta)=> dta?.avg_revenue_per_patient)
                setAvgRevenuePerPatientYearly(preparedData)
                // setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getAvgRevenuePerNewPatientYearly()
            .then((res)=> {
                let preparedData = res?.data?.data.filter((dt)=> Number(dt?.year) > 2010).map((dta)=> dta?.avg_new_patient_revenue)
                setAvgRevenuePerNewPatientYearly(preparedData)
                let preparedDataLabels = res?.data?.data.filter((dt)=> Number(dt?.year) > 2010).map((dta)=> dta?.year)
                setAvgRevenueYearlyLabels(preparedDataLabels)
                // setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getTopProviders()
            .then((res)=> {
                setTrendChartRevByProviderMonthly(res?.data?.data)
                // let count = res?.data?.data.filter((dta)=> dayjs(dta?.month) >= dayjs(fromDate) && dayjs(dta?.month) <= dayjs(toDate)).length
                let p1 = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate) && dta?.provider === 1).map((d)=> {return {production : d?.production || 0, name : d?.Name || "not found", provider : d?.provider}}).sort((a, b)=> b?.production - a?.production)
                let p2 = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate) && dta?.provider === 2).map((d)=> {return {production : d?.production || 0, name : d?.Name || "not found", provider : d?.provider}}).sort((a, b)=> b?.production - a?.production)
                let p3 = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate) && dta?.provider === 3).map((d)=> {return {production : d?.production || 0, name : d?.Name || "not found", provider : d?.provider}}).sort((a, b)=> b?.production - a?.production)
                let p4 = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate) && dta?.provider === 4).map((d)=> {return {production : d?.production || 0, name : d?.Name || "not found", provider : d?.provider}}).sort((a, b)=> b?.production - a?.production)
                let p5 = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate) && dta?.provider === 5).map((d)=> {return {production : d?.production || 0, name : d?.Name || "not found", provider : d?.provider}}).sort((a, b)=> b?.production - a?.production)
                let p6 = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate) && dta?.provider === 6).map((d)=> {return {production : d?.production || 0, name : d?.Name || "not found", provider : d?.provider}}).sort((a, b)=> b?.production - a?.production)
                let p7 = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate) && dta?.provider === 7).map((d)=> {return {production : d?.production || 0, name : d?.Name || "not found", provider : d?.provider}}).sort((a, b)=> b?.production - a?.production)

                let p1Production = p1.map((p)=> p?.production).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
                let p2Production = p2.map((p)=> p?.production).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
                let p3Production = p3.map((p)=> p?.production).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
                let p4Production = p4.map((p)=> p?.production).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
                let p5Production = p5.map((p)=> p?.production).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
                let p6Production = p6.map((p)=> p?.production).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
                let p7Production = p7.map((p)=> p?.production).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)

                let p1Name = { name : p1.length > 0 ? p1[0]?.name : "not found", production : p1Production }
                let p2Name = { name : p2.length > 0 ? p2[0]?.name : "not found", production : p2Production }
                let p3Name = { name : p3.length > 0 ? p3[0]?.name : "not found", production : p3Production }
                let p4Name = { name : p4.length > 0 ? p4[0]?.name : "not found", production : p4Production }
                let p5Name = { name : p5.length > 0 ? p5[0]?.name : "not found", production : p5Production }
                let p6Name = { name : p6.length > 0 ? p6[0]?.name : "not found", production : p6Production }
                let p7Name = { name : p7.length > 0 ? p7[0]?.name : "not found", production : p7Production }

                let providersProductionArr = [p1Production, p2Production, p3Production, p4Production, p5Production, p6Production, p7Production].sort((a, b)=> b - a)
                let providersNameArr = [p1Name, p2Name, p3Name, p4Name, p5Name, p6Name, p7Name].sort((a, b)=> b?.production - a?.production).map((p)=> p?.name)

                providersProductionArr.pop()
                providersProductionArr.pop()
                providersNameArr.pop()
                providersNameArr.pop()
                setTopProviders(providersProductionArr)
                setTopProvidersName(providersNameArr)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getTopProvidersYearly()
            .then((res)=> {
                setTrendChartRevByProviderYearly(res?.data?.data)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        // OverviewSummaryDashboardService.getTopProvidersServices()
        //     .then((res)=> {
        //         // console.log("getTopProvidersServices",res?.data?.data)
        //         let fillingsArr = res?.data?.data.filter((dta)=> dayjs(dta?.month) >= dayjs(fromDate) && dayjs(dta?.month) <= dayjs(toDate) && dta?.description === "Fillings").map((d)=> d?.job_count || 0).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
        //         let endoArr = res?.data?.data.filter((dta)=> dayjs(dta?.month) >= dayjs(fromDate) && dayjs(dta?.month) <= dayjs(toDate) && dta?.description === "ENDO").map((d)=> d?.job_count || 0).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
        //         let scalingArr = res?.data?.data.filter((dta)=> dayjs(dta?.month) >= dayjs(fromDate) && dayjs(dta?.month) <= dayjs(toDate) && dta?.description === "Scaling").map((d)=> d?.job_count || 0).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
        //         let crownArr = res?.data?.data.filter((dta)=> dayjs(dta?.month) >= dayjs(fromDate) && dayjs(dta?.month) <= dayjs(toDate) && dta?.description === "CROWN").map((d)=> d?.job_count || 0).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
        //         let extractionArr = res?.data?.data.filter((dta)=> dayjs(dta?.month) >= dayjs(fromDate) && dayjs(dta?.month) <= dayjs(toDate) && dta?.description === "Extraction").map((d)=> d?.job_count || 0).reduce((prev, curr)=> parseInt(prev)+parseInt(curr),0)
        //         setTopServices([Math.round(fillingsArr/100), Math.round(endoArr/100), Math.round(scalingArr/100), Math.round(crownArr/100), Math.round(extractionArr/100)])
        //         setTopServicesMaxCount([Math.round(fillingsArr/100), Math.round(endoArr/100), Math.round(scalingArr/100), Math.round(crownArr/100), Math.round(extractionArr/100)].sort((a, b)=> b-a))
        //         setIsLoading(false)
        //     })
        //     .catch((err)=> {
        //         console.warn("ERR : ",err)
        //     })
        // OverviewSummaryDashboardService.getTopLocations()
        //     .then((res)=> {
        //         let preparedLocationArr = res?.data?.data.map((data, index)=> {
        //             return {
        //                 name : data?.neighborhood || undefined,
        //                 // neighborhood : data?.neighborhood || undefined,
        //                 // numOfPat : data?.num_of_pats,
        //                 data : [[ index === 1 ? 20 : index === 2 ? 25 : index === 3 ? 30 : index === 4 ? 35 : 15, index === 1 ? 20 : index === 2 ? 25 : index === 3 ? 20 : index === 4 ? 20 : 20, Number(data?.num_of_pats)/4]]
        //             }
        //         })
        //
        //         let sortedPrepLocationArr = preparedLocationArr.sort((a, b)=> b.data[0][2] - a.data[0][2])
        //         setTopLocationSeries(sortedPrepLocationArr)
        //
        //         // let loc1PatCount = res?.data?.data[0]?.num_of_pats || 0
        //         // let loc2PatCount = res?.data?.data[1]?.num_of_pats || 0
        //         // let loc3PatCount = res?.data?.data[2]?.num_of_pats || 0
        //         // let loc1Name = res?.data?.data[0]?.neighborhood || ""
        //         // let loc2Name = res?.data?.data[1]?.neighborhood || ""
        //         // let loc3Name = res?.data?.data[2]?.neighborhood || ""
        //         // setTopLocationNames([loc1Name, loc2Name, loc3Name])
        //         // setTopLocationPatCout([loc1PatCount, loc2PatCount, loc3PatCount])
        //
        //         // setPatientCountStudent(parseInt(res?.data?.data?.[0]?.count))
        //         setIsLoading(false)
        //     })
        //     .catch((err)=> {
        //         console.warn("ERR : ",err)
        //     })
        OverviewSummaryDashboardService.getTopLocationsByDate(toDate)
            .then((res)=> {
                let preparedLocationArr = res?.data?.data.map((data, index)=> {
                    return {
                        name : data?.neighborhood || undefined,
                        // neighborhood : data?.neighborhood || undefined,
                        // numOfPat : data?.num_of_pats,
                        data : [[ index === 1 ? 20 : index === 2 ? 25 : index === 3 ? 30 : index === 4 ? 35 : 15, index === 1 ? 20 : index === 2 ? 25 : index === 3 ? 20 : index === 4 ? 20 : 20, Number(data?.num_of_pats)/20]]
                    }
                })

                const results = res?.data?.data
                const labels = [...new Set(results.map((result)=> result?.borough))]

                let preparedLocationArrNew = res?.data?.data.map((data, index)=> {
                    let preparedData = res?.data?.data.filter((dta)=> data?.borough === dta?.borough).map((dt)=> {
                        return {
                            x : Math.round(dt?.postalcode_area_lng_dis),
                            y: Math.round(dt?.postalcode_area_lat_dis),
                            z: Number(dt?.num_of_pats)/5
                        }
                    })
                    let preparedNeighborhood = res?.data?.data.filter((dta)=> data?.borough === dta?.borough).map((dt)=> {
                        return {
                            name : dt?.neighborhood
                        }
                    })
                    let preparedPatientCount = res?.data?.data.filter((dta)=> data?.borough === dta?.borough).map((dt)=> {
                        return {
                            count : dt?.num_of_pats
                        }
                    })

                    return {
                        name : data?.borough || undefined,
                        neighborhood : [...preparedNeighborhood],
                        numOfPat : [...preparedPatientCount],
                        data : [...preparedData]
                    }
                })

                const sortedLabels = [...new Set(preparedLocationArrNew.map((result)=> result?.name))]
                const preparedChartData = sortedLabels.map((sortedLabel)=> {
                    return {
                        name : sortedLabel,
                        data : preparedLocationArrNew.find((prepData)=> prepData?.name === sortedLabel)?.data
                    }
                })
                const preparedChartDataNeiborhood = sortedLabels.map((sortedLabel)=> {
                    return {
                        borough : sortedLabel,
                        neighborhood : preparedLocationArrNew.find((prepData)=> prepData?.name === sortedLabel)?.neighborhood,
                        patientCount : preparedLocationArrNew.find((prepData)=> prepData?.name === sortedLabel)?.numOfPat
                    }
                })

                // let sortedPrepLocationArr = preparedLocationArrNew.sort((a, b)=> b.data[0][2] - a.data[0][2])
                setTopLocationSeries(preparedChartData)
                setTopLocationSeriesTooltipData(preparedChartDataNeiborhood)

                // let loc1PatCount = res?.data?.data[0]?.num_of_pats || 0
                // let loc2PatCount = res?.data?.data[1]?.num_of_pats || 0
                // let loc3PatCount = res?.data?.data[2]?.num_of_pats || 0
                // let loc1Name = res?.data?.data[0]?.neighborhood || ""
                // let loc2Name = res?.data?.data[1]?.neighborhood || ""
                // let loc3Name = res?.data?.data[2]?.neighborhood || ""
                // setTopLocationNames([loc1Name, loc2Name, loc3Name])
                // setTopLocationPatCout([loc1PatCount, loc2PatCount, loc3PatCount])

                // setPatientCountStudent(parseInt(res?.data?.data?.[0]?.count))
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getTopLocationsByDateActiveP(fromDate, toDate)
            .then((res)=> {
                let preparedLocationArrNew = res?.data?.data.map((data, index)=> {
                    let preparedData = res?.data?.data.filter((dta)=> data?.borough === dta?.borough).map((dt)=> {
                        return {
                            x : Math.round(dt?.postalcode_area_lng_dis),
                            y: Math.round(dt?.postalcode_area_lat_dis),
                            z: Number(dt?.num_of_pats)
                        }
                    })
                    let preparedNeighborhood = res?.data?.data.filter((dta)=> data?.borough === dta?.borough).map((dt)=> {
                        return {
                            name : dt?.neighborhood
                        }
                    })
                    let preparedPatientCount = res?.data?.data.filter((dta)=> data?.borough === dta?.borough).map((dt)=> {
                        return {
                            count : dt?.num_of_pats
                        }
                    })

                    return {
                        name : data?.borough || undefined,
                        neighborhood : [...preparedNeighborhood],
                        numOfPat : [...preparedPatientCount],
                        data : [...preparedData]
                    }
                })

                const sortedLabels = [...new Set(preparedLocationArrNew.map((result)=> result?.name))]
                const preparedChartData = sortedLabels.map((sortedLabel)=> {
                    return {
                        name : sortedLabel,
                        data : preparedLocationArrNew.find((prepData)=> prepData?.name === sortedLabel)?.data
                    }
                })
                const preparedChartDataNeiborhood = sortedLabels.map((sortedLabel)=> {
                    return {
                        borough : sortedLabel,
                        neighborhood : preparedLocationArrNew.find((prepData)=> prepData?.name === sortedLabel)?.neighborhood,
                        patientCount : preparedLocationArrNew.find((prepData)=> prepData?.name === sortedLabel)?.numOfPat
                    }
                })

                setTopLocationSeriesActiveP(preparedChartData)
                setTopLocationSeriesTooltipDataActiveP(preparedChartDataNeiborhood)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getTopLocationsByDateNewP(fromDate, toDate)
            .then((res)=> {
                let preparedLocationArrNew = res?.data?.data.map((data, index)=> {
                    let preparedData = res?.data?.data.filter((dta)=> data?.borough === dta?.borough).map((dt)=> {
                        return {
                            x : Math.round(dt?.postalcode_area_lng_dis),
                            y: Math.round(dt?.postalcode_area_lat_dis),
                            z: Number(dt?.num_of_pats)/2
                        }
                    })
                    let preparedNeighborhood = res?.data?.data.filter((dta)=> data?.borough === dta?.borough).map((dt)=> {
                        return {
                            name : dt?.neighborhood
                        }
                    })
                    let preparedPatientCount = res?.data?.data.filter((dta)=> data?.borough === dta?.borough).map((dt)=> {
                        return {
                            count : dt?.num_of_pats
                        }
                    })

                    return {
                        name : data?.borough || undefined,
                        neighborhood : [...preparedNeighborhood],
                        numOfPat : [...preparedPatientCount],
                        data : [...preparedData]
                    }
                })

                const sortedLabels = [...new Set(preparedLocationArrNew.map((result)=> result?.name))]
                const preparedChartData = sortedLabels.map((sortedLabel)=> {
                    return {
                        name : sortedLabel,
                        data : preparedLocationArrNew.find((prepData)=> prepData?.name === sortedLabel)?.data
                    }
                })
                const preparedChartDataNeiborhood = sortedLabels.map((sortedLabel)=> {
                    return {
                        borough : sortedLabel,
                        neighborhood : preparedLocationArrNew.find((prepData)=> prepData?.name === sortedLabel)?.neighborhood,
                        patientCount : preparedLocationArrNew.find((prepData)=> prepData?.name === sortedLabel)?.numOfPat
                    }
                })

                setTopLocationSeriesNewP(preparedChartData)
                setTopLocationSeriesTooltipDataNewP(preparedChartDataNeiborhood)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getPatientsCountStudent()
            .then((res)=> {
                let studentsCount = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate)).map((filteredData)=> parseInt(filteredData?.num_students || 0)).reduce((prev, curr)=> prev+curr , 0)
                // setPatientCountStudent(parseInt(res?.data?.data?.[0]?.count))
                setPatientCountStudent(studentsCount)
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getPatientsCountRefugee()
            .then((res)=> {
                let refugeeCount = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate)).map((filteredData)=> parseInt(filteredData?.num_refugees || 0)).reduce((prev, curr)=> prev+curr , 0)
                setPatientCountRefugee(refugeeCount)
                // setPatientCountRefugee(parseInt(res?.data?.data?.[0]?.count))
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getPatientsCountOthers()
            .then((res)=> {
                let othersPatientsCount = res?.data?.data.filter((dta)=> dayjs(dta?.month?.value) >= dayjs(fromDate) && dayjs(dta?.month?.value) <= dayjs(toDate)).map((filteredData)=> parseInt(filteredData?.r_type_patients || 0)).reduce((prev, curr)=> prev+curr , 0)
                setPatientCountOthers(othersPatientsCount)
                // setPatientCountRefugee(parseInt(res?.data?.data?.[0]?.count))
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getRevenueByTreatmentType()
            .then((res)=> {
                setServiceTypes(res?.data?.data)
                let diagnosis = res?.data?.data.filter((dta)=> dta?.Category_Name === "Diagnosis").map((d)=> d?.percentage_of_total_production || 0)
                let prevention = res?.data?.data.filter((dta)=> dta?.Category_Name === "Prevention").map((d)=> d?.percentage_of_total_production || 0)
                let restoration = res?.data?.data.filter((dta)=> dta?.Category_Name === "Restoration").map((d)=> d?.percentage_of_total_production || 0)
                let endontics = res?.data?.data.filter((dta)=> dta?.Category_Name === "Endodontics").map((d)=> d?.percentage_of_total_production || 0)
                let periodontics = res?.data?.data.filter((dta)=> dta?.Category_Name === "Periodontics").map((d)=> d?.percentage_of_total_production || 0)
                let prosRem = res?.data?.data.filter((dta)=> dta?.Category_Name === "Prosthodontics - Removable").map((d)=> d?.percentage_of_total_production || 0)
                let prosFix = res?.data?.data.filter((dta)=> dta?.Category_Name === "Prosthodontics - Fixed").map((d)=> d?.percentage_of_total_production || 0)
                let oralMax = res?.data?.data.filter((dta)=> dta?.Category_Name === "Oral & Maxillofacial Surgery").map((d)=> d?.percentage_of_total_production || 0)
                let ortho = res?.data?.data.filter((dta)=> dta?.Category_Name === "Orthodontics").map((d)=> d?.percentage_of_total_production || 0)
                let adjunc = res?.data?.data.filter((dta)=> dta?.Category_Name === "Adjunctive General Services").map((d)=> d?.percentage_of_total_production || 0)
                let diagnosisP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Diagnosis").map((d)=> d?.production || 0)
                let preventionP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Prevention").map((d)=> d?.production || 0)
                let restorationP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Restoration").map((d)=> d?.production || 0)
                let endonticsP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Endodontics").map((d)=> d?.production || 0)
                let periodonticsP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Periodontics").map((d)=> d?.production || 0)
                let prosRemP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Prosthodontics - Removable").map((d)=> d?.production || 0)
                let prosFixP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Prosthodontics - Fixed").map((d)=> d?.production || 0)
                let oralMaxP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Oral & Maxillofacial Surgery").map((d)=> d?.production || 0)
                let orthoP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Orthodontics").map((d)=> d?.production || 0)
                let adjuncP = res?.data?.data.filter((dta)=> dta?.Category_Name === "Adjunctive General Services").map((d)=> d?.production || 0)
                setTopSrcSrvcServices([Math.floor(diagnosis[0] * 100) / 100,Math.floor(prevention[0] * 100) / 100,Math.floor(restoration[0] * 100) / 100,Math.floor(endontics[0] * 100) / 100,Math.floor(periodontics[0] * 100) / 100,Math.floor(prosRem[0] * 100) / 100,Math.floor(prosFix[0] * 100) / 100,Math.floor(oralMax[0] * 100) / 100,Math.floor(ortho[0] * 100) / 100,Math.floor(adjunc[0] * 100) / 100])
                setRevenueByTreatmentType([diagnosisP[0],preventionP[0],restorationP[0],endonticsP[0],periodonticsP[0],prosRemP[0] ,prosFixP[0],oralMaxP[0],orthoP[0],adjuncP[0]])
                setIsLoading(false)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
        OverviewSummaryDashboardService.getServiceCategoryById(0)
            .then((res)=> {
                setSelectedServiceType("Diagnosis" || "")
                setTopServiceCategories(res?.data?.data)
            })
            .catch((err)=> {
                console.warn("ERR : ",err)
            })
    }, [isLoading, fromDate, toDate])

    const handleProviderChange = (event) => {
        const {
            target: { value },
        } = event;
        setFilterByProvider(value);
    };

    const handleFilterBy = (event) => {
        const {
            target: { value },
        } = event;
        setFilterBy(value);
        if (value === "sameAsLastYear") {
            setAvgOfEbitdaIA(Number(lastYearEbidta))
            setAvgOffExpenseIA(Number(lastYearExpense))
            setAvgOfGrossRevenueIA(Number(lastYearRevenue))
        } else if(value === "industryAvg") {
            OverviewSummaryDashboardService.getIndustryAvgData()
                .then((res)=> {
                    let avgPatients = res?.data?.data?.find((d)=> d?.Metric === "Avg of New Patients/month").Industry_Avg_Amount
                    let avgExpense = res?.data?.data?.find((d)=> d?.Metric === "Expenses").Industry_Avg_Amount
                    let avgEbitda = res?.data?.data?.find((d)=> d?.Metric === "EBITDA").Industry_Avg_Amount
                    let avgPracticeValue = res?.data?.data?.find((d)=> d?.Metric === "Practice Value").Industry_Avg_Amount
                    let avgGrossValue = res?.data?.data?.find((d)=> d?.Metric === "Gross Revenu").Industry_Avg_Amount

                    setAvgOfNewPatientsPerMonthIA(parseInt(avgPatients))
                    setAvgOfEbitdaIA(parseInt(avgEbitda))
                    setAvgOffExpenseIA(parseInt(avgExpense))
                    setAvgOfGrossRevenueIA(parseInt(avgGrossValue))
                    setAvgOfPracticeValueIA(parseInt(avgPracticeValue))
                })
                .catch((err)=> {
                    console.warn("ERR : ",err)
                })
        }
    };

    const handleAvgRevViewBy = (event) => {
        const {
            target: { value },
        } = event;
        setAvgRevViewBy(value);
        // if (value === "monthly") {
        //
        // }else if (value === "yearly") {
        //
        // }
    };

    const patientsChartSeries = [totalPatients, activePatients, activePatients12, newPatients]
    const patientsChartOptions = {
        chart: {
            height: 450,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                offsetX: 45,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: 5,
                    size: '50%',
                    background: 'transparent',
                    image: undefined,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: false,
                    }
                }
            }
        },
        colors: ['#184332', '#F2C94C', '#B68340', '#27AE60'],
        labels: ['Total Patients', '6M Active Patients', '12M Active Patients', 'New Patients'],
        legend: {
            show: true,
            floating: true,
            fontSize: '16px',
            position: 'left',
            offsetX: -10,
            offsetY: 0,
            labels: {
                useSeriesColors: true,
            },
            markers: {
                size: 0
            },
            formatter: function(seriesName, opts) {
                return seriesName + ":  " + Utils.amountFormatter(opts.w.globals.series[opts.seriesIndex])
            },
            itemMargin: {
                vertical: 1
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }]
    }

    const lifeCycleChartSeries = [{
        name: "Stage",
        data: [0, 150, 350, 550, 750, 1500]
    }]
    const lifeCycleChartOptions = {
        chart: {
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        markers: {
            size: 2,
            colors: '#B68340',
            strokeColors: '#E0E0E0',
            strokeWidth: 1,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            shape: "circle",
            radius: 2,
        },
        fill: {
            type: "gradient",
            gradient: {
                type: 'horizontal',
                shadeIntensity: 1,
                opacityFrom: 0,
                opacityTo: 1,
                colorStops: [
                    {
                        offset: lifeCycleStageValue,
                        color: "#27AE60",
                        opacity: 1
                    },
                    {
                        offset: lifeCycleStageValue,
                        color: "#E0E0E0",
                        opacity: 1
                    },
                ]
            }
        },
        colors: ["#B68340"],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            color: "red",
            width: 0
        },
        xaxis: {
            show: true,
            categories : ["$0", "$150K", "$350K", "$550K", "$750K", "$1.5M"],
            labels: {
                show: true,
                formatter: function(val) {
                    return val
                }
            },
            axisBorder: {
                show: true,
                color: '#BDBDBD',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: true
            },
            lines : {
                show: true
            },
        },
        yaxis: {
            show: true,
            labels: {
                show: false
            },
            axisBorder: {
                show: true,
                color: "#BDBDBD"
            },
            axisTicks: {
                show: true
            },
            title: {
                text: "Stages",
                style: {
                    color: "#BDBDBD"
                }
            }
        },
        grid: {
            show: true,
            yaxis: {
                lines: {
                    show: false
                }
            },
            xaxis: {
                lines: {
                    show: true
                }
            },
            strokeDashArray: 2
        },
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                let labels = ["Initiated", "Survival", "Stability", "Scaling", "Success", "Star"]
                let values = ["$0", "$150K", "$350K", "$550K", "$750K", "$1.5M"]
                return `<div class="h-auto w-fit bg-white rounded-md shadow-lg py-3 px-4">
                        <div class="flex justify-center">
                            <p class="text-[#184332] font-bold">${labels[dataPointIndex]}</p>
                        </div>
                        <div>
                            <p class="text-[#F2994A] font-semibold">EBITDA ${dataPointIndex ? values[dataPointIndex-1]+" - " : ""} ${values[dataPointIndex]}</p>
                        </div>
                    </div>`
            }
        }
    }

    const ebitaVsIndustryAvgSeries = [Utils.calcPercentage(ebidta, avgOfEbitdaIA)]
    // const ebitaVsIndustryAvgSeries = [Utils.calcPercentage(50, 200)]
    const ebitaVsIndustryAvgOptions =  {
        chart: {
            type: 'radialBar',
            offsetY: -40,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#FFFFFF",
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: true,
                        fontSize: "22px",
                        color: avgOfEbitdaIA > ebidta ? "#EB5757" : "#184332"
                    },
                    value: {
                        show: false,
                        offsetY: -10,
                        fontSize: '22px'
                    }
                }
            }
        },
        fill: {
            colors: [avgOfEbitdaIA > ebidta ? "#EB5757" : "#184332"]
        },
        labels: [`$${Utils.amountFormatter(ebidta)}`],
    }

    const revVsIndustryAvgSeries = [Utils.calcPercentage(revenue, avgOfGrossRevenueIA)]
    const revVsIndustryAvgOptions =  {
        chart: {
            type: 'radialBar',
            offsetY: -40,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#FFFFFF",
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: true,
                        fontSize: "22px",
                        color: avgOfGrossRevenueIA > revenue ? "#EB5757" : "#184332"
                    },
                    value: {
                        show: false,
                        offsetY: -10,
                        fontSize: '22px'
                    }
                }
            }
        },
        fill: {
            colors: [avgOfGrossRevenueIA > revenue ? "#EB5757" : "#184332"]
        },
        labels: [`$${Utils.amountFormatter(revenue)}`],
    }

    const expensesVsIndustryAvgSeries = [Utils.calcPercentage(expense, avgOfExpenseIA)]
    const expensesVsIndustryAvgOptions =  {
        chart: {
            type: 'radialBar',
            offsetY: -40,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#FFFFFF",
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: true,
                        fontSize: "22px",
                        color: avgOfExpenseIA >= expense ? "#184332" : "#EB5757"
                    },
                    value: {
                        show: false,
                        offsetY: -10,
                        fontSize: '22px'
                    }
                }
            }
        },
        fill: {
            colors: [avgOfExpenseIA >= expense ? "#184332" : "#EB5757"]
        },
        labels: [`$${Utils.amountFormatter(expense)}`],
    }

    const practiceChurnRateSeries = [churnRate]
    const practiceChurnRateOptions = {
            chart: {
                // height: 350,
                type: 'radialBar',
            },
            colors : [churnRate > 10 ? "#EB5757" : "#27AE60"],
             stroke: {
                 lineCap: "round",
             },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '65%',
                        color: churnRate > 10 ? "#EB5757" : "#27AE60",
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            color: churnRate > 10 ? "#EB5757" : "#27AE60",
                            fontSize: "35px",
                            show: true,
                            formatter: function (val) {
                                return showChurnRate ? val + '%' : Number(activePatientsLost)
                            }
                        }
                    },
                },
            },
            // labels: ['Cricket'],
        }

    // const practiceRetentionRateSeries = [retentionRate]
    const practiceGrowthRateSeries = [growthRate]
    const practiceGrowthRateOptions = {
            chart: {
                // height: 350,
                type: 'radialBar',
            },
            colors : ["#EB5757"],
             stroke: {
                 lineCap: "round",
             },
            track : {
              width: 35
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '65%',
                        color: "#EB5757",
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            color: "#EB5757",
                            fontSize: "35px",
                            show: true
                        }
                    },
                },
            },
            // labels: ['Cricket'],
        }

    const treatmentAcceptanceRateSeries = [0]
    const treatmentAcceptanceRateOptions = {
            chart: {
                // height: 350,
                type: 'radialBar',
            },
            colors : ["#184332"],
             stroke: {
                 lineCap: "round",
             },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '65%',
                        // color: "#27AE60",
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            color: "#184332",
                            fontSize: "35px",
                            show: true
                        }
                    },
                },
            },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "vertical",
                gradientToColors: ["#C49F5C"],
                stops: [0, 100]
            }
        },
            // labels: ['Cricket'],
        }

    const avgRevSeries = [
        {
        name: "Per Appointment",
        data: avgRevViewBy === "yearly" ? avgRevenuePerAppointmentYearly : avgRevenuePerAppointment
        },
        {
            name: "Per Patient",
            data: avgRevViewBy === "yearly" ? avgRevenuePerPatientYearly : avgRevenuePerPatient
        },
        {
            name: 'Per New Patient',
            data: avgRevViewBy === "yearly" ? avgRevenuePerNewPatientYearly : avgRevenuePerNewPatient
        },
    ]
    const perAppointmentAvgRevSeries = [
        {
        name: `${averageRevenueMonths[0]} - ${averageRevenueMonths[avgRevenuePerAppointment.length -1]}`,
        data: avgRevenuePerAppointment
        },
        {
            name: `${averageRevenueMonthsLY[0]} - ${averageRevenueMonthsLY[avgRevenuePerAppointmentLY.length -1]}`,
            data: avgRevenuePerAppointmentLY
        }
    ]
    const perPatientAvgRevSeries = [
        {
        name:`${averageRevenueMonths[0]} - ${averageRevenueMonths[avgRevenuePerPatient.length -1]}`,
        data: avgRevenuePerPatient
        },
        {
            name: `${averageRevenueMonthsLY[0]} - ${averageRevenueMonthsLY[avgRevenuePerPatientLY.length -1]}`,
            data: avgRevenuePerPatientLY
        },
    ]
    const perNewPatientAvgRevSeries = [
        {
            name: `${averageRevenueMonths[0]} - ${averageRevenueMonths[avgRevenuePerNewPatient.length -1]}`,
            data: avgRevenuePerNewPatient
        },
        {
            name: `${averageRevenueMonthsLY[0]} - ${averageRevenueMonthsLY[avgRevenuePerNewPatientLY.length -1]}`,
            data: avgRevenuePerNewPatientLY
        },
    ]
    const perAppointmentAvgRevSeriesYearly = [
        {
        name: "Per Appointment",
        data: avgRevenuePerAppointmentYearly
        }
    ]
    const perPatientAvgRevSeriesYearly = [
        {
        name: "Per Patient",
        data: avgRevenuePerPatientYearly
        }
    ]
    const perNewPatientAvgRevSeriesYearly = [
        {
            name: "Per New Patient",
            data: avgRevenuePerNewPatientYearly
        }
    ]
    const avgRevOptions = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        colors: ['#184332','#9ADE7B','#B68340'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [5, 7, 5],
            curve: 'smooth',
            dashArray: [0, 0, 0]
        },
        legend: {
            position: "top",
            tooltipHoverFormatter: function(val, opts) {
                return val
            }
        },
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: avgRevViewBy === "yearly" ? avgRevenueYearlyLabels : averageRevenueMonths,
        },
        yaxis: [{
            title: {
                text: "Per Appointment",
                style: {
                    color: '#184332',
                }
            },
            min: avgRevViewBy === "yearly" ? Math.min(...avgRevenuePerAppointmentYearly) : Math.min(...avgRevenuePerAppointment),
            max: avgRevViewBy === "yearly" ? Math.max(...avgRevenuePerAppointmentYearly) : Math.max(...avgRevenuePerAppointment),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.floor(val))}`
                },
                style: {
                    colors: '#184332',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#184332'
            },
        },{
            opposite: true,
            title: {
                text: "Per Patient",
                style: {
                    color: '#9ADE7B',
                }
            },
            min: avgRevViewBy === "yearly" ? Math.min(...avgRevenuePerPatientYearly) : Math.min(...avgRevenuePerPatient),
            max: avgRevViewBy === "yearly" ? Math.max(...avgRevenuePerPatientYearly) : Math.max(...avgRevenuePerPatient),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.floor(val))}`
                },
                style: {
                    colors: '#9ADE7B',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#9ADE7B'
            },
        },{
            opposite: true,
            title: {
                text: "Per New Patient",
                style: {
                    color: '#B68340',
                }
            },
            min: avgRevViewBy === "yearly" ? Math.min(...avgRevenuePerNewPatientYearly) : Math.min(...avgRevenuePerNewPatient),
            max: avgRevViewBy === "yearly" ? Math.max(...avgRevenuePerNewPatientYearly) : Math.max(...avgRevenuePerNewPatient),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.floor(val))}`
                },
                style: {
                    colors: '#B68340',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#B68340'
            },
        }
        ],
        // tooltip: {
        //     y: [
        //         {
        //             title: {
        //                 formatter: function (val) {
        //                     return val + " (mins)"
        //                 }
        //             }
        //         },
        //         {
        //             title: {
        //                 formatter: function (val) {
        //                     return val + " per session"
        //                 }
        //             }
        //         },
        //         {
        //             title: {
        //                 formatter: function (val) {
        //                     return val;
        //                 }
        //             }
        //         }
        //     ]
        // },
        grid: {
            borderColor: '#f1f1f1',
        }
    }
    const perAppointmentAvgRevOptions = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        title: {
            text: 'Per Appointments',
            align: 'center'
        },
        colors: ['#184332','#ff8686'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [5, 5],
            curve: 'smooth',
            dashArray: [0, 2]
        },
        legend: {
            position: "top",
            tooltipHoverFormatter: function(val, opts) {
                return val
            }
        },
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: avgRevViewBy === "yearly" ? avgRevenueYearlyLabels : averageRevenueMonths.map((data)=> data.split("-")[0]),
        },
        yaxis: [{
            title: {
                text: `${averageRevenueMonths[0]} - ${averageRevenueMonths[avgRevenuePerAppointment.length -1]}`,
                style: {
                    color: '#184332',
                }
            },
            min: Math.min(...avgRevenuePerAppointment),
            max: Math.max(...avgRevenuePerAppointment),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.floor(val))}`
                },
                style: {
                    colors: '#184332',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#184332'
            },
        },{
            opposite: true,
            title: {
                text: `${averageRevenueMonthsLY[0]} - ${averageRevenueMonthsLY[avgRevenuePerAppointmentLY.length -1]}`,
                style: {
                    color: '#ff8686',
                }
            },
            min: Math.min(...avgRevenuePerAppointmentLY),
            max: Math.max(...avgRevenuePerAppointmentLY),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.floor(val))}`
                },
                style: {
                    colors: '#ff8686',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#ff8686'
            },
        }
        ],
        grid: {
            borderColor: '#f1f1f1',
        }
    }
    const perPatientAvgRevOptions = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        title: {
            text: 'Per Patients',
            align: 'center'
        },
        colors: ['#9ADE7B','#ff8686'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [5, 5],
            curve: 'smooth',
            dashArray: [0, 2]
        },
        legend: {
            position: "top",
            tooltipHoverFormatter: function(val, opts) {
                return val
            }
        },
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: avgRevViewBy === "yearly" ? avgRevenueYearlyLabels : averageRevenueMonths.map((data)=> data.split("-")[0]),
        },
        yaxis: [{
            title: {
                text: `${averageRevenueMonths[0]} - ${averageRevenueMonths[avgRevenuePerPatient.length -1]}`,
                style: {
                    color: '#184332',
                }
            },
            min: Math.min(...avgRevenuePerPatient),
            max: Math.max(...avgRevenuePerPatient),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.floor(val))}`
                },
                style: {
                    colors: '#184332',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#184332'
            },
        },{
            opposite: true,
            title: {
                text: `${averageRevenueMonthsLY[0]} - ${averageRevenueMonthsLY[avgRevenuePerPatientLY.length -1]}`,
                style: {
                    color: '#ff8686',
                }
            },
            min: Math.min(...avgRevenuePerPatientLY),
            max: Math.max(...avgRevenuePerPatientLY),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.floor(val))}`
                },
                style: {
                    colors: '#ff8686',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#ff8686'
            },
        }
        ],
        grid: {
            borderColor: '#f1f1f1',
        }
    }
    const perNewPatientAvgRevOptions = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        title: {
            text: 'Per New Patients',
            align: 'center'
        },
        colors: ['#B68340','#ff8686'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [5, 5],
            curve: 'smooth',
            dashArray: [0, 2]
        },
        legend: {
            position: "top",
            tooltipHoverFormatter: function(val, opts) {
                return val
            }
        },
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: avgRevViewBy === "yearly" ? avgRevenueYearlyLabels : averageRevenueMonths.map((data)=> data.split("-")[0]),
        },
        yaxis: [{
            title: {
                text: `${averageRevenueMonths[0]} - ${averageRevenueMonths[avgRevenuePerNewPatient.length -1]}`,
                style: {
                    color: '#184332',
                }
            },
            min: Math.min(...avgRevenuePerNewPatient),
            max: Math.max(...avgRevenuePerNewPatient),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.floor(val))}`
                },
                style: {
                    colors: '#184332',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#184332'
            },
        },{
            opposite: true,
            title: {
                text: `${averageRevenueMonthsLY[0]} - ${averageRevenueMonthsLY[avgRevenuePerNewPatientLY.length -1]}`,
                style: {
                    color: '#ff8686',
                }
            },
            min: Math.min(...avgRevenuePerNewPatientLY),
            max: Math.max(...avgRevenuePerNewPatientLY),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.floor(val))}`
                },
                style: {
                    colors: '#ff8686',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#ff8686'
            },
        }
        ],
        grid: {
            borderColor: '#f1f1f1',
        }
    }
    const perAppointmentAvgRevOptionsYearly = {
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        title: {
            text: 'Per Appointments',
            align: 'center'
        },
        colors: ["#184332"],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
            },
        },
        xaxis: {
            categories : avgRevenueYearlyLabels,
        },
        yaxis: {
            min: 0,
            max: Math.max(...avgRevenuePerAppointmentYearly),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.round(val))}`
                },
                style: {
                    colors: '#184332',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#184332',
            },
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            horizontalAlign: 'left'
        }
    }
    const perPatientAvgRevOptionsYearly = {
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        title: {
            text: 'Per Patients',
            align: 'center'
        },
        colors: ["#9ADE7B"],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
            },
        },
        xaxis: {
            categories : avgRevenueYearlyLabels,
        },
        yaxis: {
            min: 0,
            max: Math.max(...avgRevenuePerPatientYearly),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.round(val))}`
                },
                style: {
                    colors: '#9ADE7B',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#9ADE7B',
            },
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            horizontalAlign: 'left'
        }
    }
    const perNewPatientAvgRevOptionsYearly = {
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        title: {
            text: 'Per New Patients',
            align: 'center'
        },
        colors: ["#B68340"],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
            },
        },
        xaxis: {
            categories : avgRevenueYearlyLabels,
        },
        yaxis: {
            min: 0,
            max: Math.max(...avgRevenuePerNewPatientYearly),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(Math.round(val))}`
                },
                style: {
                    colors: '#B68340',
                }
            },
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#B68340',
            },
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            horizontalAlign: 'left'
        }
    }

    // const topProvidersProviderSeries = [{data: [22, 14, 17, 23, 7]}]
    const topProvidersProviderSeries = [{ name : "Revenue", data: topProviders }]
    const topProvidersProviderOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            },
        },
        colors: ['#489677'],
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '25%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                // return val + "% decrease compared to last year"
                return `$${Utils.amountFormatter(val)}`
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: topProvidersName,
            // categories: ['', '', '', '', ''],
            labels: {
                show: false
            },
        },
        yaxis: {
            min: topProviders[topProviders.length - 1],
            // max: 25,
            max: topProviders[0],
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `${val}`
                }
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return Utils.amountFormatter(val)
                }
            }
        }
    }

    // const topProvidersSeries = [{name : "Count", data: topServices}]
    const topProvidersSeries = [{name : "Value", data: topServiceCategories.map((service)=> Number(service?.production))}]
    const topProvidersOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            },
        },
        colors: ['#184332', '#27AE60', '#F2C94C', '#2D9CDB', '#BDBDBD', '#917BD9', '#2CD3E1'],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
                distributed : true
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        legend: {
            horizontalAlign: 'left'
        },
        xaxis: {
            // categories: ['Filling', 'Endo', 'Scaling', 'Crown', 'Extraction'],
            categories: topServiceCategories.map((service)=> service?.description),
            labels: {
                formatter: function (val, index) {
                    return ""
                }
            }
        },
        yaxis: {
            // min: topServicesMaxCount[topServicesMaxCount.length - 1],
            min: 0,
            // max: topServicesMaxCount[0],
            max: Math.max(...topServiceCategories.map((service)=> Number(service?.production))),
            tickAmount: 5,
            labels: {
                formatter: function (val, index) {
                    return `$${Utils.amountFormatter(val)}`
                }
            }
        },
        fill: {
            opacity: 1
        },
        // tooltip: {
        //     y: {
        //         formatter: function (val) {
        //             return "$ " + val + " thousands"
        //         }
        //     }
        // }
    }

    const topSourcesSeries = 1 === 1 ? [100] : [50, 15, 35]
    const topSourcesOptions = {
        chart: {
            type: 'donut',
            offsetY: 20
        },
        colors: 1===1 ? ['#F15A59'] : ['#30A4FC', '#D9D9D9', '#F2C94C'],
        // labels: ['Referral', 'Google', 'Existing'],
        labels: 1 === 1 ? ['Not Found'] : ['Referral', 'Google', 'Existing'],
        legend: {
            position: 'left'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
    }

    const topServicesSeries = !!topSrcSrvcServices ? [...topSrcSrvcServices] : [31.7,47.5,14.9,0.5,0.9,0.5,0.4,1.8,0.1,1.6]
    // const topServicesSeries = [31.7,47.5,14.9,0.5,0.9,0.5,0.4,1.8,0.1,1.6]
    const topServicesOptionsLg = {
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    getServiceCategoryByName(config.w.config.labels[config.dataPointIndex])
                }
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, index) {
                return viewByPercentage ? `${Math.floor(val * 100) / 100 + "%"}` : `$${Utils.amountFormatter(revenueByTreatmentType[index?.seriesIndex])}`
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: '12px',
                fontFamily: undefined,
                backgroundColor : ['#184332','#53BF9D','#BFDB38','#5272F2','#713ABE', '#FFB100', '#917BD9', '#2CD3E1', '#D7BBF5'],
            },
            x: {
                show: true,
                title: {
                    formatter: (seriesName) => seriesName,
                }
            },
            y: {
                formatter: function (val, index) {
                    return viewByPercentage ? `${Math.floor(val * 100) / 100 + "%"}` : `$${Utils.amountFormatter(revenueByTreatmentType[index?.seriesIndex])}`
                }
            },
        },
        labels: ['Diagnosis', 'Prevention', 'Restoration','Endodontics', 'Periodontics', 'Prosthodontics - Removable','Prosthodontics - Fixed', 'Oral & Maxillofacial Surgery', 'Orthodontics', 'Adjunctive General Services'],
        colors: ['#184332','#53BF9D','#BFDB38','#5272F2','#713ABE', '#FFB100', '#917BD9', '#2CD3E1', '#D7BBF5'],
        legend: {
            position: 'bottom'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 400
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }
    const topServicesOptionsSm = {
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    getServiceCategoryByName(config.w.config.labels[config.dataPointIndex])
                }
            },
        },
        // labels: ['Preventive', 'Restrative', 'Cosmetic'],
        // colors: ['#917BD9', '#D9D9D9', '#184332'],
        labels: ['Diagnosis', 'Prevention', 'Restoration','Endodontics', 'Periodontics', 'Prosthodontics - Removable','Prosthodontics - Fixed', 'Oral & Maxillofacial Surgery', 'Orthodontics', 'Adjunctive General Services'],
        colors: ['#184332','#53BF9D','#BFDB38','#5272F2','#713ABE', '#FFB100', '#917BD9', '#2CD3E1', '#D7BBF5'],
        legend: {
            position: 'left'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'left'
                }
            }
        }]
    }

    const toggle = (e)=> {
        ReactApexChart.exec('graphID', 'toggleSeries', e.target.value);
    }

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 14,
            padding : "10px",
        },
    }));

    const handleVisibleTrendChart = (showChart)=> {
        // console.log("topProvidersName 0",topProvidersName)
        if (showChart === "ebitda"){
            setTrendChartModalData({
                chartTitle: "EBITDA",
                monthlyData : trendChartEbidtaMonthly,
                yearlyData : trendChartEbidtaYearly,
                amount : ebidta,
                description : "EBITDA stands for earnings before interest, taxes, depreciation, and amortization, and its margins reflect a practice short-term operational efficiency.",
                descriptionLogoBg : "#BFD6C9",
            })
            setOpenTrendChartModal(true)
        }else if(showChart === "revenue"){
            setTrendChartModalData({
                chartTitle: "Revenue",
                monthlyData : trendChartEbidtaMonthly,
                yearlyData : trendChartRevenueYearly,
                amount : revenue,
                description : "Revenue based on P&L",
                descriptionLogoBg : "#BFD6C9",
            })
            setOpenTrendChartModal(true)
        }else if(showChart === "expense"){
            setTrendChartModalData({
                chartTitle: "Expense",
                monthlyData : trendChartEbidtaMonthly,
                yearlyData : trendChartExpenseYearly,
                amount : expense,
                description : "Expenses Based on P&L",
                descriptionLogoBg : "#BFD6C9",
            })
            setOpenTrendChartModal(true)
        }else if(showChart === "churnRate"){
            setTrendChartModalData({
                chartTitle: "Churn Rate",
                monthlyData : trendChartChurnRateMonthly,
                yearlyData : trendChartChurnRateYearly,
                amount : churnRate,
                description : "Churn rate based on P&L",
                descriptionLogoBg : "#BFD6C9",
            })
            setOpenTrendChartModal(true)
        }else if(showChart === "growthRate"){
            setTrendChartModalData({
                chartTitle: "Growth Rate",
                monthlyData : trendChartGrowthRateMonthly,
                yearlyData : trendChartGrowthRateYearly,
                amount : growthRate,
                description : "Growth rate based on P&L",
                descriptionLogoBg : "#BFD6C9",
            })
            setOpenTrendChartModal(true)
        }else if(showChart === "revenueByProvider"){
            setTrendChartModalData({
                chartTitle: "Revenue By Provider",
                monthlyData : trendChartRevByProviderMonthly,
                yearlyData : trendChartRevByProviderYearly,
                topProvidersName : topProvidersName,
                amount : growthRate,
                description : "Revenue By Provider based on Production",
                descriptionLogoBg : "#BFD6C9",
            })
            setOpenTrendChartModal(true)
        }else if(showChart === "patients"){
            setTrendChartMultipleCategoryModalData({
                chartTitle: "Patients",
                dataTotalPatientsYearly : trendChartTotalPatientsYearly,
                dataTotalPatientsMonthly : trendChartTotalPatientsMonthly,
                dataActivePatientsYearly : trendChartActivePatientsYearly,
                dataActivePatientsMonthly : trendChartActivePatientsMonthly,
                dataActivePatientsYearly12 : trendChartActivePatientsYearly12,
                dataActivePatientsMonthly12 : trendChartActivePatientsMonthly12,
                dataNewPatientsYearly : trendChartNewPatientsYearly,
                dataNewPatientsMonthly : trendChartNewPatientsMonthly,
                amounts : {
                    totalPatients,
                    activePatients,
                    activePatients12,
                    newPatients
                },
                description : {
                    totalPatients : "6M Active Patients : All patients came to the practice in the last six months",
                    activePatients : "Total Patients : All the patient active and inactive in the database (6M/12M)",
                    newPatients : "New Patient : Number of new patient practice see every month"
                },
                descriptionLogoBg : "#BFD6C9",
            })
            setOpenTrendChartMultipleCategoryModal(true)
        }
    }

    const handleClickAway = ()=> {
        setFromDateOpen(false)
        setToDateOpen(false)
    }


    const topLocationsOptions = {
        chart: {
            type: 'bubble',
            toolbar: {
                show: false
            }
        },
        series: patLocViewBy === "total" ? topLocationSeries : patLocViewBy === "active" ? topLocationSeriesActiveP : topLocationSeriesNewP,
        plotOptions : {
            bubble : {
                zScaling : false,
                maxBubbleRadius : patLocViewBy === "total" ? topLocationSeries[0]?.data[0][2] : patLocViewBy === "active" ? topLocationSeriesActiveP[0]?.data[0][2] : topLocationSeriesNewP[0]?.data[0][2],
                // minBubbleRadius : patLocViewBy === "total" ? topLocationSeries[topLocationSeries.length -1]?.data[0][2] : patLocViewBy === "active" ? topLocationSeriesActiveP[topLocationSeriesActiveP.length -1]?.data[0][2] : topLocationSeriesNewP[topLocationSeriesNewP.length -1]?.data[0][2]
                minBubbleRadius : 3
            }
        },
        colors : ['#184332','#53BF9D','#BFDB38','#5272F2','#713ABE', '#FFB100', '#917BD9', '#2CD3E1', '#D7BBF5'],
        dataLabels: {
            enabled: false
        },
        xaxis: {
            title: {
                text: 'lng_dis',
            },
            min: -15,
            max: 20,
            tickAmount: 7
        },
        yaxis: {
            title: {
                text: 'lat_dis',
            },
            min: -15,
            max: 15,
            tickAmount: 6
        },
        grid: {
            show: true,
            background: '#BFCFE7',
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        tooltip: {
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                return `<div class="h-auto w-fit bg-white rounded-md shadow-lg py-3 px-4">
                        <div class="flex justify-center">
                            <p class="text-[#184332] font-bold">
                                ${patLocViewBy === "total" ? 
                                    topLocationSeriesTooltipData[seriesIndex]?.borough 
                                    : patLocViewBy === "active" ? 
                                        topLocationSeriesTooltipDataActiveP[seriesIndex]?.borough 
                                        : topLocationSeriesTooltipDataNewP[seriesIndex]?.borough}
                            </p>
                        </div>
                        <div>
                            <p class="text-[#F2994A] font-semibold">Neiborhood : 
                                ${patLocViewBy === "total" ? 
                                    topLocationSeriesTooltipData[seriesIndex]?.neighborhood[dataPointIndex]?.name 
                                    : patLocViewBy === "active" ? 
                                        topLocationSeriesTooltipDataActiveP[seriesIndex]?.neighborhood[dataPointIndex]?.name
                                        : topLocationSeriesTooltipDataNewP[seriesIndex]?.neighborhood[dataPointIndex]?.name}
                            </p>
                        </div>
                        <div>
                            <p class="text-[#713ABE] font-semibold">Patient Count : 
                                ${patLocViewBy === "total" ? 
                                    topLocationSeriesTooltipData[seriesIndex]?.patientCount[dataPointIndex]?.count 
                                    : patLocViewBy === "active" ? 
                                        topLocationSeriesTooltipDataActiveP[seriesIndex]?.patientCount[dataPointIndex]?.count 
                                        : topLocationSeriesTooltipDataNewP[seriesIndex]?.patientCount[dataPointIndex]?.count}</p>
                        </div>
                    </div>`
            },
        }
    };

    return (
        <>
            <NavAndTopBar>
                <div className="px-7 py-6">
                    <div>
                        <Hidden mdDown>
                            <div className="flex items-center justify-between gap-4">
                                <div className="flex flex-col">
                                    <p className="font-bold text-2xl">
                                        Welcome
                                    </p>
                                    <p>To Summary Dashboard</p>
                                    {/*<div className="flex justify-between items-center bg-white shadow-lg rounded-xl px-1 w-[260px] h-[48px]">*/}
                                    {/*    <div className={`flex items-center justify-center w-[126px] h-[40px] ${selectedTab === "overview" ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setSelectedTab("overview")}>*/}
                                    {/*        <p className={`${selectedTab === "overview" ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>Overview</p>*/}
                                    {/*    </div>*/}
                                    {/*    <div className={`flex items-center justify-center w-[126px] h-[40px] ${selectedTab === "kpiSummary" ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setSelectedTab("kpiSummary")}>*/}
                                    {/*        <p className={`${selectedTab === "kpiSummary" ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>KPI Summary</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="flex gap-3">
                                    <div className="flex flex-col justify-center items-center relative">
                                        <div
                                            className="flex items-center gap-2"
                                        >
                                            <div>From</div>
                                            <div
                                                className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px] bg-white"
                                                onClick={()=> {
                                                    setFromDateOpen(!fromDateOpen);
                                                    setToDateOpen(false);
                                                }}
                                            >
                                                <div>{dayjs(fromDate).format('MMM, YYYY')}</div>
                                                <CalendarMonthOutlinedIcon />
                                            </div>

                                        </div>
                                        {
                                            fromDateOpen && (
                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                    <div className="fixed bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateCalendar']}>
                                                                <DateCalendar
                                                                    value={fromDate} onChange={(newValue) => {setFromDate(newValue); setFromDateOpen(!fromDateOpen);}}
                                                                    views={['month', 'year']}
                                                                    disableFuture
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                </ClickAwayListener>
                                                // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
                                            )
                                        }
                                    </div>
                                    <div className="flex flex-col justify-center items-center relative">
                                        <div
                                            className="flex items-center gap-2"
                                        >
                                            <div>To</div>
                                            <div
                                                className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px] bg-white"
                                                onClick={()=> {
                                                    setToDateOpen(!toDateOpen);
                                                    setFromDateOpen(false);
                                                }}
                                            >
                                                <div>{dayjs(toDate).format('MMM, YYYY')}</div>
                                                <CalendarMonthOutlinedIcon />
                                            </div>

                                        </div>
                                        {
                                            toDateOpen && (
                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                    <div className="fixed bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateCalendar']}>
                                                                <DateCalendar
                                                                    value={toDate} onChange={(newValue) => {setToDate(newValue); setToDateOpen(!toDateOpen);}}
                                                                    views={['month', 'year']}
                                                                    disableFuture
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                </ClickAwayListener>
                                                // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
                                            )
                                        }
                                    </div>
                                    <FormControl
                                    >
                                        <Select
                                            id="providerFilterBy"
                                            sx={{fontFamily: "Poppins",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                padding: "0 5px",
                                                color: "#184332",
                                                borderRadius: "30px",
                                                height: "40px",
                                                backgroundColor: "white"
                                            }}
                                            fullWidth
                                            value={filterByProvider}
                                            onChange={handleProviderChange}
                                            renderValue={(selected) => {
                                                return (
                                                    <Box sx={{ display: "flex", gap: 1 }} className="flex items-center">
                                                        <SvgIcon color="primary">
                                                            <TuneIcon sx={{color: "#184332"}}/>
                                                        </SvgIcon>
                                                        <em>{selected.length === 0 ? "Provider" : Utils.camelCaseToTitleCase(selected)}</em>
                                                    </Box>
                                                )
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value=""  sx={{backgroundColor: "white"}}>
                                                Provider
                                            </MenuItem>
                                            <hr style={{margin: "0 15px"}}/>
                                            {
                                                !!topProvidersName && topProvidersName.length > 0 && topProvidersName.map((provider)=> (
                                                    <MenuItem value={provider} sx={{margin: "0 15px", borderRadius: "10px"}}>{provider}</MenuItem>
                                                ))
                                            }
                                            {/*<MenuItem value="dr.1" sx={{margin: "0 15px", borderRadius: "10px"}}>Dr. 1</MenuItem>*/}
                                            {/*<MenuItem value="dr.2" sx={{margin: "0 15px", borderRadius: "10px"}}>Dr. 2</MenuItem>*/}
                                            {/*<MenuItem value="hygienist1" sx={{margin: "0 15px", borderRadius: "10px"}}>Hygienist 1</MenuItem>*/}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                    >
                                        <Select
                                            id="filterBy"
                                            sx={{fontFamily: "Poppins",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                padding: "0 5px",
                                                color: "#184332",
                                                borderRadius: "30px",
                                                height: "40px",
                                                backgroundColor: "white",
                                            }}
                                            fullWidth
                                            value={filterBy}
                                            onChange={handleFilterBy}
                                            renderValue={(selected) => {
                                                return (
                                                    <Box sx={{ display: "flex", gap: 1 }} className="flex items-center">
                                                        <SvgIcon color="primary">
                                                            <TuneIcon sx={{color: "#184332"}}/>
                                                        </SvgIcon>
                                                        <em>{selected.length === 0 ? "Filter By" : Utils.camelCaseToTitleCase(selected)}</em>
                                                    </Box>
                                                )
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value=""  sx={{backgroundColor: "white"}}>
                                                Filter By
                                            </MenuItem>
                                            <hr style={{margin: "0 15px"}}/>
                                            <MenuItem value="industryAvg" sx={{margin: "0 15px", borderRadius: "10px"}}>Industry Avg</MenuItem>
                                            <MenuItem value="sameAsLastYear" sx={{margin: "0 15px", borderRadius: "10px"}}>Same As Last Year</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div className="flex flex-col items-start justify-between gap-4">
                                <div className="flex flex-col">
                                    <p className="font-bold text-2xl">
                                        Welcome
                                    </p>
                                    <p>To Summary Dashboard</p>
                                    {/*<div className="flex justify-between items-center bg-white shadow-lg rounded-xl px-1 w-[260px] h-[48px] mt-4">*/}
                                    {/*    <div className={`flex items-center justify-center w-[126px] h-[40px] ${selectedTab === "overview" ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setSelectedTab("overview")}>*/}
                                    {/*        <p className={`${selectedTab === "overview" ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>Overview</p>*/}
                                    {/*    </div>*/}
                                    {/*    <div className={`flex items-center justify-center w-[126px] h-[40px] ${selectedTab === "kpiSummary" ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setSelectedTab("kpiSummary")}>*/}
                                    {/*        <p className={`${selectedTab === "kpiSummary" ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>KPI Summary</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="flex gap-3">
                                    <FormControl
                                    >
                                        <Select
                                            id="providerFilterBy"
                                            sx={{fontFamily: "Poppins",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                padding: "0 5px",
                                                color: "#184332",
                                                borderRadius: "30px",
                                                height: "50px",
                                                backgroundColor: "white"
                                            }}
                                            fullWidth
                                            value={filterByProvider}
                                            onChange={handleProviderChange}
                                            renderValue={(selected) => {
                                                return (
                                                    <Box sx={{ display: "flex", gap: 1 }} className="flex items-center">
                                                        <SvgIcon color="primary">
                                                            <TuneIcon sx={{color: "#184332"}}/>
                                                        </SvgIcon>
                                                        <em>{selected.length === 0 ? "Provider" : Utils.camelCaseToTitleCase(selected)}</em>
                                                    </Box>
                                                )
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value=""  sx={{backgroundColor: "white"}}>
                                                Provider
                                            </MenuItem>
                                            <hr style={{margin: "0 15px"}}/>
                                            <MenuItem value="dr.1" sx={{margin: "0 15px", borderRadius: "10px"}}>Dr. 1</MenuItem>
                                            <MenuItem value="dr.2" sx={{margin: "0 15px", borderRadius: "10px"}}>Dr. 2</MenuItem>
                                            <MenuItem value="hygienist1" sx={{margin: "0 15px", borderRadius: "10px"}}>Hygienist 1</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                    >
                                        <Select
                                            id="filterBy"
                                            sx={{fontFamily: "Poppins",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                padding: "0 5px",
                                                color: "#184332",
                                                borderRadius: "30px",
                                                height: "50px",
                                                backgroundColor: "white",
                                            }}
                                            fullWidth
                                            value={filterBy}
                                            onChange={handleFilterBy}
                                            renderValue={(selected) => {
                                                return (
                                                    <Box sx={{ display: "flex", gap: 1 }} className="flex items-center">
                                                        <SvgIcon color="primary">
                                                            <TuneIcon sx={{color: "#184332"}}/>
                                                        </SvgIcon>
                                                        <em>{selected.length === 0 ? "Filter By" : Utils.camelCaseToTitleCase(selected)}</em>
                                                    </Box>
                                                )
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value=""  sx={{backgroundColor: "white"}}>
                                                Filter By
                                            </MenuItem>
                                            <hr style={{margin: "0 15px"}}/>
                                            <MenuItem value="industryAvg" sx={{margin: "0 15px", borderRadius: "10px"}}>Industry Avg</MenuItem>
                                            <MenuItem value="sameAsLastYear" sx={{margin: "0 15px", borderRadius: "10px"}}>Same As Last Year</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Hidden>
                    </div>

                    {/*Patients and Life Cycle Section*/}
                    <div>
                        <Hidden mdDown>
                            <div className="mt-6 flex gap-6">
                                <div className="bg-white rounded-xl shadow-md w-[550px] ">
                                    <div className="pt-3 px-4">
                                        <div className="flex justify-between px-4">
                                            <div className="flex items-center gap-2">
                                                <p className="chart-subtitle">Patients</p>
                                                <LightTooltip title={
                                                    <div>
                                                        <p className="font-bold">6M Active Patients <span className="font-light text-sm">: All patients came to the practice in the last six months</span></p> <br/>
                                                        <p className="font-bold">Total Patients <span className="font-light text-sm">: All the patient active and inactive in the database</span></p> <br/>
                                                        <p className="font-bold">New Patient <span className="font-light text-sm">: Number of new patient practice see every month</span></p>
                                                    </div>
                                                } arrow placement="top">
                                                    <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                                </LightTooltip>
                                            </div>
                                            <QueryStatsOutlinedIcon
                                                className="cursor-pointer"
                                                onClick={()=> handleVisibleTrendChart("patients")}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <ReactApexChart options={patientsChartOptions} series={patientsChartSeries} type="radialBar" height={265} />
                                    </div>
                                </div>
                                <div className="shadow-lg bg-white w-full rounded-[24px] px-4 ">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Life Cycle</p>
                                            <LightTooltip title={
                                                <div>
                                                    <p>Here you can see your dental practice stage based on your practice EBITDA.</p>
                                                    <p>Each stage requires different kinds of actions and focus.</p>
                                                </div>
                                            } arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    {/*<p className="pt-3 pl-4 font-semibold text-sm">Life Cycle</p>*/}
                                    <div className="w-full">
                                        <ReactApexChart options={lifeCycleChartOptions} series={lifeCycleChartSeries} type="area" height={220}/>
                                    </div>
                                    <div className="flex justify-center pb-3">
                                        <p className="font-bold">EBITDA</p>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div className="mt-6 flex flex-col gap-6">
                                <div className="shadow-lg bg-white w-full rounded-[24px] px-4 ">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="text-[#184332] font-bold">Life Cycle</p>
                                            <LightTooltip title="Life Cycle graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    {/*<p className="pt-3 pl-4 font-semibold text-sm">Life Cycle</p>*/}
                                    <div className="w-full">
                                        <ReactApexChart options={lifeCycleChartOptions} series={lifeCycleChartSeries} type="area" height={220}/>
                                    </div>
                                    <div className="flex justify-center pb-3">
                                        <p className="font-bold">EBITDA</p>
                                    </div>
                                </div>
                                <div className="bg-white rounded-xl shadow-md w-full">
                                    <div className="px-4 pt-3">
                                        <div>
                                            <p className="text-[#184332] font-bold">Patients</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ReactApexChart options={patientsChartOptions} series={patientsChartSeries} type="radialBar" height={265} />
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                    </div>

                    {/*EBITDA, Rev and Expenses vs Industry Avg. Section*/}
                    <div>
                        <Hidden mdDown>
                            <div className="flex gap-6">
                                <div className="flex flex-col justify-between chartContainer1 shadow-lg bg-white mt-6 px-4">
                                    <div className="flex justify-between pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">EBITDA vs Industry Avg.</p>
                                            <LightTooltip title={
                                                <div>
                                                    <p>Earning Before Interest Tax Deppreciation and Amortization.</p>
                                                    <p>Having a healthy EBITDA means having a healthy practice.</p>
                                                </div>
                                            } arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                        <QueryStatsOutlinedIcon
                                            className="cursor-pointer"
                                            // onClick={()=> navigate("/trend-chart",
                                            //     {
                                            //         state: {
                                            //             chartTitle: "EBITDA",
                                            //             data : trendChartEbidta,
                                            //             amount : ebidta,
                                            //             // data : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.ebitda) : [],
                                            //             // xAxisLabels : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.year) : [],
                                            //             description : "EBITDA stands for earnings before interest, taxes, depreciation, and amortization, and its margins reflect a practice short-term operational efficiency.",
                                            //             descriptionLogoBg : "#BFD6C9",
                                            //
                                            //         }
                                            //     })}
                                            onClick={()=> handleVisibleTrendChart("ebitda")}
                                        />
                                    </div>
                                    <div>
                                        <GaugeChart options={ebitaVsIndustryAvgOptions} series={ebitaVsIndustryAvgSeries}/>
                                        <div className="flex justify-between sm:px-10 md:px-16 mt-[-35px] mb-2">
                                            <p className="ml-3.5">$0</p>
                                            <p className="mr-0.5">${Utils.amountFormatter(`${avgOfEbitdaIA}`)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between chartContainer1 shadow-lg bg-white mt-6 px-4">
                                    <div className="flex justify-between pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Rev vs Industry Avg.</p>
                                            <LightTooltip title="Revenue of the practice based on the P&L - Financial Statement" arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                        <QueryStatsOutlinedIcon
                                            className="cursor-pointer"
                                            // onClick={()=> navigate("/trend-chart",
                                            //     {
                                            //         state: {
                                            //             chartTitle: "Revenue",
                                            //             data : trendChartEbidta,
                                            //             amount : revenue,
                                            //             // data : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.ebitda) : [],
                                            //             // xAxisLabels : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.year) : [],
                                            //             description : "Revenue based on P&L",
                                            //             descriptionLogoBg : "#BFD6C9",
                                            //
                                            //         }
                                            //     })}
                                            onClick={()=> handleVisibleTrendChart("revenue")}
                                        />
                                    </div>
                                    {/*<div className="flex justify-between pt-2">*/}
                                    {/*    <p className="chart-subtitle">Rev vs Industry Avg.</p>*/}
                                    {/*</div>*/}
                                    <div>
                                        <GaugeChart options={revVsIndustryAvgOptions} series={revVsIndustryAvgSeries}/>
                                        <div className="flex justify-between sm:px-10 md:px-16 mt-[-35px] mb-2">
                                            <p className="ml-3.5">$0</p>
                                            <p className="mr-2">${Utils.amountFormatter(`${avgOfGrossRevenueIA}`)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between chartContainer1 shadow-lg bg-white mt-6 px-4">
                                    <div className="flex justify-between pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Expenses vs Industry Avg.</p>
                                            <LightTooltip title="Total Expenses of the Practice based on the P&L - Financial Statement" arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                        <QueryStatsOutlinedIcon
                                            className="cursor-pointer"
                                            // onClick={()=> navigate("/trend-chart",
                                            //     {
                                            //         state: {
                                            //             chartTitle: "Expense",
                                            //             data : trendChartEbidta,
                                            //             amount : expense,
                                            //             // data : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.ebitda) : [],
                                            //             // xAxisLabels : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.year) : [],
                                            //             description : "Expenses Based on P&L",
                                            //             descriptionLogoBg : "#BFD6C9",
                                            //
                                            //         }
                                            //     })}
                                            onClick={()=> handleVisibleTrendChart("expense")}
                                        />
                                    </div>
                                    {/*<div className="flex justify-between pt-2">*/}
                                    {/*    <p className="chart-subtitle">Expenses vs Industry Avg.</p>*/}
                                    {/*</div>*/}
                                    <div>
                                        <GaugeChart options={expensesVsIndustryAvgOptions} series={expensesVsIndustryAvgSeries}/>
                                        <div className="flex justify-between sm:px-10 md:px-16 mt-[-35px] mb-2">
                                            <p className="ml-3.5">$0</p>
                                            <p className="mr-0.5">${Utils.amountFormatter(`${avgOfExpenseIA}`)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div className="flex flex-col gap-6">
                                <div className="flex flex-col justify-between rounded-2xl shadow-lg bg-white mt-6 px-4">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">EBITDA vs Industry Avg.</p>
                                            <LightTooltip title="EBITDA vs Industry Avg. graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <GaugeChart options={ebitaVsIndustryAvgOptions} series={ebitaVsIndustryAvgSeries}/>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between rounded-2xl shadow-lg bg-white mt-6 px-4">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Rev vs Industry Avg.</p>
                                            <LightTooltip title="Rev vs Industry Avg. graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <GaugeChart options={revVsIndustryAvgOptions} series={revVsIndustryAvgSeries}/>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between rounded-2xl shadow-lg bg-white mt-6 px-4">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Expenses vs Industry Avg.</p>
                                            <LightTooltip title="Expenses vs Industry Avg. graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <GaugeChart options={expensesVsIndustryAvgOptions} series={expensesVsIndustryAvgSeries}/>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                    </div>

                    {/*Practice Churn/Retention and Treatment acceptance Rate Section*/}
                    <div className="w-full">
                        <Hidden mdDown>
                            <div className="flex gap-6">
                                <div className="flex flex-col justify-between chartContainer1 shadow-lg bg-white mt-6 px-4 relative">
                                    <div className="flex justify-between pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Practice Churn Rate</p>
                                            <LightTooltip title={
                                                <div>
                                                    <p className="font-bold">Churn Rate <span className="font-light text-sm">= Total Inactive Patients/Total Active patients</span></p> <br/>
                                                    <p className="font-bold">6M Active Patients <span className="font-light text-sm">: Total number of patients with an appointment in the past 12 months</span></p> <br/>
                                                    <p className="font-bold">Inactive Patients <span className="font-light text-sm">: Total number of patients with an appointment in the past 24months - 6M Active Patients</span></p>
                                                </div>
                                            } arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                        <QueryStatsOutlinedIcon
                                            className="cursor-pointer"
                                            // onClick={()=> navigate("/trend-chart",
                                            //     {
                                            //         state: {
                                            //             chartTitle: "EBITDA",
                                            //             data : trendChartEbidta,
                                            //             amount : ebidta,
                                            //             // data : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.ebitda) : [],
                                            //             // xAxisLabels : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.year) : [],
                                            //             description : "EBITDA stands for earnings before interest, taxes, depreciation, and amortization, and its margins reflect a practice short-term operational efficiency.",
                                            //             descriptionLogoBg : "#BFD6C9",
                                            //
                                            //         }
                                            //     })}
                                            onClick={()=> handleVisibleTrendChart("churnRate")}
                                        />
                                    </div>
                                    <div className="absolute top-10 right-7 z-20">
                                        <div className="flex justify-between items-center bg-white shadow-lg rounded-xl px-1 w-[120px] h-[30px]">
                                            <div className={`flex items-center justify-center w-[60px] h-[25px] ${showChurnRate ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setShowChurnRate(true)}>
                                                <p className={`${showChurnRate ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>% Value</p>
                                            </div>
                                            <div className={`flex items-center justify-center w-[60px] h-[25px] ${!showChurnRate ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setShowChurnRate(false)}>
                                                <p className={`${!showChurnRate ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}># Value</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="flex justify-between pt-2">*/}
                                    {/*    <p className="chart-subtitle">Practice Churn Rate</p>*/}
                                    {/*</div>*/}
                                    {
                                        showChurnRate && (
                                            <div>
                                                <RadialBarChart options={practiceChurnRateOptions} series={practiceChurnRateSeries}/>
                                            </div>
                                        )
                                    }
                                    {
                                        !showChurnRate && (
                                            <div className="flex justify-center mb-16">
                                                <div className={`flex justify-between px-3 py-2 items-center rounded-sm h-[50px] w-[260px]  bg-[#E4FFF4]`}>
                                                    <div className="text-[15px] font-bold text-[#4F4F4F]">Count</div>
                                                    <div className="flex flex-col items-center justify-center gap-1">
                                                        <div className="text-[15px] font-bold text-[#4F4F4F]">{activePatientsLost}</div>
                                                        <div className="text-[13px]">No of Patients lost</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="flex flex-col justify-between chartContainer1 shadow-lg bg-white mt-6 px-4">
                                    <div className="flex justify-between pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            {/*<p className="chart-subtitle">Practice Retention Rate</p>*/}
                                            {/*<LightTooltip title="Practice Retention Rate graph based on date range." arrow placement="top">*/}
                                            {/*    <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>*/}
                                            {/*</LightTooltip>*/}
                                            <p className="chart-subtitle">Practice Growth Rate</p>
                                            <LightTooltip title={
                                                <div>
                                                    <p className="font-bold">Growth Rate <span className="font-light text-sm">= (new patients - inactive patients)/6M Active Patients</span></p> <br/>
                                                </div>
                                            } arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                        <QueryStatsOutlinedIcon
                                            className="cursor-pointer"
                                            // onClick={()=> navigate("/trend-chart",
                                            //     {
                                            //         state: {
                                            //             chartTitle: "EBITDA",
                                            //             data : trendChartEbidta,
                                            //             amount : ebidta,
                                            //             // data : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.ebitda) : [],
                                            //             // xAxisLabels : trendChartEbidta.length > 0 ? trendChartEbidta.map((data)=> data?.year) : [],
                                            //             description : "EBITDA stands for earnings before interest, taxes, depreciation, and amortization, and its margins reflect a practice short-term operational efficiency.",
                                            //             descriptionLogoBg : "#BFD6C9",
                                            //
                                            //         }
                                            //     })}
                                            onClick={()=> handleVisibleTrendChart("growthRate")}
                                        />
                                    </div>
                                    {/*<div className="flex justify-between pt-2">*/}
                                    {/*    <p className="chart-subtitle">Practice Retention Rate</p>*/}
                                    {/*</div>*/}
                                    <div>
                                        <RadialBarChart options={practiceGrowthRateOptions} series={practiceGrowthRateSeries}/>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between chartContainer1 shadow-lg bg-white mt-6 px-4">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Treatment acceptance Rate</p>
                                            <LightTooltip title="Practice Retention Rate graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    {/*<div className="flex justify-between pt-2">*/}
                                    {/*    <p className="chart-subtitle">Treatment acceptance Rate</p>*/}
                                    {/*</div>*/}
                                    <div>
                                        <RadialBarChart options={treatmentAcceptanceRateOptions} series={treatmentAcceptanceRateSeries}/>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div className="flex flex-col gap-6">
                                <div className="flex flex-col justify-between rounded-2xl shadow-lg bg-white mt-6 px-4">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Practice Churn Rate</p>
                                            <LightTooltip title="Practice Churn Rate graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <RadialBarChart options={practiceChurnRateOptions} series={practiceChurnRateSeries}/>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between rounded-2xl shadow-lg bg-white mt-6 px-4">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            {/*<p className="chart-subtitle">Practice Retention Rate</p>*/}
                                            <p className="chart-subtitle">Practice Growth Rate</p>
                                            <LightTooltip title={
                                                <div>
                                                    <p className="font-bold">Growth Rate <span className="font-light text-sm">= (new patients - inactive patients)/6M Active Patients</span></p> <br/>
                                                </div>
                                            } arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <RadialBarChart options={practiceGrowthRateOptions} series={practiceGrowthRateSeries}/>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between rounded-2xl shadow-lg bg-white mt-6 px-4">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Treatment acceptance Rate</p>
                                            <LightTooltip title="Practice Retention Rate graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <RadialBarChart options={treatmentAcceptanceRateOptions} series={treatmentAcceptanceRateSeries}/>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                    </div>

                    {/*Average Revenue Section*/}
                    <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                        <div className="flex justify-between pt-3 px-4">
                            <div className="flex items-center gap-2">
                                <p className="chart-subtitle">Average Revenue</p>
                                <LightTooltip title="Average Revenue graph based on date range." arrow placement="top">
                                    <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                </LightTooltip>
                            </div>
                            <div className="flex justify-between items-center bg-white shadow-lg rounded-xl px-1 w-[160px] h-[30px]">
                                <div className={`flex items-center justify-center w-[70px] h-[25px] ${avgRevViewBy === "monthly" ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setAvgRevViewBy("monthly")}>
                                    <p className={`${avgRevViewBy === "monthly" ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>Monthly</p>
                                </div>
                                <div className={`flex items-center justify-center w-[70px] h-[25px] ${avgRevViewBy !== "monthly" ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setAvgRevViewBy("yearly")}>
                                    <p className={`${avgRevViewBy !== "monthly" ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>Yearly</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-[320px] overflow-y-scroll">
                            {
                                avgRevViewBy === "monthly" && (
                                    <>
                                        <ReactApexChart options={perAppointmentAvgRevOptions} series={perAppointmentAvgRevSeries} type="line" height={300} />
                                        <ReactApexChart options={perPatientAvgRevOptions} series={perPatientAvgRevSeries} type="line" height={300} />
                                        <ReactApexChart options={perNewPatientAvgRevOptions} series={perNewPatientAvgRevSeries} type="line" height={300} />
                                    </>
                                )
                            }
                            {
                                avgRevViewBy === "yearly" && (
                                    <>
                                        <ReactApexChart options={perAppointmentAvgRevOptionsYearly} series={perAppointmentAvgRevSeriesYearly} type="bar" height={300} />
                                        <ReactApexChart options={perPatientAvgRevOptionsYearly} series={perPatientAvgRevSeriesYearly} type="bar" height={300} />
                                        <ReactApexChart options={perNewPatientAvgRevOptionsYearly} series={perNewPatientAvgRevSeriesYearly} type="bar" height={300} />
                                    </>
                                )
                            }
                            {/*<ReactApexChart options={avgRevOptions} series={avgRevSeries} type="line" height={300} />*/}
                        </div>
                    </div>

                    {/*Top Providers Section*/}
                    <div>
                        <Hidden mdDown>
                            <div className="flex gap-6">
                                <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                                    <div className="flex sm:12 md:gap-24 justify-between px-3">
                                        <div className="w-full">
                                            <div className="flex justify-between pt-3 px-4">
                                                <div className="flex items-center gap-2">
                                                    <p className="chart-subtitle">Revenue by Provider</p>
                                                    <LightTooltip title="Revenue by Providers between selected date range" arrow placement="top">
                                                        <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                                    </LightTooltip>
                                                </div>
                                                <QueryStatsOutlinedIcon
                                                    className="cursor-pointer"
                                                    onClick={()=> handleVisibleTrendChart("revenueByProvider")}
                                                />
                                            </div>
                                            <div>
                                                <ReactApexChart options={topProvidersProviderOptions} series={topProvidersProviderSeries} type="bar" height={300}  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center w-full bg-white mt-6 rounded-xl shadow-md">
                                    {/*<div className="flex justify-center text-sm text-[#184332] font-bold pt-2">*/}
                                    {/*    Referral Info.*/}
                                    {/*</div>*/}
                                    <div className="w-full">
                                        <div className="flex items-center gap-2 pt-4 pl-4">
                                            <p className="chart-subtitle">Referral Info.</p>
                                            <LightTooltip title="Referral Info. between selected date range" arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                        <div className="flex justify-center">
                                            <ReactApexChart options={topSourcesOptions} series={topSourcesSeries} type="donut" height={350} width={400} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                                <div className="pt-3 px-4">
                                    <div className="flex items-center gap-2">
                                        <p className="chart-subtitle">Top Providers & Service Types</p>
                                        <LightTooltip title="Top Providers & Service Types graph based on date range." arrow placement="top">
                                            <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                        </LightTooltip>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 justify-between px-3">
                                    <div className="flex justify-center text-sm text-[#184332] font-bold pt-2">
                                        Providers
                                    </div>
                                    <div>
                                        <ReactApexChart options={topProvidersProviderOptions} series={topProvidersProviderSeries} type="bar" height={300}  />
                                    </div>
                                    <div className="flex justify-center text-sm text-[#184332] font-bold pt-2">
                                        Service Types
                                    </div>
                                    <div>
                                        {/*<ReactApexChart options={topProvidersOptions} series={topProvidersSeries} type="bar" height={300} />*/}
                                        <ReactApexChart options={topServicesOptionsLg} series={topServicesSeries} type="donut" height={400} width={465}/>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                    </div>

                    {/*Top Sources & Services Section*/}
                    <div>
                        <Hidden mdDown>
                            <div className="flex justify-between gap-6">
                                <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                                    {/*<div className="pt-3 px-4">*/}
                                    {/*    <div className="flex items-center gap-2">*/}
                                    {/*        <p className="chart-subtitle">Top Referral Info and Services</p>*/}
                                    {/*        <LightTooltip title="Top Referral Info and Services graph based on date range." arrow placement="top">*/}
                                    {/*            <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>*/}
                                    {/*        </LightTooltip>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="px-4 pt-3">*/}
                                    {/*    <div>*/}
                                    {/*        <p className="text-[#184332] font-bold">Top Referral Info and Services</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div>
                                        <div className="flex justify-between pt-4 px-4">
                                            <div className="flex items-center gap-2">
                                                <p className="chart-subtitle">Revenue by Treatment Type</p>
                                                <LightTooltip title="Revenue by Treatment Type" arrow placement="top">
                                                    <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                                </LightTooltip>
                                            </div>
                                            <div className="flex justify-between items-center bg-white shadow-lg rounded-xl px-1 w-[160px] h-[30px]">
                                                <div className={`flex items-center justify-center w-[70px] h-[25px] ${viewByPercentage ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setViewByPercentage(true)}>
                                                    <p className={`${viewByPercentage ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>% Value</p>
                                                </div>
                                                <div className={`flex items-center justify-center w-[70px] h-[25px] ${!viewByPercentage ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setViewByPercentage(false)}>
                                                    <p className={`${!viewByPercentage ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>$ Value</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-center py-4">
                                            {/*<ReactApexChart options={topProvidersOptions} series={topProvidersSeries} type="bar" height={300} />*/}
                                            {/*<ReactApexChart options={topServicesOptionsLg} series={topServicesSeries} type="donut" height={400} width={465}/>*/}
                                            <ReactApexChart options={topServicesOptionsLg} series={topServicesSeries} type="donut" height={300} width={465}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                                    <div className="w-full">
                                        <div className="flex items-center gap-2 pt-4 pl-4">
                                            <p className="chart-subtitle">{selectedServiceType ? `${selectedServiceType}'s` : ""} Detailed Services</p>
                                            <LightTooltip title="Revenue by Treatment Type" arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                        <div className="w-full">
                                            <ReactApexChart options={topProvidersOptions} series={topProvidersSeries} type="bar" height={300}/>
                                            {/*<ReactApexChart options={topServicesOptionsLg} series={topServicesSeries} type="donut" height={200} width={350}/>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                                <div className="pt-3 px-4">
                                    <div className="flex items-center gap-2">
                                        <p className="chart-subtitle">Top Referral Info and Services</p>
                                        <LightTooltip title="Top Referral Info and Services graph based on date range." arrow placement="top">
                                            <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                        </LightTooltip>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 justify-center items-center px-3 py-2">
                                    <div className="flex justify-center text-sm text-[#184332] font-bold pt-2">
                                        Services
                                    </div>
                                    <div>
                                        <ReactApexChart options={topProvidersOptions} series={topProvidersSeries} type="bar" height={200} width={400}/>
                                        {/*<ReactApexChart options={topServicesOptionsSm} series={topServicesSeries} type="donut" height={200} />*/}
                                    </div>
                                    <div className="flex justify-center text-sm text-[#184332] font-bold pt-2">
                                        Referral Info.
                                    </div>
                                    <div>
                                        <ReactApexChart options={topSourcesOptions} series={topSourcesSeries} type="donut" height={200} />
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                    </div>

                    {/*Top Location and Patients Type Section*/}
                    <div>
                        <Hidden mdDown>
                            <div className="flex justify-between gap-6">
                                <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                                    <div className="flex justify-between pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Patient by Top Location</p>
                                            <LightTooltip title="Top Locations graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                        <div className="flex justify-evenly items-center bg-white shadow-lg rounded-xl px-1 w-[220px] h-[30px]">
                                            <div className={`flex items-center justify-center w-[70px] h-[25px] ${patLocViewBy === "total" ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setPatLocViewBy("total")}>
                                                <p className={`${patLocViewBy === "total" ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>Total</p>
                                            </div>
                                            <div className={`flex items-center justify-center w-[70px] h-[25px] ${patLocViewBy === "active" ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setPatLocViewBy("active")}>
                                                <p className={`${patLocViewBy === "active" ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>Active</p>
                                            </div>
                                            <div className={`flex items-center justify-center w-[70px] h-[25px] ${patLocViewBy === "new" ? "bg-[#184332]" : "bg-white"} rounded-[10px] cursor-pointer`} onClick={()=> setPatLocViewBy("new")}>
                                                <p className={`${patLocViewBy === "new" ? "button-linear-text" : "button-dashboard-tab-text"}`} style={{textTransform: "capitalize"}}>New</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="px-4 pt-3">*/}
                                    {/*    <div>*/}
                                    {/*        <p className="text-[#184332] font-bold">Top Locations</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="flex justify-between gap-24 py-6 px-10">*/}
                                    {/*    <div className="flex flex-col gap-3 justify-center items-center py-6 px-6">*/}
                                    {/*        <div className={`flex justify-between px-3 py-2 items-center rounded-sm h-[50px] w-[260px] ${patientCountStudent >= patientCountRefugee ? "bg-[#E4FFF4]" : "bg-[#F2F2F2]"}`}>*/}
                                    {/*            <div className="text-[15px] font-bold text-[#4F4F4F]">{topLocationNames[0]}</div>*/}
                                    {/*            <div className="flex flex-col items-center justify-center gap-1">*/}
                                    {/*                <div className="text-[15px] font-bold text-[#4F4F4F]">{topLocationPatCout[0]}</div>*/}
                                    {/*                <div className="text-[13px]">No of Patients</div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className={`flex justify-between px-3 py-2 items-center rounded-sm h-[50px] w-[260px] ${patientCountRefugee >= patientCountStudent ? "bg-[#E4FFF4]" : "bg-[#F2F2F2]"}`}>*/}
                                    {/*            <div className="text-[15px] font-bold text-[#4F4F4F]">{topLocationNames[1]}</div>*/}
                                    {/*            <div className="flex flex-col items-center justify-center gap-1">*/}
                                    {/*                <div className="text-[15px] font-bold text-[#4F4F4F]">{topLocationPatCout[1]}</div>*/}
                                    {/*                <div className="text-[13px]">No of Patients</div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className={`flex justify-between px-3 py-2 items-center rounded-sm h-[50px] w-[260px] ${patientCountRefugee >= patientCountStudent ? "bg-[#E4FFF4]" : "bg-[#F2F2F2]"}`}>*/}
                                    {/*            <div className="text-[15px] font-bold text-[#4F4F4F]">{topLocationNames[2]}</div>*/}
                                    {/*            <div className="flex flex-col items-center justify-center gap-1">*/}
                                    {/*                <div className="text-[15px] font-bold text-[#4F4F4F]">{topLocationPatCout[2]}</div>*/}
                                    {/*                <div className="text-[13px]">No of Patients</div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="flex flex-col justify-center px-8">*/}
                                    {/*        <p className="text-[10px]">See miles difference of the patients</p>*/}
                                    {/*        <p>0-3 km <span className="font-bold">2%</span></p>*/}
                                    {/*        <p>3-6 km <span className="font-bold">10%</span></p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    <div className="pt-4">
                                        <ReactApexChart options={topLocationsOptions} series={topLocationsOptions.series} type="bubble" height={350} />
                                    </div>
                                </div>
                                <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Top Type of Patients</p>
                                            <LightTooltip title="Top Type of Patients graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    {/*<div className="px-4 pt-3">*/}
                                    {/*    <div>*/}
                                    {/*        <p className="text-[#184332] font-bold">Top Type of Patients</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="flex gap-4 justify-center items-center px-3">
                                        <div className="flex flex-col gap-3 justify-center items-center content-center py-16 px-6">
                                            <div className={`flex justify-between px-3 py-2 items-center rounded-sm h-[50px] w-[260px] ${patientCountStudent >= patientCountRefugee ? "bg-[#E4FFF4]" : "bg-[#F2F2F2]"}`}>
                                                <div className="text-[15px] font-bold text-[#4F4F4F]">Students</div>
                                                <div className="flex flex-col items-center justify-center gap-1">
                                                    <div className="text-[15px] font-bold text-[#4F4F4F]">{patientCountStudent}</div>
                                                    <div className="text-[13px]">No of Patients</div>
                                                </div>
                                            </div>
                                            <div className={`flex justify-between px-3 py-2 items-center rounded-sm h-[50px] w-[260px] ${patientCountRefugee >= patientCountStudent ? "bg-[#E4FFF4]" : "bg-[#F2F2F2]"}`}>
                                                <div className="text-[15px] font-bold text-[#4F4F4F]">Refugee</div>
                                                <div className="flex flex-col items-center justify-center gap-1">
                                                    <div className="text-[15px] font-bold text-[#4F4F4F]">{patientCountRefugee}</div>
                                                    <div className="text-[13px]">No of Patients</div>
                                                </div>
                                            </div>
                                            <div className={`flex justify-between px-3 py-2 items-center rounded-sm h-[50px] w-[260px] bg-[#F2F2F2]`}>
                                                <div className="text-[15px] font-bold text-[#4F4F4F]">Others</div>
                                                <div className="flex flex-col items-center justify-center gap-1">
                                                    {/*<div className="text-[15px] font-bold text-[#4F4F4F]">{patientCountOthers}</div>*/}
                                                    <div className="text-[15px] font-bold text-[#4F4F4F]">{Number(totalPatients)-(Number(patientCountStudent)+Number(patientCountRefugee))}</div>
                                                    <div className="text-[13px]">No of Patients</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div className="flex flex-col justify-between gap-4">
                                <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Top Locations</p>
                                            <LightTooltip title="Top Locations graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    {/*<div className="px-4 pt-3">*/}
                                    {/*    <div>*/}
                                    {/*        <p className="text-[#184332] font-bold">Top Locations</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="flex flex-col justify-center items-center gap-8 py-6 px-10">*/}
                                    {/*    <div className="flex flex-col items-center gap-3">*/}
                                    {/*        <div className="flex justify-center items-center rounded-sm h-[40px] w-[160px] bg-[#F2F2F2]">*/}
                                    {/*            <p className="text-[13px] font-bold text-[#4F4F4F]">North York</p>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="flex justify-center items-center rounded-sm h-[40px] w-[160px] bg-[#E4FFF4]">*/}
                                    {/*            <p className="text-[13px] font-bold text-[#4F4F4F]">Danforth</p>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="flex justify-center items-center rounded-sm h-[40px] w-[160px] bg-[#F2F2F2]">*/}
                                    {/*            <p className="text-[13px] font-bold text-[#4F4F4F]">South York</p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="flex flex-col justify-center items-center px-8">*/}
                                    {/*        <p className="text-[10px]">See miles difference of the patients</p>*/}
                                    {/*        <p>0-3 km <span className="font-bold">2%</span></p>*/}
                                    {/*        <p>3-6 km <span className="font-bold">10%</span></p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="pt-4">
                                        <ReactApexChart options={topLocationsOptions} series={topLocationsOptions.series} type="bubble" height={300} />
                                    </div>
                                </div>
                                <div className="w-full bg-white mt-6 rounded-xl shadow-md">
                                    <div className="pt-3 px-4">
                                        <div className="flex items-center gap-2">
                                            <p className="chart-subtitle">Top Type of Patients</p>
                                            <LightTooltip title="Top Type of Patients graph based on date range." arrow placement="top">
                                                <InfoOutlinedIcon sx={{color: "#BDBDBD", fontSize: "20px"}}/>
                                            </LightTooltip>
                                        </div>
                                    </div>
                                    <div className="flex gap-4 justify-center px-3">
                                        <div className="flex flex-col gap-3 justify-center items-center py-6 px-6">
                                            <div className="flex justify-center items-center rounded-sm h-[40px] w-[160px] bg-[#E4FFF4]">
                                                <p className="text-[13px] font-bold text-[#4F4F4F]">Students</p>
                                            </div>
                                            <div className="flex justify-center items-center rounded-sm h-[40px] w-[160px] bg-[#F2F2F2]">
                                                <p className="text-[13px] font-bold text-[#4F4F4F]">Refugee</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Hidden>
                    </div>

                    {/*Recommendations Section*/}
                    {/*<div>*/}
                    {/*    <Hidden mdDown>*/}
                    {/*        <div className="flex bg-white shadow-md rounded-xl mt-6 py-6">*/}
                    {/*            <div className="flex justify-center items-center h-[60px] bg-[#F4FFF9] w-[60px] rounded-full">*/}
                    {/*                <img src="./recommendation-of-the-day.png" height="40px" width="40px" alt=""/>*/}
                    {/*            </div>*/}
                    {/*            <div className="flex justify-between w-full">*/}
                    {/*                <div>*/}
                    {/*                    <p className="text-[#219653] font-bold">Recommendation of the Day 🚀</p>*/}
                    {/*                    <div>*/}
                    {/*                        <p className="text-sm">*/}
                    {/*                            Based on the data and Industry Expert We are ready to give you suggestions & recommendations.*/}
                    {/*                        </p>*/}
                    {/*                        <p className="text-sm">You Can follow these steps to improve your EBITDA, REVENUE and No of Patients.</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex items-end gap-4 px-6">*/}
                    {/*                    <Button type="submit" variant="outlined" sx={{backgroundColor: "#184332", height: "40px", width: "100px", borderRadius: "30px"}}>*/}
                    {/*                        <p className="button-linear-text" style={{textTransform: "capitalize"}}>Accept</p>*/}
                    {/*                    </Button>*/}
                    {/*                    <Button type="submit" variant="outlined" sx={{backgroundColor: "#FFFFFF", color: "#184332", height: "40px", width: "100px", borderRadius: "30px", borderColor: "#184332"}}>*/}
                    {/*                        <p className="" style={{textTransform: "capitalize"}}>Later</p>*/}
                    {/*                    </Button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Hidden>*/}
                    {/*    <Hidden mdUp>*/}
                    {/*        <div className="flex bg-white shadow-md rounded-xl mt-6 py-6">*/}
                    {/*            <div className="flex justify-center items-center h-[60px] bg-[#F4FFF9] w-[60px] rounded-full">*/}
                    {/*                <img src="./recommendation-of-the-day.png" height="40px" width="40px" alt=""/>*/}
                    {/*            </div>*/}
                    {/*            <div className="flex flex-col justify-between w-full">*/}
                    {/*                <div>*/}
                    {/*                    <p className="text-[#219653] font-bold">Recommendation of the Day 🚀</p>*/}
                    {/*                    <div className="pe-1">*/}
                    {/*                        <p className="text-sm">*/}
                    {/*                            Based on the data and Industry Expert We are ready to give you suggestions & recommendations.*/}
                    {/*                        </p>*/}
                    {/*                        <p className="text-sm">You Can follow these steps to improve your EBITDA, REVENUE and No of Patients.</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex justify-center gap-4 px-6 pt-5">*/}
                    {/*                    <Button type="submit" variant="outlined" sx={{backgroundColor: "#184332", height: "40px", width: "100px", borderRadius: "30px"}}>*/}
                    {/*                        <p className="button-linear-text" style={{textTransform: "capitalize"}}>Accept</p>*/}
                    {/*                    </Button>*/}
                    {/*                    <Button type="submit" variant="outlined" sx={{backgroundColor: "#FFFFFF", color: "#184332", height: "40px", width: "100px", borderRadius: "30px", borderColor: "#184332"}}>*/}
                    {/*                        <p className="" style={{textTransform: "capitalize"}}>Later</p>*/}
                    {/*                    </Button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Hidden>*/}
                    {/*</div>*/}
                </div>
            </NavAndTopBar>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openTrendChartModal}
                onClose={handleTrendChartModalClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openTrendChartModal}>
                    <Box sx={style}>
                        <TrendChart
                            trendChartData={trendChartModalData}
                            closeModal={handleTrendChartModalClose}
                        />
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openTrendChartMultipleCategoryModal}
                onClose={handleTrendChartModalClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openTrendChartMultipleCategoryModal}>
                    <Box sx={style}>
                        <TrendChartMultipleCategory
                            trendChartData={trendChartMultipleCategoryModalData}
                            closeModal={handleTrendChartModalClose}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}