import NavAndTopBar from "../../shared/layouts/NavAndTopBar";
import {useState} from "react";

export default function  ActiveYourPlan({title}) {

    return(
        <NavAndTopBar>
            <div className="px-7 py-6">
                <div className="flex items-center gap-4">
                    <p className="font-bold text-2xl">
                        Welcome 👋
                    </p>
                    {/*<p>Add your data to see the insightful metrics</p>*/}
                </div>
                <div className="mt-12">
                    <div className="mb-8">
                        <p className="font-bold text-2xl">This page hasn't been activated for you.<br /> Upgrade your plan and add your data to see the insightful metrics</p>
                        <p className="text-md pt-5">Contact with <span className="font-bold text-xl">admin@worldaigroup.com</span></p>
                    </div>
                </div>
            </div>
        </NavAndTopBar>
    )
}