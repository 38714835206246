import {useContext, useEffect} from "react";
import {AuthContext} from "../contexts/AuthContext";
import Login from "../components/auth/Login";
import history from "./@history"
import AuthService from "../data-access/services/authServices/AuthService";
import {useLocation} from "react-router-dom";
import Signup from "../components/auth/Signup";
import ForgetPassword from "../components/auth/ForgetPassword";
import ResetPassword from "../components/auth/ResetPassword";

export default function Authorization({children}) {
    const location = useLocation();
    const {isAuthenticated} = useContext(AuthContext)
    const userData = AuthService.getUserInfo()
    const unAuthenticatedRoutes = ["/login", "/signup", "/forget-password", "/reset-password"]
    // useEffect(()=> {
    //     if (!userData) setTimeout(() => history.push("/login"), 0);
    // },[isAuthenticated])
    return isAuthenticated ? (
        <div>
            {children}
        </div>
    ) : (
        location.pathname === "/signup" ? <Signup /> : location.pathname === "/forget-password" ? <ForgetPassword /> : location.pathname.includes("/reset-password") ? <ResetPassword /> : <Login />
    )
}