import ProfileSetup from "./ProfileSetup";
import {useState} from "react";
import ProfileSetup2 from "./ProfileSetup2";
import {useLocation} from "react-router-dom";

export default function ProfileSetupScreen() {
    const { state } = useLocation();
    const [isSetupDoneFirstScreen, setIsSetupDoneFirstScreen] = useState(state.profileSetupScreenNo === 2)

    return (
        <div>
            {isSetupDoneFirstScreen && (
                <ProfileSetup2 userId={state?.userId} />
            )}
            {!isSetupDoneFirstScreen && (
                <ProfileSetup setIsSetupDoneFirstScreen={setIsSetupDoneFirstScreen} userId={state?.userId} />
            )}
        </div>
    )
}