import {
    Box,
    Button,
    Paper,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {useEffect, useRef, useState} from "react";
import InputFieldWithLabel from "../shared/input-fields/InputFieldWithLabel";
import { useNavigate } from 'react-router-dom';
import AuthService from "../../data-access/services/authServices/AuthService";
import {useSnackbar} from "notistack";
import {GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import jwtDecode from "jwt-decode";
import Logo from "../../assets/images/WAIG-Logo-2.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
    const {enqueueSnackbar} = useSnackbar()
    const appleRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        email: "",
        password: "",
        remember: false,
        showPassword: false,
    });
    const navigate = useNavigate()

    // yup schema
    const schema = yup.object().shape({
        email: yup
            .string()
            .email("You must enter a valid email")
            .required("You must enter a email"),
        password: yup.string().required("Please enter your password."),
    });

    // default form values
    const defaultValues = {
        email: "",
        password: ""
    };

    // form controler
    const { control, formState, handleSubmit, reset } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });
    const { isValid, dirtyFields, errors } = formState;


    const onSubmit = (values)=> {
        AuthService.login(values)
            .then((response)=> {
                if (response?.data?.data?.user_data?.isProfileSetup || response?.data?.data?.user_data?.skipstageno){
                    navigate("/dashboard")
                } else {
                    navigate("/profile-setup", {
                        state : {
                            profileSetupScreenNo : response?.data?.data?.user_data?.profileSetupScreenNo,
                            userId : response?.data?.data?.user_data?.userId,
                        }
                    })
                }
                // enqueueSnackbar(response?.data?.message, {variant: "success"})
            })
            .catch((err)=> {
                // enqueueSnackbar(err, {variant: "error"})
                toast.warn(err, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
    }

    const handleClickShowPassword = ()=> {

    }

    // const signInWithGoogle = useGoogleLogin({
    //     onSuccess : tokenResponse => console.log("credentials : ", jwtDecode(tokenResponse?.credential)),
    //     // flow: "auth-code"
    // })

    const signInWithGoogle = ()=> {
        appleRef.current.childNodes[1].click()
        console.log("appleRef", appleRef)
    }


    return (
        <div className="flex flex-col flex-auto items-center justify-around sm:justify-center h-auto min-w-0 md:p-32">
            <Paper className="flex w-11/12 sm:w-auto min-h-auto max-h-3xl h-661 overflow-hidden" sx={{borderRadius: "20px"}}>
                <Box className="relative hidden lg:flex flex-auto justify-center items-center pt-36 pb-36 overflow-hidden lg:min-w-[500px] max-w-[500px] h-auto w-full bg-[#184332]">
                    <div className="flex flex-col gap-[60px] justify-center items-center z-10 relative w-full">
                        <div className="flex justify-center items-center h-[190px] w-[190px] bg-[#133B2B] rounded-full">
                            {/*<div className="flex justify-center h-[95px] w-[95px]">*/}
                            {/*    <img src="./login-logo.png" alt="" />*/}
                            {/*</div>*/}
                            <div className="flex justify-center items-center rounded-full h-[200px] w-[200px]">
                                <img src={Logo} alt=""/>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center max-w-[433px]">
                            <p className="heading-text-1 pb-2">
                                Welcome to WAIG
                            </p>
                            <p className="body-text text-center">
                                Welcome to World AI Group, where data-driven insights
                            </p>
                            <p className="body-text text-center">
                                meet innovation, powering your journey towards
                            </p>
                            <p className="body-text text-center">
                                optimized KPIs and business excellence.
                            </p>
                        </div>
                    </div>
                </Box>
                <div className="w-full sm:w-auto flex flex-col py-[104px] px-6 md:px-24 lg:min-w-[500px] max-w-[500px]">
                    <div className="w-auto">
                        <p className="welcome-back-text">Welcome Back!</p>
                        <p className="body-text-2 pb-8">Get your data-driven insights</p>
                    </div>
                    <form name="loginForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <div style={{paddingBottom: "15px"}}>
                            <InputFieldWithLabel label="E-mail" placeholder="Your E-mail" control={control} errors={errors} controllerName="email" />
                        </div>
                        <div>
                            <InputFieldWithLabel label="Password" placeholder="Your Password" control={control} errors={errors} controllerName="password" />
                        </div>
                        <div className="flex justify-end pt-2">
                            <p className="body-text-3 cursor-pointer" onClick={()=> navigate("/forget-password")}>Forgot Password?</p>
                        </div>
                        <div className="pt-4">
                            <Button type="submit" variant="outlined" sx={{backgroundColor: "#184332", height: "40px", borderRadius: "30px"}} fullWidth>
                                <p className="button-linear-text" style={{textTransform: "capitalize"}}>Sign In</p>
                            </Button>
                        </div>
                    </form>
                    {/*<div className="pt-4">*/}
                    {/*    <fieldset><legend>or do it via other accounts</legend></fieldset>*/}
                    {/*</div>*/}
                    {/*<div className="flex justify-center gap-5 pt-3">*/}
                    {/*    <div className="OAuthIconButton flex justify-center items-center rounded-full" onClick={()=> signInWithGoogle()}>*/}
                    {/*        <div className="flex justify-center items-center " style={{height: "15px", width: "15px"}}>*/}
                    {/*            <img src="./icon-Google.png" alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="OAuthIconButton flex justify-center items-center rounded-full bg-black" onClick={()=> console.log("Apple clicked")}>*/}
                    {/*        <div className="flex justify-center items-center " style={{height: "15px", width: "15px"}}>*/}
                    {/*            <img src="./icon-Apple.png" alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="OAuthIconButton flex justify-center items-center rounded-full bg-[#2F80ED]">*/}
                    {/*        <div className="flex justify-center items-center p-1" style={{height: "17px", width: "17px"}}>*/}
                    {/*            <img src="./icon-Facebook.png" alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="">*/}
                    {/*    <GoogleOAuthProvider clientId="780733152260-iedr4f3jmjktllco7sbbvk2kuish6emn.apps.googleusercontent.com">*/}
                    {/*        <Button ref={appleRef} onClick={()=> console.log("hola")}>*/}
                    {/*            <GoogleLogin*/}
                    {/*                shape="pill"*/}
                    {/*                onSuccess={credentialResponse => {*/}
                    {/*                    let credentials = jwtDecode(credentialResponse.credential)*/}
                    {/*                    console.log("credentials : ",credentials);*/}
                    {/*                    console.log(credentialResponse);*/}
                    {/*                }}*/}
                    {/*                onError={() => {*/}
                    {/*                    console.log('Login Failed');*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </Button>*/}
                    {/*    </GoogleOAuthProvider>*/}
                    {/*</div>*/}
                    <div className="flex justify-center pt-10">
                        <p style={{color: "#718096", fontSize: "10px", fontWeight: "600", fontFamily: "Poppins"}}>Don’t have an account? <span onClick={()=> navigate("/signup")} style={{color: "#B68340", cursor: "pointer"}}>Sign Up</span></p>
                    </div>
                </div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Paper>
        </div>
    )
}