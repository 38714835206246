import ReactApexChart from "react-apexcharts";
import React from "react";

export default function RadialBarChart({options, series}) {
    return (
        <ReactApexChart options={options} series={series} type="radialBar" height={200} />
    )
}


