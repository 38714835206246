import React from "react";
import axios from "axios";
import { EnvVariable, SecretKey } from "../../../../utils/envVariables";
import dayjs from "dayjs";

class OverviewSummaryDashboardService {
  getIndustryAvgData = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/industry-avg-data`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getNumOfTotalPatientMonth = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/number-of-total-patients-month`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getNumOfTotalPatientYear = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/number-of-total-patients-year`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  //6M
  getNumOfActivePatient = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/number-of-active-patient`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  //12M
  getNumOfActivePatient12 = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/number-of-active-patient12`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  // 6M
  getNumOfActivePatientYear = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/number-of-active-patient-year`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  // 12M
  getNumOfActivePatientYear12 = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/number-of-active-patient-year12`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getNumOfNewPatientsMonth = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/number-of-new-patients-month`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getNumOfNewPatientsYear = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/number-of-new-patients-year`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getEbidtaRevenueExpenseData = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/profit-los-data`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getEbitdaData = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/ebitda-data`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getRevenueData = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/revenue-data`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getExpenseData = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/expense-data`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  //MonthToSixMonthCutOff
  getChurnRateMonthly = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/churn-rate-monthly`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };
  //MonthToSixMonthCutOff
  getChurnYearlyData = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/churn-rate-yearly`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getGrowthRateYearlyData = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/growth-rate-yearly`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getGrowthRateYearlyMonthlyBasisData = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/growth-rate-mb-yearly`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getGrowthRateMonthlyData = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/growth-rate-monthly`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getAvgRevenuePerPatient = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/avg-rev-per-patient`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getAvgRevenuePerPatientYearly = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/avg-rev-per-patient-yearly`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getAvgRevenuePerAppointment = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/avg-rev-per-appointment`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getAvgRevenuePerAppointmentYearly = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/avg-rev-per-appointment-yearly`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getAvgRevenuePerNewPatient = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/avg-rev-per-new-patient`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getAvgRevenuePerNewPatientYearly = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/avg-rev-per-new-patient-yearly`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getTopProviders = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/top-providers`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getTopProvidersYearly = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/top-providers-yearly`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getTopProvidersServices = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/top-providers-services`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getServiceCategoryById = (id) => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/service-by-category-id/${id}`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getTopLocations = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/top-locations`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getTopLocationsByDate = (dateParam) => {
    let date = dayjs(dateParam);
    let preparedDate = dayjs(`${date.$y}-${date.$M}-${date.$D}`)
      .endOf("month")
      .format("YYYY-MM-DD");
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/top-locations/${preparedDate}`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getTopLocationsByDateActiveP = (startDateParam, endDateParam) => {
    // let startDate = dayjs(startDateParam)
    // let endDate = dayjs(endDateParam)
    // let preparedStartDate = dayjs(`${startDate.$y}-${startDate.$M}-${startDate.$D}`).startOf("month").format('YYYY-MM-DD')
    // let preparedEndDate = dayjs(`${endDate.$y}-${endDate.$M}-${endDate.$D}`).endOf("month").format('YYYY-MM-DD')
    let preparedStartDate = dayjs(startDateParam)
      .startOf("month")
      .add(0, "month")
      .format("YYYY-MM-DD");
    let preparedEndDate = dayjs(endDateParam)
      .startOf("month")
      .add(0, "month")
      .format("YYYY-MM-DD");
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/top-locations-active-pat/${preparedStartDate}/${preparedEndDate}`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getTopLocationsByDateNewP = (startDateParam, endDateParam) => {
    let startDate = dayjs(startDateParam);
    let endDate = dayjs(endDateParam);
    // let preparedStartDate = dayjs(`${startDate.$y}-${startDate.$M}-${startDate.$D}`).startOf("month").format('YYYY-MM-DD')
    // let preparedEndDate = dayjs(`${endDate.$y}-${endDate.$M}-${endDate.$D}`).endOf("month").format('YYYY-MM-DD')
    let preparedStartDate = dayjs(startDateParam)
      .startOf("month")
      .add(0, "month")
      .format("YYYY-MM-DD");
    let preparedEndDate = dayjs(endDateParam)
      .endOf("month")
      .add(0, "month")
      .format("YYYY-MM-DD");
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/top-locations-new-pat/${preparedStartDate}/${preparedEndDate}`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getPatientsCountStudent = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/patient-count-student`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getPatientsCountRefugee = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/patient-count-refugee`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getPatientsCountOthers = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/patient-count-others`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getRevenueByTreatmentType = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/num-of-services-by-category`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getChurnRateLoosedPatientsIds = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/loosed-patients-id`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getChurnRateLoosedPatientsDetailsByIds = (dataParams) => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/loosed-patients-details`;

      const arrayAsString = dataParams.join(",");
      return axios
        .get(URL, {
          params: {
            ids: arrayAsString,
          },
        })
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };

  getNumOfNewPatientsMonthToDownload = (startDate, endDate) => {
    return new Promise((resolve, reject) => {
      // let preparedStartDate = dayjs(startDate).startOf("month").subtract(1, 'month').format('YYYY-MM-DD')
      // let preparedEndDate = dayjs(endDate).endOf("month").subtract(1, 'month').format('YYYY-MM-DD')
      let preparedStartDate = dayjs(startDate)
        .startOf("month")
        .add(0, "month")
        .format("YYYY-MM-DD");
      let preparedEndDate = dayjs(endDate)
        .endOf("month")
        .add(0, "month")
        .format("YYYY-MM-DD");
      //   console.log("std end : ", preparedStartDate, preparedEndDate);
      const URL = `${EnvVariable.BASEURL}/overview/summary/new-patients-month-range/${preparedStartDate}/${preparedEndDate}`;
      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          // reject(error.response.data.error);
          reject("somethingWentWrong");
        });
    });
  };

  getTestAPI = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/overview/summary/test-api`;
      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          // reject(error.response.data.error);
          reject("somethingWentWrong");
        });
    });
  };
}

const instance = new OverviewSummaryDashboardService();
export default instance;
